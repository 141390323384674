import { take, put, call, fork, takeEvery } from 'redux-saga/effects';
import { ALERT_TYPES, REVISION_STATUS } from '../../constants';
import {
  ADD_REVISION_LOG_REQUEST_URL,
  ADD_REVISION_REQUEST_URL,
  BASE_URL,
  callRequest,
  CANCEL_REVISION,
  CREATE_DRAWING_URL,
  DOWNLOAD_DRAWINGS_REGISTER,
  DOWNLOAD_DRAWING_URL,
  ERROR_SOMETHING_WENT_WRONG,
  GET_CIRCLES_ACTIVITY,
  GET_DRAWING_ACCESS_HISTORY,
  GET_DRAWING_ACTIVITY,
  GET_DRAWING_REVISIONS,
  GET_DRAWING_SEEN_ACTIVITY,
  GET_DRAWING_UNREGISTER_USER,
  GET_PROJECT_DRAWINGS_URL,
  GET_UPLOAD_URL,
  SEND_DRAWING_ACCESS_URL,
  SHARE_DRAWING,
  UPLOAD_DWG
} from '../../config/webService';
import {
  cancelRevisionsParams,
  deleteDrawingParams,
  getDrawingNotificationParams,
  getDrawingRevisionsActivityParams,
  getDrawingsDetail,
  getUserDrawings,
  removeRevisionsParams,
  toastAlert,
  updateDrawingsParams
} from '../../services/utils';
import {
  addRevisionPerfLogRequest,
  addRevisionRequest,
  cancelRevisionRequest,
  createDrawingRequest,
  downloadDrawingRequest,
  getCirclesActivityRequest,
  getDrawingsActivityRequest,
  getDrawingsDetailRequest,
  getDrawingsRequest,
  getDrawingsRevisionRequest,
  getDrawingsSeenActivityRequest,
  getDrawingsSuccess,
  shareDrawingRequest,
  updateDrawingPermissionRequest,
  getRevisionAccessHistoryRequest,
  getRevisionsSeenActivityRequest,
  deleteDrawingRequest,
  sendDrawingAcceessReq,
  getNotificationsRequest,
  getCommentsNotificationsRequest,
  updateDrawingRequest,
  getUploadUrlsRequest,
  getNotificationsSuccess,
  downnloadDrawingsRegisterRequest,
  setCommentNotification,
  getDrawingUnregisterUserRequest,
  uploadDWGRevisionRequest
} from '../slicers/drawing';

function* createDrawingLoop() {
  yield takeEvery(createDrawingRequest.type, createDrawing);
}

function* createDrawing(action) {
  // while (true) {
  // PAYLOAD PATTERN COMING FROM REDUX-TOOLKIT
  const { payload } = action;
  // const { payload } = yield take(createDrawingRequest.type);
  // PARAMETER SEND FROM DISPATCH WILL DESTRUCTURE THERE
  const { payloadData, responseCallback } = payload;

  try {
    const response = yield call(
      callRequest,
      CREATE_DRAWING_URL,
      payloadData.data,
      getUserDrawings(payloadData?.id),
      '',
      {}
    );
    if (response.success) {
      if (responseCallback) responseCallback(true, response);
    } else {
      if (responseCallback) responseCallback(false, response);
    }
  } catch (err) {
    if (responseCallback) responseCallback(false, err);
  }
  // }
}
function* getDrawings() {
  while (true) {
    // PAYLOAD PATTERN COMING FROM REDUX-TOOLKIT
    const { payload } = yield take(getDrawingsRequest.type);
    // PARAMETER SEND FROM DISPATCH WILL DESTRUCTURE THERE
    const { payloadData, responseCallback } = payload;

    //
    try {
      const response = yield call(
        callRequest,
        GET_PROJECT_DRAWINGS_URL,
        '',
        getUserDrawings(payloadData),
        '',
        {}
      );
      console.log({ response });
      if (response?.length >= 0) {
        if (responseCallback) responseCallback(true, response);
        yield put(getDrawingsSuccess(response));
      } else {
        if (responseCallback) responseCallback(false, response);
      }
    } catch (err) {
      if (responseCallback) responseCallback(false, err);
    }
  }
}
function* getDrawingDetail() {
  while (true) {
    // PAYLOAD PATTERN COMING FROM REDUX-TOOLKIT
    const { payload } = yield take(getDrawingsDetailRequest.type);
    // PARAMETER SEND FROM DISPATCH WILL DESTRUCTURE THERE
    const { payloadData, responseCallback } = payload;

    //
    try {
      const response = yield call(
        callRequest,
        GET_PROJECT_DRAWINGS_URL,
        '',
        getDrawingsDetail(payloadData?.id, payloadData?.drawingId),
        '',
        {}
      );
      if (response) {
        const array = [response];
        yield put(getDrawingsSuccess(array));
        if (responseCallback) {
          responseCallback(true, response);
        }
      } else {
        if (responseCallback) responseCallback(false, response);
      }
    } catch (err) {
      if (responseCallback) responseCallback(false, err);
    }
  }
}

function* addRevisionLoop() {
  yield takeEvery(addRevisionRequest.type, addRevision);
}

function* addRevision(action) {
  // while (true) {
  // PAYLOAD PATTERN COMING FROM REDUX-TOOLKIT
  const { payload } = action;
  // PARAMETER SEND FROM DISPATCH WILL DESTRUCTURE THERE
  const { payloadData, responseCallback } = payload;

  //
  try {
    const response = yield call(
      callRequest,
      ADD_REVISION_REQUEST_URL,
      payloadData,
      '',
      '',
      {}
    );
    if (response.success) {
      if (responseCallback) responseCallback(true, response);
    } else {
      if (responseCallback) responseCallback(false, response);
    }
  } catch (err) {
    ///if (responseCallback) responseCallback(false, err);
  }
  // }
}
function* addRevisionPerfLog() {
  while (true) {
    // PAYLOAD PATTERN COMING FROM REDUX-TOOLKIT
    const { payload } = yield take(addRevisionPerfLogRequest.type);
    // PARAMETER SEND FROM DISPATCH WILL DESTRUCTURE THERE
    const { payloadData, responseCallback } = payload;

    try {
      const response = yield call(
        callRequest,
        ADD_REVISION_LOG_REQUEST_URL,
        payloadData,
        '',
        '',
        {}
      );

      if (response) {
        if (responseCallback) responseCallback(true, response);
      } else {
        if (responseCallback) responseCallback(false, response);
      }
    } catch (err) {
      if (responseCallback) responseCallback(false, err);
    }
  }
}
function* getDrawingRevisions() {
  while (true) {
    // PAYLOAD PATTERN COMING FROM REDUX-TOOLKIT
    const { payload } = yield take(getDrawingsRevisionRequest.type);
    // PARAMETER SEND FROM DISPATCH WILL DESTRUCTURE THERE
    const { payloadData, responseCallback } = payload;

    //
    try {
      const response = yield call(
        callRequest,
        GET_DRAWING_REVISIONS,
        {},
        '',
        // getDrawingsDetail(payloadData?.id, payloadData?.drawingId),
        '',
        { ...payloadData }
      );
      if (response?.length) {
        if (responseCallback) responseCallback(true, response);
        // yield put(getDrawingsSuccess(response));
      } else {
        if (responseCallback) responseCallback(false, response);
      }
    } catch (err) {
      if (responseCallback) responseCallback(false, err);
    }
  }
}
function* getRevisionAccessHistory() {
  while (true) {
    // PAYLOAD PATTERN COMING FROM REDUX-TOOLKIT
    const { payload } = yield take(getRevisionAccessHistoryRequest.type);
    // PARAMETER SEND FROM DISPATCH WILL DESTRUCTURE THERE
    const { payloadData, responseCallback } = payload;

    //
    try {
      const response = yield call(
        callRequest,
        GET_DRAWING_ACCESS_HISTORY,
        {},
        `/${payloadData.pid}/${payloadData.did}/${payloadData.rid}/seenActivity`,
        '',
        {}
      );
      if (response?.length) {
        if (responseCallback) responseCallback(true, response);
        // yield put(getDrawingsSuccess(response));
      } else {
        if (responseCallback) responseCallback(false, response);
      }
    } catch (err) {
      if (responseCallback) responseCallback(false, err);
    }
  }
}
function* getDrawingsActivity() {
  while (true) {
    // PAYLOAD PATTERN COMING FROM REDUX-TOOLKIT
    const { payload } = yield take(getDrawingsActivityRequest.type);
    // PARAMETER SEND FROM DISPATCH WILL DESTRUCTURE THERE
    const { payloadData, responseCallback } = payload;

    //
    try {
      const response = yield call(
        callRequest,
        GET_DRAWING_ACTIVITY,
        {},
        `/${payloadData.pid}/drawingActivity`,
        '',
        {}
      );
      if (response?.length) {
        if (responseCallback) responseCallback(true, response);
        // yield put(getDrawingsSuccess(response));
      } else {
        if (responseCallback) responseCallback(false, response);
      }
    } catch (err) {
      if (responseCallback) responseCallback(false, err);
    }
  }
}
function* getCirclesActivity() {
  while (true) {
    // PAYLOAD PATTERN COMING FROM REDUX-TOOLKIT
    const { payload } = yield take(getCirclesActivityRequest.type);
    // PARAMETER SEND FROM DISPATCH WILL DESTRUCTURE THERE
    const { payloadData, responseCallback } = payload;

    //
    try {
      const response = yield call(
        callRequest,
        GET_CIRCLES_ACTIVITY,
        {},
        `/${payloadData.pid}/circleActivity`,
        '',
        {}
      );
      if (response?.length) {
        if (responseCallback) responseCallback(true, response);
      } else {
        if (responseCallback) responseCallback(false, response);
      }
    } catch (err) {
      if (responseCallback) responseCallback(false, err);
    }
  }
}
function* getDrawingsSeenActivity() {
  while (true) {
    // PAYLOAD PATTERN COMING FROM REDUX-TOOLKIT
    const { payload } = yield take(getDrawingsSeenActivityRequest.type);
    // PARAMETER SEND FROM DISPATCH WILL DESTRUCTURE THERE
    const { payloadData, responseCallback } = payload;

    //
    try {
      const response = yield call(
        callRequest,
        GET_DRAWING_SEEN_ACTIVITY,
        {},
        `${payloadData.pid}/seenActivity`,
        '',
        {}
      );
      if (response?.length) {
        if (responseCallback) responseCallback(true, response);
        // yield put(getDrawingsSuccess(response));
      } else {
        if (responseCallback) responseCallback(false, response);
      }
    } catch (err) {
      if (responseCallback) responseCallback(false, err);
    }
  }
}

function* shareDrawing() {
  while (true) {
    // PAYLOAD PATTERN COMING FROM REDUX-TOOLKIT
    const { payload } = yield take(shareDrawingRequest.type);
    // PARAMETER SEND FROM DISPATCH WILL DESTRUCTURE THERE
    const { payloadData, responseCallback } = payload;

    const url = {
      ...SHARE_DRAWING,
      route: SHARE_DRAWING?.route
        ?.replace(':drawingid', payloadData.drawingid)
        .replace(':revisionNumber', payloadData.revision)
    };
    const payloadFrShare = {
      recipient: payloadData.recipient
    };

    try {
      const response = yield call(
        callRequest,
        url,
        payloadFrShare,
        '',
        // getUserDrawings(payloadData?.id),
        '',
        {}
      );
      if (response.success) {
        if (responseCallback) responseCallback(true, response);
      } else {
        if (responseCallback) responseCallback(false, response);
      }
    } catch (err) {
      if (responseCallback) responseCallback(false, err);
    }
  }
}

function* cancelRevision() {
  while (true) {
    // PAYLOAD PATTERN COMING FROM REDUX-TOOLKIT
    const { payload } = yield take(cancelRevisionRequest.type);
    // PARAMETER SEND FROM DISPATCH WILL DESTRUCTURE THERE
    const { payloadData, responseCallback } = payload;
    const { action } = payloadData;
    try {
      const response = yield call(
        callRequest,
        CANCEL_REVISION,
        payloadData.data,
        action === REVISION_STATUS.cancel
          ? cancelRevisionsParams(
              payloadData.pid,
              payloadData.did,
              payloadData.rnumber
            )
          : action === REVISION_STATUS.remove &&
              removeRevisionsParams(
                payloadData.pid,
                payloadData.did,
                payloadData.rnumber
              ),
        '',
        {}
      );
      if (response.success) {
        if (responseCallback) responseCallback(true, response);
      } else {
        if (responseCallback) responseCallback(false, response);
      }
    } catch (err) {
      if (responseCallback) responseCallback(false, err);
    }
  }
}

function* updateDrawingPermission() {
  while (true) {
    // PAYLOAD PATTERN COMING FROM REDUX-TOOLKIT
    const { payload } = yield take(updateDrawingPermissionRequest.type);
    // PARAMETER SEND FROM DISPATCH WILL DESTRUCTURE THERE
    const { payloadData, responseCallback } = payload;
    const { id, drawingId, data } = payloadData;
    //
    try {
      const response = yield call(
        callRequest,
        CREATE_DRAWING_URL,
        data,
        updateDrawingsParams(id, drawingId),
        '',
        {}
      );
      if (response.success) {
        if (responseCallback) responseCallback(true, response);
      } else {
        if (responseCallback) responseCallback(false, response);
      }
    } catch (err) {
      if (responseCallback) responseCallback(false, err);
    }
  }
}

function* getRevisionsSeenActivity() {
  while (true) {
    // PAYLOAD PATTERN COMING FROM REDUX-TOOLKIT
    const { payload } = yield take(getRevisionsSeenActivityRequest.type);
    // PARAMETER SEND FROM DISPATCH WILL DESTRUCTURE THERE
    const { payloadData, responseCallback } = payload;
    const { id, drawingId, rnumber } = payloadData;

    try {
      const response = yield call(
        callRequest,
        GET_DRAWING_SEEN_ACTIVITY,
        {},
        getDrawingRevisionsActivityParams(id, drawingId, rnumber),
        '',
        {}
      );
      if (response?.length) {
        if (responseCallback) responseCallback(true, response);
      } else {
        if (responseCallback) responseCallback(false, response);
      }
    } catch (err) {
      if (responseCallback) responseCallback(false, err);
    }
  }
}

function* downloadProjectDrawing() {
  while (true) {
    const { payload } = yield take(downloadDrawingRequest.type);

    const { payloadData, responseCallback } = payload;

    const url = {
      ...DOWNLOAD_DRAWING_URL,
      route: DOWNLOAD_DRAWING_URL?.route
        ?.replace(':pId', payloadData.pId)
        .replace(':drawingId', payloadData.drawingId)
        .replace(':rurl', payloadData?.data?.rNum)
      //.replace(':rurl', 'latest')
    };

    try {
      const response = yield call(
        callRequest,
        url,
        //payloadData?.data,
        {},
        '',
        '',
        {}
      );
      //

      if (response) {
        if (responseCallback) responseCallback(true, response);
      }
    } catch (error) {
      console.error(error);
    }
  }
}

function* deleteDrawing() {
  while (true) {
    // PAYLOAD PATTERN COMING FROM REDUX-TOOLKIT
    const { payload } = yield take(deleteDrawingRequest.type);
    // PARAMETER SEND FROM DISPATCH WILL DESTRUCTURE THERE
    const { payloadData, responseCallback } = payload;
    const { id, drawingId, data } = payloadData;
    try {
      const response = yield call(
        callRequest,
        CREATE_DRAWING_URL,
        data,
        deleteDrawingParams(id, drawingId),
        '',
        {}
      );
      if (response.success) {
        if (responseCallback) responseCallback(true, response);
      } else {
        if (responseCallback) responseCallback(false, response);
      }
    } catch (err) {
      if (responseCallback) responseCallback(false, err);
    }
  }
}

function* drawingAccessRequest() {
  while (true) {
    const { payload } = yield take(sendDrawingAcceessReq.type);

    const { payloadData, responseCallback } = payload;

    try {
      const response = yield call(
        callRequest,
        SEND_DRAWING_ACCESS_URL,
        payloadData.data,
        '',
        '',
        {}
      );

      if (response?.success) {
        toastAlert(response.msg, ALERT_TYPES.success);
        if (responseCallback) responseCallback(true);
      } else {
        if (responseCallback) responseCallback(false);
        toastAlert(
          response.msg || ERROR_SOMETHING_WENT_WRONG,
          ALERT_TYPES.error
        );
      }
    } catch (error) {
      if (responseCallback) responseCallback(false);
    }
  }
}

function* getNotifications() {
  while (true) {
    const { payload } = yield take(getNotificationsRequest.type);

    const { payloadData, responseCallback } = payload;
    try {
      const response = yield call(
        callRequest,
        GET_DRAWING_ACCESS_HISTORY,
        '',
        getDrawingNotificationParams(payloadData?.id, 'notifications'),
        '',
        {}
      );

      if (response?.length) {
        yield put(getNotificationsSuccess(response));
        if (responseCallback) responseCallback(true, response);
      } else {
        if (responseCallback) responseCallback(false, response);
      }
    } catch (error) {
      if (responseCallback) responseCallback(false);
    }
  }
}

function* getCommentsNotifications() {
  while (true) {
    const { payload } = yield take(getCommentsNotificationsRequest.type);

    const { payloadData, responseCallback } = payload;
    try {
      const response = yield call(
        callRequest,
        GET_DRAWING_ACCESS_HISTORY,
        '',
        getDrawingNotificationParams(payloadData?.id, 'commentNotifs'),
        '',
        {}
      );

      if (response?.length) {
        yield put(setCommentNotification(response));
        if (responseCallback) responseCallback(true, response);
      } else {
        if (responseCallback) responseCallback(false, response);
      }
    } catch (error) {
      if (responseCallback) responseCallback(false);
    }
  }
}

function* updatedrawing() {
  while (true) {
    const { payload } = yield take(updateDrawingRequest.type);

    const { payloadData, responseCallback } = payload;
    try {
      const response = yield call(
        callRequest,
        CANCEL_REVISION,
        payloadData?.data,
        updateDrawingsParams(payloadData?.id, payloadData?.drawingId),
        '',
        {}
      );

      if (response.success) {
        if (responseCallback) responseCallback(true, response);
      } else {
        if (responseCallback) responseCallback(false, response);
      }
    } catch (error) {
      if (responseCallback) responseCallback(false);
    }
  }
}

function* getUploadingFileUrlLoop() {
  yield takeEvery(getUploadUrlsRequest.type, getUploadingFileUrl);
}
function* getUploadingFileUrl(action) {
  // while (true) {
  const { payload } = action;

  const { payloadData, responseCallback } = payload;
  const requestUrl = {
    ...GET_UPLOAD_URL,
    route: GET_UPLOAD_URL.route
      .replace(':projectId', payloadData?.id)
      .replace(':drawingId', payloadData?.drawingId)
  };
  try {
    const response = yield call(callRequest, requestUrl, {}, '', '', {});

    console.log({ response });

    if (response.success) {
      if (responseCallback) responseCallback(true, response);
    } else {
      if (responseCallback) responseCallback(false, response);
    }
  } catch (error) {
    if (responseCallback) responseCallback(false);
  }
  // }
}
function* downloadDrawingsRegister() {
  while (true) {
    const { payload } = yield take(downnloadDrawingsRegisterRequest.type);

    const { payloadData, responseCallback } = payload;
    const requestUrl = {
      ...DOWNLOAD_DRAWINGS_REGISTER,
      route:
        DOWNLOAD_DRAWINGS_REGISTER.route.replace(
          ':projectId',
          payloadData?.id
        ) + `?type=${payloadData?.type}`
    };
    try {
      const response = yield call(
        callRequest,
        requestUrl,
        {},
        '',
        '',
        {},
        BASE_URL,
        true
      );
      if (response) {
        console.log({ response });

        const disposition = response.headers['content-disposition'];
        console.log({ disposition });
        let filename = disposition.split(/;(.+)/)[1].split(/=(.+)/)[1];
        if (filename.toLowerCase().startsWith("utf-8''"))
          filename = decodeURIComponent(filename.replace("utf-8''", ''));
        else filename = filename.replace(/['"]/g, '');

        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(new Blob([response.data]));
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        link.remove();
        if (responseCallback) responseCallback(true, response);
      } else {
        if (responseCallback) responseCallback(false, response);
      }
    } catch (error) {
      if (responseCallback) responseCallback(false);
    }
  }
}

function* getDrawingUnregisterUser() {
  while (true) {
    const { payload } = yield take(getDrawingUnregisterUserRequest.type);
    const { payloadData, responseCallback } = payload;
    console.log({ payloadData });
    const requestUrl = {
      ...GET_DRAWING_UNREGISTER_USER,
      route: GET_DRAWING_UNREGISTER_USER.route.replace(
        ':notifiId',
        payloadData?.notifiId
      )
    };
    try {
      const response = yield call(callRequest, requestUrl, {}, '', '', {});

      if (response) {
        if (responseCallback) responseCallback(true, response);
      } else {
        if (responseCallback) responseCallback(false, response);
      }
    } catch (error) {
      if (responseCallback) responseCallback(false);
    }
  }
}
function* uploadDwgRevision() {
  while (true) {
    const { payload } = yield take(uploadDWGRevisionRequest.type);
    const { payloadData, responseCallback } = payload;
    const { drawingid } = payloadData;

    try {
      const response = yield call(
        callRequest,
        UPLOAD_DWG,
        { drawingid },
        '',
        '',
        {}
      );

      if (response) {
        if (responseCallback) responseCallback(true, response);
      } else {
        if (responseCallback) responseCallback(false, response);
      }
    } catch (error) {
      if (responseCallback) responseCallback(false);
    }
  }
}
// new drawing  PAGE APIS
export default function* root() {
  yield fork(updatedrawing);
  yield fork(getCommentsNotifications);
  yield fork(getNotifications);
  yield fork(getDrawingRevisions);
  yield fork(getRevisionAccessHistory);
  yield fork(getCirclesActivity);
  yield fork(getDrawingsActivity);
  yield fork(getDrawingsSeenActivity);
  yield fork(updateDrawingPermission);
  yield fork(addRevisionPerfLog);
  yield fork(getDrawingDetail);
  yield fork(createDrawingLoop);
  yield fork(addRevisionLoop);
  yield fork(getUploadingFileUrlLoop);
  yield fork(getDrawings);
  yield fork(shareDrawing);
  yield fork(cancelRevision);
  yield fork(downloadProjectDrawing);
  yield fork(deleteDrawing);
  yield fork(drawingAccessRequest);
  yield fork(getRevisionsSeenActivity);
  yield fork(downloadDrawingsRegister);
  yield fork(getDrawingUnregisterUser);
  yield fork(uploadDwgRevision);
}
