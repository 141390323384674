import { combineReducers } from '@reduxjs/toolkit';

import general from './general';
import user from './user';
import subscription from './subscription';
import projects from './projects';
import drawing from './drawing';
import comments from './comments';
import upload from './upload';
import circle from './circle';
export default combineReducers({
  drawing,
  comments,
  general,
  user,
  subscription,
  projects,
  upload,
  circle
});
