import React, { useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { Error } from '../modules';
import { PAGE_ROUTES, ACCESS_TYPES } from '../constants';
import {
  PrivateSharedLayout,
  AuthSharedLayout,
  PublicSharedLayout
} from '../sharedLayouts';

const renderRouteSharedLayout = (access, component, breadCrumbs) => (
  <React.Fragment>
    {access === ACCESS_TYPES.AUTH ? (
      <AuthSharedLayout> {component}</AuthSharedLayout>
    ) : access === ACCESS_TYPES.PRIVATE ? (
      <PrivateSharedLayout breadCrumbs={breadCrumbs}>
        {component}
      </PrivateSharedLayout>
    ) : (
      <PublicSharedLayout>{component}</PublicSharedLayout>
    )}
  </React.Fragment>
);

const PageRoutes = () => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      <Routes>
        {PAGE_ROUTES.map((item, index) => (
          <Route
            path={item.route}
            element={renderRouteSharedLayout(
              item.access,
              item.component,
              item.breadCrumbs
            )}
            key={index}
          />
        ))}
        <Route path="*" element={<Error />} />
      </Routes>
    </>
  );
};

export default PageRoutes;
