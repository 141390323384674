import { Button, Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import logo from '../../../assets/logo/Logo1.png';
import { acceptCircleRequest } from '../../../redux/slicers/projects';
import { Images } from '../../../theme';

import './styles.scss';
function ConfirmCircle() {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isAccepted, setAccepted] = useState(false);

  useEffect(() => {
    dispatch(
      acceptCircleRequest({
        payloadData: params,
        responseCallback: (res) => {
          setAccepted(res);
        }
      })
    );
  }, []);
  return (
    <span className="confirm-circle-wrapper">
      <Row style={{ width: '100%' }}>
        <Col
          xl={{ span: 24 }}
          lg={{ span: 24 }}
          md={{ span: 24 }}
          sm={{ span: 24 }}
          xs={{ span: 24 }}
        >
          <Row align="middle" justify="center" gutter={[0, 24]}>
            <Col
              xl={{ span: 13 }}
              lg={{ span: 13 }}
              md={{ span: 13 }}
              xs={{ span: 22 }}
              sm={{ span: 22 }}
            >
              <span className="confirm-site-logo-wrapper">
                <img src={logo} className="logo-login" />
                <h1>
                  <b>Site</b>Circles
                </h1>
              </span>
            </Col>
            <Col
              xl={{ span: 13 }}
              lg={{ span: 13 }}
              md={{ span: 13 }}
              xs={{ span: 23 }}
              sm={{ span: 23 }}
            >
              <span className="confirm-site-cross-logo-wrapper">
                {!isAccepted ? (
                  <>
                    <img src={Images.CrossIcon} className="logo-login" />
                    <h6>
                      Could not accept your circle invitation at this time.
                    </h6>
                    :
                  </>
                ) : (
                  <>
                    <img src={Images.tick2} className="logo-login" />
                    <h6>
                      You have successfully registered for notifications from
                      this circle.
                    </h6>
                  </>
                )}
              </span>
            </Col>
            <Col
              xl={{ span: 13 }}
              lg={{ span: 13 }}
              md={{ span: 13 }}
              xs={{ span: 23 }}
              sm={{ span: 23 }}
            >
              <span className="confirm-site-arrowdown-logo-wrapper">
                <h6>
                  Register now to get full access to all the features.
                  Registration is quick and FREE.
                </h6>
                <Button
                  onClick={() => {
                    navigate('/register');
                  }}
                  size="large"
                  className="register-btn"
                >
                  Register Now
                </Button>
                <img src={Images.ArrowDown} />
              </span>
            </Col>
            <Col
              xl={{ span: 13 }}
              lg={{ span: 13 }}
              md={{ span: 13 }}
              xs={{ span: 22 }}
              sm={{ span: 22 }}
            >
              <span className="confirm-site-iframe-logo-wrapper">
                <h6>What's SiteCircles all about?</h6>
                <iframe
                  width="420"
                  height="345"
                  src="https://www.youtube.com/embed/5iqaMn5UjhY"
                  title="SiteCircles"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                  className="video-iframe"
                ></iframe>
              </span>
            </Col>
          </Row>
        </Col>
      </Row>
    </span>
  );
}

export default ConfirmCircle;
