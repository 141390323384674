import { take, call, fork } from 'redux-saga/effects';
import {
  callRequest,
  CREATE_DRAWING_URL,
  GET_PROJECT_DRAWINGS_URL
} from '../../config/webService';
import {
  addDrawingCommentsParameter,
  getDrawingCommentsParameter
} from '../../services/utils';

import { addCommentRequest, getCommentsRequest } from '../slicers/comments';

function* getDrawingComments() {
  while (true) {
    const { payload } = yield take(getCommentsRequest.type);
    const { payloadData, responseCallback } = payload;

    try {
      const response = yield call(
        callRequest,
        GET_PROJECT_DRAWINGS_URL,
        '',
        getDrawingCommentsParameter(payloadData?.id, payloadData?.drawingId),
        '',
        {}
      );
      if (response) {
        if (responseCallback) responseCallback(true, response);
      } else {
        if (responseCallback) responseCallback(false, response);
      }
    } catch (err) {
      if (responseCallback) responseCallback(false, err);
    }
  }
}
function* addDrawingComment() {
  while (true) {
    const { payload } = yield take(addCommentRequest.type);
    const { payloadData, responseCallback } = payload;

    try {
      const response = yield call(
        callRequest,
        CREATE_DRAWING_URL,
        { text: payloadData?.text },
        addDrawingCommentsParameter(payloadData?.id, payloadData?.drawingId),
        '',
        {}
      );
      if (response.success) {
        if (responseCallback) responseCallback(true, response);
      } else {
        if (responseCallback) responseCallback(false, response);
      }
    } catch (err) {
      if (responseCallback) responseCallback(false, err);
    }
  }
}

// new drawing  PAGE APIS
export default function* root() {
  yield fork(addDrawingComment);
  yield fork(getDrawingComments);
}
