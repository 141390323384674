import { Button, Form, Input } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { BeatLoader } from 'react-spinners';
import { ALERT_TYPES } from '../../../constants';
import {
  addCommentRequest,
  getCommentsRequest
} from '../../../redux/slicers/comments';
import { getDrawingsRequest } from '../../../redux/slicers/drawing';
import { toastAlert } from '../../../services/utils';
import './styles.scss';
function Comments() {
  const [form] = Form.useForm();
  const [msgs, setmsgs] = useState(null);
  const [loading, setLoading] = useState(true);
  const params = useParams();
  const commenRef = useRef(null);
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { drawing } = state;
  const handleSetMsgs = (data) => {
    setmsgs(data);
  };
  useEffect(() => {
    if (params?.id) {
      dispatch(
        getDrawingsRequest({
          payloadData: params?.id,
          responseCallback: () => {}
        })
      );
      dispatch(
        getCommentsRequest({
          payloadData: { id: params?.id, drawingId: params?.drawingId },
          responseCallback: (status, res) => {
            if (status && res) {
              handleSetMsgs(res);
              setLoading(false);
            } else {
              setLoading(false);
            }
          }
        })
      );
    }
  }, []);
  const handleSubmitMsg = (values) => {
    //
    dispatch(
      addCommentRequest({
        payloadData: {
          ...values,
          id: params?.id,
          drawingId: params?.drawingId
        },
        responseCallback: (status, res) => {
          if (status && res) {
            form.resetFields();
            dispatch(
              getCommentsRequest({
                payloadData: { id: params?.id, drawingId: params?.drawingId },
                responseCallback: (status, res) => {
                  if (status && res) {
                    handleSetMsgs(res);
                  }
                }
              })
            );
          } else if (!status && res.msg) {
            toastAlert(res.msg, ALERT_TYPES.error);
          }
        }
      })
    );
  };
  useEffect(() => {
    commenRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [msgs?.comments]);
  const currDrawing = drawing?.projectDrawings?.find(
    (dt) => dt?._id === params?.drawingId
  );
  return (
    <section className="archived-comments">
      <div className="comment-cont">
        {loading ? (
          <span className="comment-loader-wrapper">
            <BeatLoader size={20} color="#000" />
          </span>
        ) : (
          <>
            <span
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                height: '65vh'
              }}
            >
              <div className="content-header">
                <div className="header-content comment-header">
                  <h5>
                    {' '}
                    Comments for <b>{currDrawing?.dname}</b>
                  </h5>
                </div>
              </div>

              <div className="table-cont-comment">
                {msgs?.comments?.map((item) => (
                  <div className="comment-info" key={item?._id} ref={commenRef}>
                    <p>
                      <b>{`${item?.userfname} ${item?.userlname}`}</b> -Revision{' '}
                      {item?.rnumber}
                    </p>

                    <div className="comment-date">
                      <p className="comment-message">{item?.text}</p>
                      <p>
                        {`[${moment(item?.cdatetime).format(
                          'DD/MM/YYYY hh:mm a'
                        )}]`}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
              {/* )} */}
            </span>

            <div className="comment-input-area">
              <div className="msg-label">
                <h5>Write a comment</h5>
              </div>
              <div className="input-cont">
                <Form
                  form={form}
                  onFinish={handleSubmitMsg}
                  className="input-cont-inner"
                  labelCol={{
                    span: 24
                  }}
                  wrapperCol={{
                    span: 24
                  }}
                >
                  <Form.Item
                    name={'text'}
                    className="send-input-wrapper"
                    rules={[{ required: true, message: 'Message is Required' }]}
                  >
                    <Input className="send-msg-input" />
                  </Form.Item>
                  <Button
                    size="small"
                    htmlType="submit"
                    className="comment-btn"
                  >
                    Add Comment
                  </Button>
                  <Button
                    size="small"
                    htmlType="submit"
                    className="comment-btn-plus"
                  >
                    +
                  </Button>
                </Form>
              </div>
            </div>
          </>
        )}
      </div>
    </section>
  );
}

export default Comments;
