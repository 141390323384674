import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DashboardContent } from '../../../components';
import {
  getArchivedProjectRequest,
  getNotificationCountRequest
} from '../../../redux/slicers/projects';
import './styles.scss';
const ArchivedProjects = () => {
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { projects } = state;

  const { archivedProjectList } = projects;
  const userArchivedProjects = archivedProjectList?.map((item) => {
    return {
      ...item,
      status: item?.status ? 'Active' : 'Inactive',
      creationDatetime: item?.creationDatetime
        ? moment(item?.creationDatetime, 'YYYY-MM-DD').format('YYYY-MM-DD')
        : 'Never Updated'
    };
  });

  const initialRequest = () => {
    setLoading(true);
    dispatch(
      getArchivedProjectRequest({
        payloadData: null,
        responseCallback: () => {
          setLoading(false);
        }
      })
    );
    dispatch(
      getNotificationCountRequest({
        payloadData: null,
        responseCallback: () => {}
      })
    );
  };

  useEffect(() => {
    initialRequest();
  }, []);
  return (
    <section className="archived">
      <DashboardContent
        archived={true}
        projectList={userArchivedProjects || []}
        loading={loading}
        initialRequest={initialRequest}
      />
    </section>
  );
};

export default ArchivedProjects;
