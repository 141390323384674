import React from 'react';
import { Form, Input, Modal } from 'antd';
import './styles.scss';
import Loader from '../loader';
import { ClipLoader } from 'react-spinners';
import { Images } from '../../theme';
import { useState } from 'react';
import _ from 'lodash';
import { isEmailValid } from '../../services/utils';
const ShareModal = ({
  handleClose,
  onSubmit,
  title = '',
  loading = false,
  isModalShare,
  isModalEnterShareEmail,
  setShareEmail,
  shareEmail,
  isShareIndividual,
  drawingName,
  recipient,
  handleShare,
  isLoading = false
}) => {
  const handleSubmit = () => {
    onSubmit();
  };
  const [emails, setEmails] = useState([]);
  const [error, setError] = useState(false);
  const [form] = Form.useForm();
  const onEnterEmailField = (emails) => {
    const emailsArray = emails.split(',');
    let tempEmailArray = [];
    emailsArray.map((item) => {
      if (isEmailValid(item)) {
        tempEmailArray.push(item);
      }
    });
    setEmails(tempEmailArray);
    form.resetFields();
    setShareEmail(emailsArray);
  };

  const deleteItemEmail = (index) => {
    let tempdata = [...emails];
    tempdata.splice(index, 1);
    setEmails(tempdata);
    setShareEmail(tempdata);
  };

  return (
    <>
      {isModalEnterShareEmail && (
        <Modal
          visible={true}
          centered
          onCancel={handleClose}
          footer={null}
          closeIcon={
            <img className="cross-Icon-share" src={Images.crossIcon} alt="" />
          }
          width={600}
          className="bulk-share-modal-emails"
        >
          <div className="share-wrapper-div">
            <div className="modal-title-div">
              <p className="modal-title">Bulk Share</p>
            </div>

            <div className="comma-line-bulk-share">
              <p>
                Add email list and use a (comma) to separate the email addresses
              </p>
            </div>
            <div className="share-email-input-div">
              <Form form={form}>
                <Form.Item name={'email'}>
                  <Input
                    placeholder="Email"
                    className="share-email-input"
                    onPressEnter={(e) => {
                      onEnterEmailField(e?.target?.value);
                    }}
                    onChange={() => {
                      setError(false);
                    }}
                  />
                </Form.Item>
              </Form>
            </div>
            <div className="share-emails-list-div">
              <p>Sharing list:</p>
              <div className="share-email-list">
                {emails?.map((item, index) => (
                  <div key={index} className="inner-div-share-listing">
                    <p key={index} className="drawings-name">
                      {item}
                    </p>
                    <img
                      onClick={() => {
                        deleteItemEmail(index);
                      }}
                      src={Images.crossIcon}
                      alt=""
                    />
                  </div>
                ))}
              </div>
              {error && (
                <p className="error-txt-share-emails">
                  Please enter the emails for share drawings
                </p>
              )}
            </div>
            <div
              type="button"
              className="share-btn-center-modal"
              onClick={() => {
                !_.isEmpty(emails) ? handleSubmit() : setError(true);
              }}
            >
              <p>{'Share'}</p>
            </div>
          </div>
        </Modal>
      )}
      {isModalShare && (
        <Modal
          visible={true}
          centered
          onCancel={handleClose}
          footer={null}
          closeIcon={
            <img className="cross-Icon-share" src={Images.crossIcon} alt="" />
          }
          width={600}
          className="certer-modal"
        >
          <div className="share-modal-wrapper-div">
            <div className="iconCrow-div">
              <img src={Images.questionIcon} />
            </div>

            <div className="modal-title-div">
              <p className="modal-title">{title} </p>
            </div>

            <p className="sharing-user">
              You are sharing to the following users:
            </p>

            <div className="share-emails-list-div">
              <p>Sharing list:</p>
              <div className="share-email-list-view">
                <ul className="share-email-scroll-box">
                  {shareEmail?.map((item, index) => (
                    <li className="inner-div-share-listing" key={index}>
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <div className="cancel-share-div">
              <div
                type="button"
                className="share-btn-center-modal"
                onClick={handleSubmit}
              >
                <p>
                  {loading ? <Loader Type={ClipLoader} size={20} /> : 'Share'}
                </p>
              </div>
            </div>
          </div>
        </Modal>
      )}
      {isShareIndividual && (
        <Modal
          visible={true}
          centered
          onCancel={handleClose}
          footer={null}
          closeIcon={
            <img className="cross-Icon-share" src={Images.crossIcon} alt="" />
          }
          width={600}
          className="certer-modal"
        >
          <div className="modal-wrapper-div">
            <div className="iconCrow-div">
              <img src={Images.questionIcon} />
            </div>

            <div className="modal-title-div">
              <p className="modal-title">{title} </p>
            </div>

            <p className="email-share-user-txt">{drawingName}</p>
            <div className="share-to-txt">
              <p className="modal-title">to </p>
            </div>

            <p className="email-share-user-txt">{recipient}</p>
            <div className="bottom-btn-notification">
              <div
                className="cancel-btn-notification-center-modal"
                onClick={handleClose}
              >
                <p>{'Cancel'}</p>
              </div>
              <div
                className="confirm-btn-notification-center-modal"
                onClick={handleShare}
              >
                <p>
                  {isLoading ? <Loader Type={ClipLoader} size={20} /> : 'Share'}
                </p>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default ShareModal;
