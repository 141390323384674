import React from 'react';
import './styles.scss';

const Footer = () => {
  return (
    <footer className="web-footer">
      <p>Footer</p>
    </footer>
  );
};

export default Footer;
