// @flow
import { createSlice } from '@reduxjs/toolkit';
import Immutable from 'seamless-immutable';

const CommentsReducer = createSlice({
  name: 'comments',
  initialState: Immutable({}),
  reducers: {
    getCommentsRequest() {},
    addCommentRequest() {}
  }
});

export const { addCommentRequest, getCommentsRequest } =
  CommentsReducer.actions;

export default CommentsReducer.reducer;
