import { take, call, fork, put } from 'redux-saga/effects';
import { ALERT_TYPES } from '../../constants';
import {
  BULK_UPLOAD_VERIFY,
  callRequest,
  UPLOAD_REVISION_FILE_BUCKET
} from '../../config/webService';
import { toastAlert } from '../../services/utils';
import {
  bulkUploadVerifyRequest,
  bulkUploadVerifySuccess,
  uploadRevisionFileRequest
} from '../slicers/upload';
import _ from 'lodash';

function* uploadRevisionFile() {
  while (true) {
    // PAYLOAD PATTERN COMING FROM REDUX-TOOLKIT
    const { payload } = yield take(uploadRevisionFileRequest.type);
    const { payloadData, responseCallback } = payload;
    try {
      const response = yield call(
        callRequest,
        UPLOAD_REVISION_FILE_BUCKET,
        payloadData.data,
        '',
        '',
        {},
        payloadData.baseUrl
      );

      if (response.status) {
        if (responseCallback) responseCallback(true, response);
      } else {
        if (responseCallback) responseCallback(false, response);
        if (response.message) toastAlert(response.message, ALERT_TYPES.error);
      }
    } catch (err) {
      if (responseCallback) responseCallback(err);
    }
  }
}
function* bulkUploadVerify() {
  while (true) {
    // PAYLOAD PATTERN COMING FROM REDUX-TOOLKIT
    const { payload } = yield take(bulkUploadVerifyRequest.type);
    const { payloadData, responseCallback } = payload;

    const requestUrl = {
      ...BULK_UPLOAD_VERIFY,
      route: BULK_UPLOAD_VERIFY.route.replace(
        ':projectId',
        payloadData?.payload?.Pid
      )
    };
    try {
      const response = yield call(
        callRequest,
        requestUrl,
        payloadData?.payload?.data,
        '',
        '',
        {},
        payloadData.baseUrl
      );

      if (response.length > 0) {
        let tempArray = _.cloneDeep(response);
        tempArray = tempArray.map((item) => {
          const tempObj = _.cloneDeep(item);
          tempObj['latestRevision'] = item?.rnumber;
          delete tempObj['rnumber'];
          return tempObj;
        });
        console.log({ tempArray });
        yield put(bulkUploadVerifySuccess(tempArray));
        if (responseCallback) responseCallback(true, response);
      } else {
        if (responseCallback) responseCallback(false, response);
        if (response.message) toastAlert(response.message, ALERT_TYPES.error);
      }
    } catch (err) {
      if (responseCallback) responseCallback(err);
    }
  }
}
export default function* root() {
  yield fork(uploadRevisionFile);
  yield fork(bulkUploadVerify);
}
