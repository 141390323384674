import { Popover, Table } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { BeatLoader } from 'react-spinners';
import { ConfirmationModal } from '../../../components';
import {
  ALERT_TYPES,
  REVISIONS_LIST_COLUMNS,
  DRAWING_ACCESS_HISTORY_ROUTE,
  REVISION_STATUS
} from '../../../constants';
import { downloadFile } from '../../../helpers.js';
import {
  cancelRevisionRequest,
  downloadDrawingRequest,
  getDrawingsRevisionRequest
} from '../../../redux/slicers/drawing';
import { toastAlert } from '../../../services/utils';
import { Images } from '../../../theme';
import './styles.scss';

const DrawingRevisions = () => {
  const [loading, setLoading] = useState(false);
  const { did, pid } = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = useState(null);
  const [open, setopen] = useState({
    title: '',
    desc: '',
    action: '',
    bool: false,
    select: null
  });
  const state = useSelector((state) => state);
  const { projects } = state;

  const user = state?.user?.data?.data;
  const project = projects?.projectList?.find((p) => p?._id === pid);

  const handleClose = () => {
    setopen({ action: '', bool: false, select: null, title: '', desc: '' });
  };
  const handleCancelRevision = (cid, rno) => {
    const payload = {
      data: { circleid: cid },
      pid: pid,
      did: did,
      rnumber: rno,
      action:
        open.action === 'cancel'
          ? REVISION_STATUS.cancel
          : REVISION_STATUS.remove
    };

    dispatch(
      cancelRevisionRequest({
        payloadData: payload,
        responseCallback: (status, res) => {
          if (status && res) {
            toastAlert(res.msg, ALERT_TYPES.success);
            handleGetRevisions();
          } else if (!status && !res.success) {
            toastAlert(res.msg, ALERT_TYPES.error);
          }
          handleClose();
        }
      })
    );
  };

  useEffect(() => {
    setLoading(true);
    dispatch(
      getDrawingsRevisionRequest({
        payloadData: { drawingid: did },
        responseCallback: (status, res) => {
          setLoading(false);
          if (res.length) {
            setData(res[0]);
          } else {
            setData(null);
          }
        }
      })
    );
  }, []);
  const handleGetRevisions = () => {
    dispatch(
      getDrawingsRevisionRequest({
        payloadData: { drawingid: did },
        responseCallback: (status, res) => {
          if (res.length) {
            setData(res[0]);
          } else {
            setData(null);
          }
        }
      })
    );
  };

  const allRevisions =
    data?.revisions?.map((item) => {
      let obj = {
        ...item,
        rnumber: (
          <span
            className={
              item?.status === REVISION_STATUS.cancel
                ? 'span-cancelled'
                : item?.status === REVISION_STATUS.remove
                ? 'span-remove'
                : ''
            }
            onClick={() => {
              downloadRevision(item, 'PDF');
            }}
          >
            {item?.rnumber}
          </span>
        ),
        uploader: (
          <span
            className={
              item?.status === REVISION_STATUS.cancel
                ? 'span-cancelled'
                : item?.status === REVISION_STATUS.remove
                ? 'span-remove'
                : ''
            }
            onClick={() => {
              downloadRevision(item, 'PDF');
            }}
          >{`${item?.userfname} ${item?.userlname}`}</span>
        ),
        rdatetime: (
          <span
            className={
              item?.status === REVISION_STATUS.cancel
                ? 'span-cancelled'
                : item?.status === REVISION_STATUS.remove
                ? 'span-remove'
                : ''
            }
            onClick={() => {
              downloadRevision(item, 'PDF');
            }}
          >
            {moment(item?.rdatetime).format('YYYY-MM-DD')}
          </span>
        ),
        status: (
          <span
            className={
              item?.status === REVISION_STATUS.cancel
                ? 'span-cancelled'
                : item?.status === REVISION_STATUS.remove
                ? 'span-remove'
                : ''
            }
            onClick={() => {
              downloadRevision(item, 'PDF');
            }}
          >
            {item?.status}
          </span>
        ),
        download: (
          <span
            className={
              item?.status === REVISION_STATUS.cancel
                ? 'span-cancelled'
                : item?.status === REVISION_STATUS.remove
                ? 'span-remove'
                : ''
            }
          >
            <div className="download-txt-div">
              {!item?.extension && (
                <>
                  <p
                    onClick={() => {
                      downloadRevision(item, 'PDF');
                    }}
                  >
                    PDF
                  </p>
                  <p
                    className={
                      !item?.rurl_dwg ? 'dwg-disable-txt-revisions' : ''
                    }
                    onClick={() => {
                      item?.rurl_dwg && downloadRevision(item, 'DWG');
                    }}
                  >
                    DWG
                  </p>
                </>
              )}

              {item?.extension && (
                <p
                  onClick={() => {
                    downloadRevision(item, 'model');
                  }}
                  style={{ textTransform: 'uppercase' }}
                >
                  {item?.extension}
                </p>
              )}
            </div>
          </span>
        ),
        notes: (
          <span
            className={
              item?.status === REVISION_STATUS.cancel
                ? 'span-cancelled'
                : item?.status === REVISION_STATUS.remove
                ? 'span-remove'
                : ''
            }
            onClick={() => {
              downloadRevision(item, 'PDF');
            }}
          >
            {item?.notes}
          </span>
        ),
        action: (
          <span>
            <Popover
              // className="popover-options"
              placement="bottomRight"
              trigger="click"
              content={
                <div className="options-cont">
                  <div
                    className="options-item"
                    onClick={() => {
                      let accessHistoryRoute =
                        DRAWING_ACCESS_HISTORY_ROUTE.replace(':pid', pid);
                      accessHistoryRoute = accessHistoryRoute.replace(
                        ':did',
                        did
                      );
                      accessHistoryRoute = accessHistoryRoute.replace(
                        ':rid',
                        item?.rnumber
                      );
                      handleRoute(accessHistoryRoute);
                    }}
                  >
                    <a className="">Access History</a>
                  </div>

                  {user?.id === project?.creatorid && (
                    <div
                      className="options-item"
                      onClick={() => {
                        if (item?.status === REVISION_STATUS.remove) {
                          toastAlert(
                            'This Revision is Already Removed!',
                            ALERT_TYPES.error
                          );
                        } else {
                          setopen({
                            action: 'remove',
                            bool: true,
                            select: item?.rnumber,
                            title: 'Removing Revision!',
                            desc: 'Are you sure you want to remove this revision!'
                          });
                        }
                      }}
                    >
                      <a className="">Remove Revision</a>
                    </div>
                  )}
                  <div
                    className="options-item"
                    onClick={() => {
                      if (item?.status === REVISION_STATUS.cancel) {
                        toastAlert(
                          'This Revision is Already Cancelled!',
                          ALERT_TYPES.error
                        );
                      } else {
                        setopen({
                          action: 'cancel',
                          bool: true,
                          select: item?.rnumber,
                          title: 'Cancelling Revision!',
                          desc: 'Are you sure you want to cancel this revision!'
                        });
                      }
                    }}
                  >
                    <a className="">Cancel Revision</a>
                  </div>
                </div>
              }
              // onConfirm={() => handleDelete(record.key)}
            >
              <img src={Images.Elipse} alt="" width={15} />
            </Popover>
          </span>
        )
      };
      return obj;
    }) ?? [];
  const handleRoute = (url) => {
    navigate(url);
  };
  const handleSubmit = () => {
    handleCancelRevision(data?._id?.circleid, open?.select);
  };
  const handleDownloadFile = (drawing, revision, url) => {
    const name = `${drawing?.dnumber}_${drawing?.dname}_${revision?.rnumber}`;
    downloadFile(url, name);
  };
  const downloadRevision = (item, fileType) => {
    if (item?._id) {
      const payloadData = {
        data: {
          dName: item.dname,
          dNumber: item?.dnumber,
          rNum: item?.rnumber
        },
        pId: pid,
        drawingId: did,
        rurl: item?.rurl
      };
      dispatch(
        downloadDrawingRequest({
          payloadData,
          responseCallback: (status, res) => {
            if (status) {
              handleDownloadFile(
                item,
                item?.rnumber,
                fileType === 'model'
                  ? res?.fileUrl
                  : _.isEqual(fileType, 'PDF')
                  ? res.pdfUrl
                  : res.pdfDwg
              );
            }
          }
        })
      );
    } else {
      toastAlert('No revision for this drawing.', ALERT_TYPES.info);
    }
  };
  return (
    <section className="archived">
      {loading ? (
        <BeatLoader color={'#fff'} size={30} />
      ) : (
        <>
          <div className="revisions-cont">
            <div className="revisions-header">
              <span className="search-title-wrapper">
                <h4>
                  Revisions for <b> {data?._id?.dname}</b>
                </h4>
                {/* <Input
                  placeholder="Search"
                  value={search}
                  onChange={(e) => setsearch(e.target.value)}
                  className="input-wrapper"
                /> */}
              </span>
            </div>
            <div className="revisions-content">
              <Table
                columns={REVISIONS_LIST_COLUMNS()}
                dataSource={allRevisions}
                pagination={false}
                rowClassName={'drawings-revions'}
              />
            </div>
          </div>
          {open.bool && (
            <ConfirmationModal
              title={open.title}
              description={open.desc}
              onSubmit={handleSubmit}
              handleClose={handleClose}
            />
          )}
        </>
      )}
    </section>
  );
};

export default DrawingRevisions;
