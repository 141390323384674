// @flow
import { createSlice } from '@reduxjs/toolkit';
import Immutable from 'seamless-immutable';

const CommentsReducer = createSlice({
  name: 'circle',
  initialState: Immutable({
    circlesCheckBoxArray: {}
  }),
  reducers: {
    circleIsCheckBox(state, action) {
      var tempData = {};

      Object.assign(tempData, {
        [action?.payload?.circleId]: {
          isCheckBox: true
        }
      });
      state.circlesCheckBoxArray = tempData;
    }
  }
});

export const { circleIsCheckBox } = CommentsReducer.actions;

export default CommentsReducer.reducer;
