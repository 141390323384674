import React, { useState } from 'react';
import './auth.scss';
import { Button, Form, Input } from 'antd';
import { useNavigate } from 'react-router-dom';
import { Col, Row } from 'antd';
import { ALERT_TYPES, RegisterFields, SUCCESS_MESSAGES } from '../../constants';
import { useDispatch } from 'react-redux';
import { userSignUpRequest } from '../../redux/slicers/user';
import { toastAlert } from '../../services/utils';
import { BeatLoader } from 'react-spinners';

function Register() {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const { fields } = RegisterFields;
  const { firstName, lastName, email, password, companyName, confirmPassword } =
    fields;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = () => {
    const values = form.getFieldsValue();
    setIsLoading(true);
    const payload = {
      fname: values.firstName,
      lname: values.lastName,
      cname: values.companyName,
      email: values.email,
      password: values.password
    };

    dispatch(
      userSignUpRequest({
        payloadData: payload,
        responseCallback: (status, res) => {
          if (!status) {
            toastAlert(res.msg, ALERT_TYPES.error);
            setIsLoading(false);
          } else {
            setIsLoading(false);
            toastAlert(SUCCESS_MESSAGES.REGISTRATION, ALERT_TYPES.success);
            navigate(`/verifyRegistration/${values.email}`);
          }
        }
      })
    );
  };
  return (
    <div className="login-container">
      <Row align={'middle'} justify="center">
        <Col xs={24} lg={24}>
          <div className="register-label-wrapper">
            <h3>Register</h3>
            <h4>Enter your details below to join SiteCircles</h4>
          </div>
        </Col>
        <Col xs={24} lg={10} sm={22} md={20}>
          <div className="register-form-wrapper">
            <Form
              form={form}
              onFinish={handleSubmit}
              className="form-signup"
              wrapperCol={{ span: 24 }}
              // wrapperCol={{align:"middle"},}
            >
              <Form.Item
                wrapperCol={{ span: 6 }}
                name={firstName.title}
                rules={firstName.rules}
              >
                <Input
                  type={firstName.type}
                  placeholder={firstName.label}
                  className="login-input reg-input"
                />
              </Form.Item>
              <Form.Item
                wrapperCol={{ span: 6 }}
                name={lastName.title}
                rules={lastName.rules}
              >
                <Input
                  type={lastName.type}
                  placeholder={lastName.label}
                  className="login-input reg-input"
                />
              </Form.Item>
              <Form.Item
                wrapperCol={{ span: 6 }}
                name={companyName.title}
                rules={companyName.rules}
              >
                <Input
                  type={companyName.type}
                  placeholder={companyName.label}
                  className="login-input reg-input"
                />
              </Form.Item>
              <Form.Item
                wrapperCol={{ span: 6 }}
                name={email.title}
                rules={email.rules}
              >
                <Input
                  type={email.type}
                  placeholder={email.label}
                  className="login-input reg-input"
                />
              </Form.Item>
              <Form.Item
                wrapperCol={{ span: 18 }}
                name={password.title}
                rules={password.rules}
              >
                <Input
                  type={password.type}
                  placeholder={password.label}
                  className="login-input reg-input"
                />
              </Form.Item>
              <Form.Item
                wrapperCol={{ span: 18 }}
                name={confirmPassword.title}
                rules={confirmPassword.rules}
              >
                <Input
                  type={confirmPassword.type}
                  placeholder={confirmPassword.label}
                  className="login-input reg-input"
                />
              </Form.Item>
              <div className="btn-cont">
                <div>
                  <Button
                    size="large"
                    className="back-btn"
                    onClick={() => {
                      navigate('/');
                    }}
                  >
                    Back
                  </Button>
                </div>
                <div>
                  <Button size="large" className="signup-btn" htmlType="submit">
                    {isLoading ? (
                      <BeatLoader
                        // Type={ClipLoader}
                        size={8}
                        color={'#fff'}
                      />
                    ) : (
                      'Sign Up Now'
                    )}
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        </Col>
      </Row>
      <div className="label-wrapper">
        <br />
        <a
          className="frgt-pass"
          target="_blank"
          rel="noreferrer"
          href="https://sitecircles.com"
        >
          What is SiteCircles?
        </a>
      </div>
    </div>
  );
}

export default Register;
