import { Breadcrumb, Popover } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import { ARCHIVED_ROUTE, DASHBOARD_ROUTE } from '../../constants';
import { Images } from '../../theme';

import './styles.scss';

function ArchiveProjectsBreadCrumb() {
  const content = () => {
    return (
      <div className="breadCrumbWrapper">
        <Link to={DASHBOARD_ROUTE} className="customBreadcrumbMobView">
          Dashboard
        </Link>

        <Link className="customBreadcrumbMobView" to={ARCHIVED_ROUTE}>
          Archive Project
        </Link>
      </div>
    );
  };

  return (
    <>
      <div className="breadCrumbWebView">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link className="customBreadcrumb" to={DASHBOARD_ROUTE}>
              Dashboard
            </Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item>
            <Link className="customBreadcrumb" to={ARCHIVED_ROUTE}>
              Archive Project
            </Link>
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>

      <div className="breadCrumbMobView">
        <Popover content={content()} trigger="click">
          <img src={Images.folder} height={30} width={30} />
        </Popover>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link className="customBreadcrumb" to={ARCHIVED_ROUTE}>
              Archive Project
            </Link>
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
    </>
  );
}

export default ArchiveProjectsBreadCrumb;
