import { take, call, fork, put } from 'redux-saga/effects';
import {
  contactUsForm,
  PrimiumProjectCreditRequest,
  PrimiumProjectCreditSucces
} from '../slicers/general';
import { ALERT_TYPES } from '../../constants';
import {
  callRequest,
  CONTACT_US,
  PRIMIUM_PROJECT_CREDIT
} from '../../config/webService';
import { toastAlert } from '../../services/utils';

function* contactUs() {
  while (true) {
    // PAYLOAD PATTERN COMING FROM REDUX-TOOLKIT
    const { payload } = yield take(contactUsForm.type);

    // PARAMETER SEND FROM DISPATCH WILL DESTRUCTURE THERE
    const { payloadData, responseCallback } = payload;
    try {
      const response = yield call(
        callRequest,
        CONTACT_US,
        payloadData,
        '',
        '',
        {}
      );
      if (response.status) {
        if (responseCallback) responseCallback(response);
      } else {
        if (responseCallback) responseCallback(response);
        if (response.message) toastAlert(response.message, ALERT_TYPES.error);
      }
    } catch (err) {
      if (responseCallback) responseCallback(err);
    }
  }
}

function* PrimiumProjectCredit() {
  while (true) {
    // PAYLOAD PATTERN COMING FROM REDUX-TOOLKIT
    const { payload } = yield take(PrimiumProjectCreditRequest.type);
    const { responseCallback } = payload;
    try {
      const response = yield call(
        callRequest,
        PRIMIUM_PROJECT_CREDIT,
        '',
        '',
        '',
        {}
      );
      if (response) {
        yield put(PrimiumProjectCreditSucces(response));
        if (responseCallback) responseCallback(response);
      } else {
        if (responseCallback) responseCallback(response);
        if (response.message) toastAlert(response.message, ALERT_TYPES.error);
      }
    } catch (err) {
      if (responseCallback) responseCallback(err);
    }
  }
}
export default function* root() {
  yield fork(contactUs);
  yield fork(PrimiumProjectCredit);
}
