// @flow
import { createSlice } from '@reduxjs/toolkit';
import Immutable from 'seamless-immutable';

const GeneralReducer = createSlice({
  name: 'general',
  initialState: Immutable({
    accessToken: '',
    primiumProject: {}
  }),
  reducers: {
    contactUsForm() {},
    PrimiumProjectCreditRequest() {},
    PrimiumProjectCreditSucces(state, action) {
      state.primiumProject = action.payload;
    }
  }
});

export const {
  contactUsForm,
  PrimiumProjectCreditRequest,
  PrimiumProjectCreditSucces
} = GeneralReducer.actions;

export default GeneralReducer.reducer;
