import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { DETAILS_ROUTE } from '../../../constants';
import { Images } from '../../../theme';
import ProgressBar from 'react-bootstrap/ProgressBar';
import './styles.scss';
import { bulkUploadingLoader } from '../../../redux/slicers/upload';
const UploadStep7 = () => {
  const params = useParams();
  const state = useSelector((state) => state);
  const { isBulklUploading, bulkFilesForUpload, uploadedFilesCount } =
    state?.upload;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // const handleUpload = async () => {
  //   setloader(true);
  //   bulkFilesForUpload.map((item) => {
  //     const idsWithAdmin = projects?.circleNames?.map((dt) => dt?._id);
  //     const idsWithNotAdmin = projects?.circleOptions?.map((dt) => dt?._id);
  //     const { dname, dnumber, circle, followercircle } = item;
  //     const adminId = idsWithAdmin?.filter(
  //       (dt) => !idsWithNotAdmin?.includes(dt)
  //     );
  //     const tempFollowercircle = followercircle ? followercircle : [];
  //     let payload = {
  //       dname: dname,
  //       dnumber: dnumber,
  //       circleid: circle?.circleId,
  //       followerCircles: [...adminId, ...tempFollowercircle]
  //     };
  //     if (!_.isUndefined(item?.drawingid)) {
  //       const payload = {
  //         id: params.id,
  //         drawingId: item?.drawingid
  //       };
  //       dispatch(
  //         getUploadUrlsRequest({
  //           payloadData: payload,
  //           responseCallback: (status, response) => {
  //             if (status) {
  //               if (item.dwgFile) {
  //                 uploadDwfFileToS3(response, item);
  //                 uploadPdfFileToS3(response, item, item?.drawingid);
  //               } else {
  //                 uploadPdfFileToS3(response, item, item?.drawingid);
  //               }
  //             }
  //           }
  //         })
  //       );
  //     } else {
  //       dispatch(
  //         createDrawingRequest({
  //           payloadData: { data: payload, id: params?.id },
  //           responseCallback: (status, res) => {
  //             if (status) {
  //               const payload = {
  //                 id: params.id,
  //                 drawingId: res?._id
  //               };
  //               dispatch(
  //                 getUploadUrlsRequest({
  //                   payloadData: payload,
  //                   responseCallback: (status, response) => {
  //                     if (status) {
  //                       if (item.dwgFile) {
  //                         uploadDwfFileToS3(response, item, res?._id);
  //                         uploadPdfFileToS3(response, item, res?._id);
  //                       } else {
  //                         uploadPdfFileToS3(response, item, res?._id);
  //                       }
  //                     }
  //                   }
  //                 })
  //               );
  //             }
  //           }
  //         })
  //       );
  //     }
  //   });
  // };

  // const uploadDwfFileToS3 = async (values, item) => {
  //   console.log({ values, item });
  //   const formData = new FormData();
  //   Object.keys(values?.dwg?.fields).map((keys) => {
  //     if (keys !== 'bucket') {
  //       formData.append(`${keys}`, values?.dwg?.fields[keys]);
  //     }
  //   });
  //   formData.append('file', item.dwgFile);
  //   axios
  //     .post(values?.dwg?.url, formData, {
  //       headers: {
  //         'Content-Type': 'multipart/form-data'
  //       }
  //     })
  //     .then(function (response) {
  //       console.log({ response });
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // };

  // const uploadPdfFileToS3 = async (values, item, drawingId) => {
  //   const formData = new FormData();
  //   Object.keys(values?.pdf?.fields).map((keys) => {
  //     if (keys !== 'bucket') {
  //       formData.append(`${keys}`, values?.pdf?.fields[keys]);
  //     }
  //   });
  //   formData.append('file', item?.file);
  //   axios
  //     .post(values?.pdf?.url, formData, {
  //       headers: {
  //         'Content-Type': 'multipart/form-data'
  //       }
  //     })
  //     .then(function () {
  //       apiReqForRevisionSubmit(item, drawingId);
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // };
  // const apiReqForRevisionSubmit = (values, drawingId) => {
  //   const { circle, dname, dnumber, status } = values;

  //   const addRevision = {
  //     ...values,
  //     projectid: params?.id,
  //     drawingid: drawingId,
  //     circle: circle?.circlename,
  //     dname,
  //     dnumber,
  //     status: !_.isEmpty(status) ? (status === 'No Status' ? '' : status) : '',
  //     hasDWG: values?.dwgFile ? true : false
  //   };

  //   let fileUploadStartTime = new Date(),
  //     fileUploadEndTime = new Date();

  //   const fileUploadDur =
  //     moment(fileUploadEndTime).diff(fileUploadStartTime, 'milliseconds') /
  //     1000;
  //   let revisionUploadStartTime = new Date(),
  //     revisionUploadEndTime = new Date();
  //   addRevisionReqSubmit(
  //     addRevision,
  //     () => {
  //       revisionUploadEndTime = moment();
  //       const revisionDuration =
  //         moment(revisionUploadEndTime).diff(
  //           revisionUploadStartTime,
  //           'milliseconds'
  //         ) / 1000;

  //       return revisionDuration;
  //     },
  //     fileUploadStartTime,
  //     fileUploadDur,
  //     values?.file,
  //     { ...values }
  //   );
  // };

  // const addRevisionReqSubmit = async (payload) => {
  //   await dispatch(
  //     addRevisionRequest({
  //       payloadData: { ...payload },
  //       responseCallback: () => {}
  //     })
  //   );
  //   setTimeout(() => {
  //     setloader(false);
  //     navigate(DETAILS_ROUTE.replace(':id', params?.id));
  //   }, 4000);
  // };

  useEffect(() => {
    if (uploadedFilesCount >= bulkFilesForUpload.length) {
      dispatch(bulkUploadingLoader(false));
    }
  }, [uploadedFilesCount, bulkFilesForUpload]);
  const renderBulkFinish = () => {
    return (
      <>
        <div className="upload-click-div">
          <img src={Images.rightTick}></img>
        </div>
        <p className="uploaded-txt">
          <span>Bulk Upload Complete</span>
        </p>
        <div
          onClick={() => {
            navigate(DETAILS_ROUTE.replace(':id', params?.id));
          }}
          className="next-Btn"
        >
          <p className="next-txt">Finish</p>
        </div>
      </>
    );
  };

  return (
    <div className="step7-main-div">
      {!isBulklUploading ? (
        renderBulkFinish()
      ) : (
        <div className="step7-loading-div">
          <ProgressBar
            label={`${uploadedFilesCount} / ${bulkFilesForUpload?.length} drawings`}
            now={uploadedFilesCount}
            max={uploadedFilesCount}
            min={0}
          />
          <h3 style={{ marginRight: 10 }}>
            Total {bulkFilesForUpload.length} drawings
          </h3>
          {/* <Loader size={10} Type={PulseLoader} color={Colors.boulder} /> */}
        </div>
      )}
    </div>
  );
};

export default UploadStep7;
