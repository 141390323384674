import { Button, Form, Input, Select } from 'antd';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { BeatLoader } from 'react-spinners';
import {
  ALERT_TYPES,
  CreateFields,
  SUCCESS_MESSAGES
} from '../../../constants';
import {
  editProjectRequest,
  getProjectViewDetailsRequest
} from '../../../redux/slicers/projects';
import { toastAlert } from '../../../services/utils';
import './styles.scss';
const EditProject = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [projectData, setProjectData] = useState();

  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { id } = useParams();
  useEffect(() => {
    const payload = { id: id };
    dispatch(
      getProjectViewDetailsRequest({
        payloadData: payload,
        responseCallback: (status, res) => {
          if (status) {
            setProjectData(res);
          }
        }
      })
    );
  }, []);
  useEffect(() => {
    form.setFieldsValue({
      projectName: projectData?.pname,
      companyName: projectData?.cname,
      projectDescription: projectData?.pdescrip,
      streetInfo: projectData?.paddress?.street,
      city: projectData?.paddress?.city,
      subUrb: projectData?.paddress?.suburb,
      country: projectData?.paddress?.country,
      province: projectData?.paddress?.province,
      cordinates: projectData?.paddress?.coordinates,
      status: projectData?.status
    });
  }, projectData);
  // if (projectData) {

  // }
  const {
    projectName,
    companyName,
    projectDescription,
    streetInfo,
    city,
    subUrb,
    country,
    province,
    cordinates
  } = CreateFields.fields;

  const handleSubmit = () => {
    setIsLoading(true);
    const values = form.getFieldsValue();
    const payload = {
      data: {
        cname: values.companyName,
        paddress: {
          coordinates: values.cordinates,
          street: values.streetInfo,
          suburb: values.subUrb,
          city: values.city,
          province: values.province,
          country: values.country
        },
        pdescrip: values.projectDescription,
        pname: values.projectName,
        status: values.status ? 'active' : 'inactive'
      },
      pid: id
    };

    dispatch(
      editProjectRequest({
        payloadData: payload,
        responseCallback: (status, res) => {
          if (!status) {
            toastAlert(res.msg, ALERT_TYPES.error);
            setIsLoading(false);
          } else {
            setIsLoading(false);
            toastAlert(SUCCESS_MESSAGES.UPDATE_PROJECT, ALERT_TYPES.success);
            // navigate("/dashboard");
          }
        }
      })
    );
  };
  return (
    <section className="new-cont">
      {!projectData ? (
        <BeatLoader size={30} color={'#fff'} />
      ) : (
        <div className="new-form">
          <h3>{projectData?.pname}</h3>
          <p>You can manage this project's information below.</p>
          <Form className="form-create" onFinish={handleSubmit} form={form}>
            <div className="input-cont">
              <div className="form-side">
                <Form.Item name={projectName.title} rules={projectName.rules}>
                  <Input
                    className="create-input"
                    placeholder={projectName.label}
                    type={projectName.type}
                  />
                </Form.Item>{' '}
                <Form.Item name={companyName.title} rules={companyName.rules}>
                  <Input
                    className="create-input"
                    placeholder={companyName.label}
                    type={companyName.type}
                  />
                </Form.Item>{' '}
                <Form.Item
                  name={projectDescription.title}
                  rules={projectDescription.rules}
                >
                  <Input.TextArea
                    className="create-input"
                    placeholder={projectDescription.label}
                    type={projectDescription.type}
                    rows={4}
                  />
                </Form.Item>
                <p className="status-alter-text">Alter project status below.</p>
                <Form.Item name="status">
                  <Select
                    // defaultValue={true}
                    style={{ width: 120 }}
                    options={[
                      {
                        value: true,
                        label: 'Active'
                      },
                      {
                        value: false,
                        label: 'Inactive  '
                      }
                    ]}
                  />
                </Form.Item>
              </div>
              <div className="form-side">
                <Form.Item name={streetInfo.title} rules={streetInfo.rules}>
                  <Input
                    className="create-input"
                    placeholder={streetInfo.label}
                    type={streetInfo.type}
                  />
                </Form.Item>
                <Form.Item name={subUrb.title} rules={subUrb.rules}>
                  <Input
                    className="create-input"
                    placeholder={subUrb.label}
                    type={subUrb.type}
                  />
                </Form.Item>
                <Form.Item name={city.title} rules={city.rules}>
                  <Input
                    className="create-input"
                    placeholder={city.label}
                    type={city.type}
                  />
                </Form.Item>
                <Form.Item name={province.title} rules={province.rules}>
                  <Input
                    className="create-input"
                    placeholder={province.label}
                    type={province.type}
                  />
                </Form.Item>
                <Form.Item name={country.title} rules={country.rules}>
                  <Input
                    className="create-input"
                    placeholder={country.label}
                    type={country.type}
                  />
                </Form.Item>{' '}
                <Form.Item name={cordinates.title} rules={cordinates.rules}>
                  <Input
                    className="create-input"
                    placeholder={cordinates.label}
                    type={cordinates.type}
                  />
                </Form.Item>
              </div>
            </div>
            <Button
              className="login-btn create-btn"
              size="large"
              htmlType="submit"
            >
              {isLoading ? (
                <BeatLoader
                  // Type={ClipLoader}
                  size={8}
                  color={'#fff'}
                />
              ) : (
                'Save Changes'
              )}
            </Button>
          </Form>
        </div>
      )}
    </section>
  );
};

export default EditProject;
