import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import Immutable from 'seamless-immutable';

const uploadReducer = createSlice({
  name: 'uploads',
  initialState: Immutable({
    bulkUploadFiles: [],
    bulkUploadAllFiles: [],
    nextStep: false,
    errorMessage: 'Please,Upload Folder',
    bulkFilesErrors: [],
    bulkFilesForUpload: [],
    isBulklUploading: false,
    stepCount: 0,
    uploadedFilesCount: 0
  }),

  reducers: {
    uploadRevisionFileRequest() {},
    bulkUploadVerifyRequest() {},
    bulkUploadVerifySuccess(state, action) {
      state.bulkUploadFiles = state.bulkUploadFiles.map((obj, index) => {
        const commingDrawingObj = action?.payload[index];
        let newOj = {
          ...obj,
          ...commingDrawingObj
        };
        return newOj;
      });
    },
    bulkUploadFilesReducer(state, action) {
      // debugger;
      state.bulkUploadAllFiles = action.payload.map((item) => {
        let newOj = {
          file: item
        };
        return newOj;
      });
      state.errorMessage = 'Please choose an owning circle before proceeding';
      let dwgFiles = [];
      let pdfFiles = [];
      action.payload.map((item) => {
        const fileName = item?.name?.split('.');
        if (fileName[fileName?.length - 1] == 'dwg') {
          let newObj = {
            file: item
          };

          dwgFiles.push(newObj);
        } else {
          let newOj = {
            file: item
          };
          pdfFiles.push(newOj);
        }
      });
      if (!_.isEmpty(dwgFiles)) {
        dwgFiles.map((itemDwg) => {
          console.log({ itemDwg });
          const dwgFileName = itemDwg?.file?.name
            ?.split('.')
            ?.slice(0, -1)
            ?.join('.');

          pdfFiles?.map((item, index) => {
            const pdfFileName = item?.file?.name
              ?.split('.')
              ?.slice(0, -1)
              ?.join('.');

            if (dwgFileName == pdfFileName) {
              pdfFiles[index].dwgFile = itemDwg;

              dwgFiles = dwgFiles.filter(function (el) {
                const dwgFileName2 = el?.file?.name
                  ?.split('.')
                  ?.slice(0, -1)
                  ?.join('.');

                return dwgFileName2 != pdfFileName;
              });
            }
          });
        });
      } else {
        state.bulkUploadFiles = pdfFiles;
      }
      state.bulkUploadFiles = [...pdfFiles, ...dwgFiles];
    },
    bulkUploadFilesCircle(state, action) {
      state.bulkUploadFiles = state.bulkUploadFiles.map((obj) => {
        let newOj = {
          ...obj,
          circle: action?.payload?.circle
        };
        return newOj;
      });
      state.bulkUploadAllFiles = state.bulkUploadAllFiles.map((obj) => {
        let newOj = {
          ...obj,
          circle: action?.payload?.circle
        };
        return newOj;
      });
      state.nextStep = true;
      state.errorMessage = 'Please,Select a Delimiter';
    },
    bulkUploadFilesDelimiter(state, action) {
      state.bulkUploadFiles = state.bulkUploadFiles.map((obj) => {
        let newOj = {
          ...obj,
          delimiter: action.payload?.delimiter
        };
        return newOj;
      });
      state.nextStep = true;
      state.errorMessage =
        'Please,Choose Drawing Number, Drawing Name, and revision Number  ';
    },
    bulkUploadDrawingsName(state, action) {
      state.bulkUploadFiles = state.bulkUploadFiles.map((obj, index) => {
        let newOj = {
          ...obj,
          dname: action.payload[index]
        };
        return newOj;
      });

      if (
        state.bulkUploadFiles.some((item) => !_.isEmpty(item.dname)) &&
        state.bulkUploadFiles.some((item) => !_.isEmpty(item.dnumber)) &&
        state.bulkUploadFiles.some((item) => !_.isEmpty(item.rnumber))
      ) {
        state.nextStep = true;
        state.errorMessage = ' ';
      }
    },
    bulkUploadDrawingsNumber(state, action) {
      state.bulkUploadFiles = state.bulkUploadFiles.map((obj, index) => {
        let newOj = {
          ...obj,
          dnumber: action.payload[index]
        };
        return newOj;
      });
      if (
        state.bulkUploadFiles.some((item) => !_.isEmpty(item.dname)) &&
        state.bulkUploadFiles.some((item) => !_.isEmpty(item.dnumber)) &&
        state.bulkUploadFiles.some((item) => !_.isEmpty(item.rnumber))
      ) {
        state.nextStep = true;
        state.errorMessage = '';
      }
    },
    bulkUploadDrawingsRevision(state, action) {
      state.bulkUploadFiles = state.bulkUploadFiles.map((obj, index) => {
        let newOj = {
          ...obj,
          rnumber: action.payload[index]
        };
        return newOj;
      });
      if (
        state.bulkUploadFiles.some((item) => !_.isEmpty(item.dname)) &&
        state.bulkUploadFiles.some((item) => !_.isEmpty(item.dnumber)) &&
        state.bulkUploadFiles.some((item) => !_.isEmpty(item.rnumber))
      ) {
        state.nextStep = true;
        state.errorMessage =
          'Please,Choose Drawing Number, Drawing Name, and revision Number  ';
      }
    },
    bulkUploadDrawingsStatus(state, action) {
      state.bulkUploadFiles = state.bulkUploadFiles.map((obj) => {
        let newOj = {
          ...obj,
          status: action.payload
        };
        return newOj;
      });
      state.bulkFilesForUpload = state.bulkFilesForUpload.map((obj) => {
        let newOj = {
          ...obj,
          status: action.payload
        };
        return newOj;
      });
    },
    bulkUploadDrawingsNotes(state, action) {
      state.bulkUploadFiles = state.bulkUploadFiles.map((obj) => {
        let newOj = {
          ...obj,
          notes: action.payload
        };
        return newOj;
      });
      state.bulkFilesForUpload = state.bulkFilesForUpload.map((obj) => {
        let newOj = {
          ...obj,
          notes: action.payload
        };
        return newOj;
      });
    },
    bulkUploadItemRemove(state, action) {
      state.bulkFilesForUpload = state.bulkFilesForUpload.filter(
        (item) => JSON.stringify(item) !== JSON.stringify(action.payload)
      );
    },
    bulkUploadSetStatusItem(state, action) {
      const { comingObj, menuItem } = action.payload;
      state.bulkFilesForUpload = state.bulkFilesForUpload.map((item) => {
        if (JSON.stringify(item) === JSON.stringify(comingObj)) {
          let obj = {
            ...item
          };
          obj.status = menuItem;
          return obj;
        }
        return item;
      });
    },
    shareCircleIndividualCheck(state, action) {
      const { circleItem, drawingItem } = action.payload || {};
      state.bulkFilesForUpload = state.bulkFilesForUpload.map((item) => {
        let followerCircleCheck = item.followerCircleCheck
          ? item.followerCircleCheck
          : {};
        let followercircle = item.followercircle ? item?.followercircle : [];
        if (
          item.dname == drawingItem.dname &&
          item.dnumber == drawingItem.dnumber &&
          item.rnumber == drawingItem.rnumber
        ) {
          Object.assign(followerCircleCheck, {
            [circleItem?._id]: {
              isCheck: item?.followerCircleCheck
                ? !item?.followerCircleCheck[circleItem?._id]?.isCheck
                : true
            }
          });
          if (!followerCircleCheck[circleItem?._id].isCheck) {
            followercircle = followercircle.filter((item) => {
              return item != circleItem?._id;
            });
          } else {
            followercircle.push(circleItem?._id);
          }

          return { ...item, followerCircleCheck, followercircle };
        }
        return item;
      });
    },
    shareCircleSelectColumn(state, action) {
      const { circleItem } = action.payload || {};
      state.bulkFilesForUpload = state.bulkFilesForUpload.map((item) => {
        let followerCircleCheck = item.followerCircleCheck
          ? item.followerCircleCheck
          : {};
        let followercircle = item.followercircle ? item?.followercircle : [];

        Object.assign(followerCircleCheck, {
          [circleItem?._id]: {
            isCheck: item?.followerCircleCheck
              ? !item?.followerCircleCheck[circleItem?._id]?.isCheck
              : true
          }
        });
        if (!followerCircleCheck[circleItem?._id].isCheck) {
          followercircle = followercircle.filter((item) => {
            return item != circleItem?._id;
          });
        } else {
          followercircle.push(circleItem?._id);
        }
        return { ...item, followerCircleCheck, followercircle };
      });
    },
    shareCircleSelectRow(state, action) {
      const { circles, drawingItem } = action.payload || {};
      // const selectCircleItem = _.cloneDeep(circleItem);
      state.bulkFilesForUpload = state.bulkFilesForUpload.map((item) => {
        let followerCircleCheck = item.followerCircleCheck
          ? item.followerCircleCheck
          : {};
        let followercircle = item.followercircle ? item?.followercircle : [];
        if (
          item.dname == drawingItem.dname &&
          item.dnumber == drawingItem.dnumber &&
          item.rnumber == drawingItem.rnumber
        ) {
          circles.map((circleitem) => {
            Object.assign(followerCircleCheck, {
              [circleitem?._id]: {
                isCheck: item?.followerCircleCheck
                  ? !item?.followerCircleCheck[circleitem?._id]?.isCheck
                  : true
              }
            });

            if (!followerCircleCheck[circleitem?._id].isCheck) {
              followercircle = followercircle.filter((item) => {
                return item != circleitem?._id;
              });
            } else {
              followercircle.push(circleitem?._id);
            }
          });

          return { ...item, followerCircleCheck, followercircle };
        }
        return item;
      });
    },
    bulkUploadNextStep(state, action) {
      state.nextStep = action.payload;
    },
    bulkUploadReducerEmpty(state) {
      state.nextStep = false;
      state.errorMessage = 'Please,Upload Folder';
      state.bulkUploadFiles = [];
      state.bulkUploadAllFiles = [];
      state.bulkFilesErrors = [];
      state.bulkFilesForUpload = [];
    },
    bulkUploadFilesError(state) {
      let tempArray = _.cloneDeep(state.bulkUploadFiles);
      let errorsArray = [];
      let filesArray = [];

      tempArray.map((item) => {
        if (item.sameNumberDifferentName) {
          if (!_.isUndefined(item.dwgFile)) {
            let temp = {
              ...item.dwgFile,
              errorMessage:
                'This drawing already exists on the system with a different drawing name.'
            };
            errorsArray.push(temp);
          }
          let newOj = {
            ...item,
            errorMessage:
              'This drawing already exists on the system with a different drawing name.'
          };
          errorsArray.push(newOj);
        } else {
          if (item.drawingInAnotherCircle) {
            if (!_.isUndefined(item.dwgFile)) {
              let temp = {
                ...item.dwgFile,
                errorMessage:
                  'This drawing already exists in another circle. Please create a new bulk upload process and select that circle as the owning circle.'
              };
              errorsArray.push(temp);
            }
            let newOj = {
              ...item,
              errorMessage:
                'This drawing already exists in another circle. Please create a new bulk upload process and select that circle as the owning circle.'
            };
            errorsArray.push(newOj);
          } else if (item.drawingExists) {
            let newOj = {};
            if (item.revisionNumberExists) {
              if (!_.isUndefined(item.dwgFile)) {
                let temp = {
                  ...item.dwgFile,
                  errorMessage:
                    'This drawing already exists on the system with the same drawing revision. Please create a new revision and re upload.'
                };
                errorsArray.push(temp);
              }

              newOj = {
                ...item,
                errorMessage:
                  'This drawing already exists on the system with the same drawing revision. Please create a new revision and re upload.'
              };
              errorsArray.push(newOj);
            } else if (_.isUndefined(item.rnumber)) {
              newOj = {
                ...item,
                errorMessage:
                  'This drawing does not have enough information. Every drawing needs to have a Drawing Number, Drawing Name and a Revision Number'
              };
              errorsArray.push(newOj);
            } else {
              filesArray.push(item);
            }
          } else {
            if (_.isUndefined(item.dname)) {
              let newOj = {
                ...item,
                errorMessage:
                  'This drawing does not have enough information. Every drawing needs to have a Drawing Number, Drawing Name and a Revision Number'
              };
              errorsArray.push(newOj);
            } else if (_.isUndefined(item.dnumber)) {
              let newOj = {
                ...item,
                errorMessage:
                  'This drawing does not have enough information. Every drawing needs to have a Drawing Number, Drawing Name and a Revision Number'
              };
              errorsArray.push(newOj);
            } else if (_.isUndefined(item.rnumber)) {
              let newOj = {
                ...item,
                errorMessage:
                  'This drawing does not have enough information. Every drawing needs to have a Drawing Number, Drawing Name and a Revision Number'
              };
              errorsArray.push(newOj);
            } else if (item?.file?.name?.split('.')[1] == 'dwg') {
              let newOj = {
                ...item,
                errorMessage:
                  'This drawing only has a dwg. A PDF version must be attacted too.'
              };
              errorsArray.push(newOj);
            } else {
              filesArray.push(item);
            }
          }
        }
      });

      // for duplicate  drawing  number start
      let duplicateDnumberArray = [];
      let duplicateDnumber = [];
      duplicateDnumber = filesArray
        .map((e) => e['dnumber'])
        .map((e, i, final) => final.indexOf(e) !== i && i)
        .filter((obj) => filesArray[obj])
        .map((e) => filesArray[e]['dnumber']);
      duplicateDnumberArray = filesArray.filter((obj) =>
        duplicateDnumber?.includes(obj?.dnumber)
      );

      duplicateDnumber.map((item) => {
        filesArray = filesArray.filter(function (el) {
          return el.dnumber != item;
        });
      });

      // for duplicate  drawing  number End
      // for duplicate  drawing  name start
      let duplicateDnameArray = [];
      let duplicateDname = [];
      duplicateDname = duplicateDnumberArray
        .map((e) => e['dname'])
        .map((e, i, final) => final.indexOf(e) !== i && i)
        .filter((obj) => duplicateDnumberArray[obj])
        .map((e) => duplicateDnumberArray[e]['dname']);
      duplicateDnameArray = duplicateDnumberArray.filter((obj) =>
        duplicateDname?.includes(obj?.dname)
      );

      duplicateDname.map((item) => {
        duplicateDnumberArray = duplicateDnumberArray.filter(function (el) {
          return el.dname != item;
        });
      });

      // for duplicate  drawing  name End
      duplicateDnameArray.map((item) => {
        if (!_.isUndefined(item.dwgFile)) {
          let temp = {
            ...item.dwgFile,
            errorMessage:
              'You have multiple files in your upload folder with same drw. number and different drw. revisions.'
          };
          errorsArray.push(temp);
        }
        let temp = {
          ...item,
          errorMessage:
            'You have multiple files in your upload folder with same drw. number and different drw. revisions.'
        };
        errorsArray.push(temp);
      });
      duplicateDnumberArray.map((item) => {
        if (!_.isUndefined(item.dwgFile)) {
          let temp = {
            ...item.dwgFile,
            errorMessage:
              'You have multiple files in your upload folder with same drw. number and different drw. revisions.'
          };
          errorsArray.push(temp);
        }

        let temp = {
          ...item,
          errorMessage:
            'You have multiple files in your upload folder with same drw. number and different drw. names.'
        };
        errorsArray.push(temp);
      });

      state.bulkFilesErrors = errorsArray;
      state.bulkFilesForUpload = filesArray;
    },
    bulkUploadingLoader(state, action) {
      state.isBulklUploading = action.payload;
      if (action.payload === false) {
        state.uploadedFilesCount = 0;
      }
    },
    bulkUploadStep(state, action) {
      state.stepCount = action.payload;
    },
    bulkUploadedFIlesCount(state, action) {
      state.uploadedFilesCount = action.payload;
    },
    shareCircleSelectAll(state, action) {
      const { circles } = action.payload || {};
      // const selectCircleItem = _.cloneDeep(circleItem);
      state.bulkFilesForUpload = state.bulkFilesForUpload.map((item) => {
        let followerCircleCheck = item.followerCircleCheck
          ? item.followerCircleCheck
          : {};
        let followercircle = item.followercircle ? item?.followercircle : [];

        circles.map((circleitem) => {
          Object.assign(followerCircleCheck, {
            [circleitem?._id]: {
              isCheck: item?.followerCircleCheck
                ? !item?.followerCircleCheck[circleitem?._id]?.isCheck
                : true
            }
          });

          if (!followerCircleCheck[circleitem?._id].isCheck) {
            followercircle = followercircle.filter((item) => {
              return item != circleitem?._id;
            });
          } else {
            followercircle.push(circleitem?._id);
          }
        });

        return { ...item, followerCircleCheck, followercircle };
      });
    }
  }
});

export const {
  uploadRevisionFileRequest,
  bulkUploadFilesReducer,
  bulkUploadFilesCircle,
  bulkUploadFilesDelimiter,
  bulkUploadDrawingsName,
  bulkUploadDrawingsNumber,
  bulkUploadDrawingsRevision,
  bulkUploadDrawingsStatus,
  bulkUploadDrawingsNotes,
  bulkUploadVerifyRequest,
  bulkUploadVerifySuccess,
  bulkUploadItemRemove,
  bulkUploadSetStatusItem,
  shareCircleIndividualCheck,
  shareCircleSelectColumn,
  shareCircleSelectRow,
  bulkUploadNextStep,
  bulkUploadReducerEmpty,
  bulkUploadFilesError,
  bulkUploadingLoader,
  bulkUploadStep,
  bulkUploadedFIlesCount,
  shareCircleSelectAll
} = uploadReducer.actions;

export default uploadReducer.reducer;
