import React from 'react';
import { Modal } from 'antd';
import './styles.scss';
import Loader from '../loader';
import { ClipLoader } from 'react-spinners';
import { Images } from '../../theme';
const ErrorModal = ({
  handleClose,
  onSubmit,
  title = '',

  loading = false
}) => {
  const handleSubmit = () => {
    onSubmit();
  };

  return (
    <Modal
      visible={true}
      centered
      onCancel={handleClose}
      footer={null}
      closeIcon={<img src={{}} alt="" />}
      width={600}
      className="certer-modal"
    >
      <div className="modal-wrapper-div">
        <div className="iconCrow-div">
          <img src={Images.errorModal} />
        </div>
        <div className="modal-title-div">
          <p className="modal-title">{title}</p>
        </div>

        <div
          type="button"
          className="continue-btn-center-modal"
          onClick={handleSubmit}
        >
          <p>{loading ? <Loader Type={ClipLoader} size={20} /> : 'Continue'}</p>
        </div>
      </div>
    </Modal>
  );
};

export default ErrorModal;
