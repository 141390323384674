import { Col, Row } from 'antd';
import moment from 'moment';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getRevisionsSeenActivityRequest } from '../../../redux/slicers/drawing';
import './styles.scss';

const RevisionActivity = () => {
  const dispatch = useDispatch();
  const params = useParams();

  const [revisionActivity, setrevisionActivity] = useState(null);

  useEffect(() => {
    dispatch(
      getRevisionsSeenActivityRequest({
        payloadData: {
          id: params?.pid,
          drawingId: params?.did,
          rnumber: params?.rid
        },
        responseCallback: (status, res) => {
          if (status && res.length) {
            setrevisionActivity(res);
          }
        }
      })
    );
    return () => {
      // setrevisionActivity(null);
    };
  }, []);

  return (
    <section className="dashboard">
      <Row style={{ width: '100%' }}>
        <Col
          lg={{ span: 24 }}
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
        >
          <span className="revision-activity-col1">
            <h6>Below is a list of users who have accessed</h6>
            {!revisionActivity?.length ? (
              <h5>{'No access logs'}</h5>
            ) : (
              <h5>
                {revisionActivity[0].drawingName} - Revision{' '}
                {revisionActivity[0]?.revisionNum}
              </h5>
            )}
          </span>
          <Row align="middle" justify="center">
            <Col
              lg={{ span: 12 }}
              md={{ span: 16 }}
              sm={{ span: 20 }}
              xs={{ span: 24 }}
            >
              <span className="revision-activity-col2">
                <h6 className="title">Access Logs</h6>
                <div>
                  {!revisionActivity?.length ? (
                    <h6>No one has accessed this file as yet</h6>
                  ) : (
                    revisionActivity?.map((item) => (
                      <span key={item?.email}>
                        <h6>{`[${moment(item?.datetime).format(
                          'DD/MM/YYYY HH:MM:ss'
                        )}]`}</h6>
                        <h6>
                          <strong> {item?.userFullName}</strong> {item?.email}
                        </h6>
                      </span>
                    ))
                  )}
                </div>
              </span>
            </Col>
          </Row>
        </Col>
      </Row>
    </section>
  );
};

export default RevisionActivity;
