import React from 'react';
import './styles.scss';
import logo from '../../assets/logo/Logo1.png';
import { Popover } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { userSignOutRequest } from '../../redux/slicers/user';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';

const Header = ({ children }) => {
  const data = useSelector((state) => state.user.data);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleSignout = () => {
    dispatch(userSignOutRequest());
    navigate('/');
  };
  const location = useLocation();
  const params = useParams();

  useEffect(() => {
    handleCreateBreadCrumbs();
  }, [location.pathname, params]);

  const handleCreateBreadCrumbs = () => {};

  return (
    <header className="main-header">
      <div className="headerWrapper">
        <div className="logo-title">
          <img src={logo} className="header-logo" />
          <div style={{ displsy: 'flex' }}>
            {/* <Link to="/dashboard">DASHBOARD</Link> */}
            {children}
          </div>
        </div>
        <Popover
          trigger="click"
          className="popover-options"
          placement="bottomRight"
          content={
            <div className="options-cont">
              <div className="options-item">
                <Link to={'/profile'} className="">
                  Profile
                </Link>
              </div>
              <div className="options-item" onClick={handleSignout}>
                <a className="">Logout</a>
              </div>
            </div>
          }
          // onConfirm={() => handleDelete(record.key)}
        >
          {/* <img src={ellipsis} alt="" className="ellipsis" /> */}
          <div className="profile-icon">
            <p>{data?.data?.fname[0]?.toUpperCase() || ''}</p>
          </div>
        </Popover>
      </div>
    </header>
  );
};

export default Header;
