import { Button, Form, Input } from 'antd';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { BeatLoader } from 'react-spinners';
import { ShareModal } from '../../../components';
import { ALERT_TYPES, RegisterFields } from '../../../constants';
import {
  getDrawingsRequest,
  shareDrawingRequest
} from '../../../redux/slicers/drawing';
import { getProjectRequest } from '../../../redux/slicers/projects';
import { toastAlert } from '../../../services/utils';
import './styles.scss';

const Share = () => {
  const data = useSelector((state) => state.user.data);
  const [project, setProject] = useState([]);
  const [isLoading, setLoading] = useState();
  const [isShareIndividual, setIsShareIndividual] = useState(() => false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [drawingData, setDrawingData] = useState([]);
  const { email } = RegisterFields.fields;
  const { pid, did } = useParams();

  useEffect(() => {
    dispatch(
      getDrawingsRequest({
        payloadData: pid,
        responseCallback: (status, res) => {
          if (status) {
            setDrawingData(res.filter((item) => item?._id === did));
          }
        }
      })
    );

    dispatch(
      getProjectRequest({
        payloadData: {},
        responseCallback: (status, res) => {
          if (status) {
            setProject(res.filter((item) => item?._id === pid));
          }
        }
      })
    );
  }, []);

  const handleShare = () => {
    try {
      setLoading(true);
      const values = form.getFieldsValue();

      const sortedRevisions = [...drawingData?.[0]?.revisions]?.sort((a, b) =>
        b.rdatetime > a.rdatetime ? 1 : -1
      );

      const payload = {
        company: data.data.cname,
        drawing: drawingData[0]?.dname,
        drawingid: drawingData[0]?._id,
        recipient: values.email,
        revision: sortedRevisions?.[0]?.rnumber,
        senderFullName: data.data.fname + ' ' + data.data.lname,
        versionid: sortedRevisions?.[0]?.rurl,
        project: project.filter((a) => a._id === pid)[0]?.projectname,
        projectid: project.filter((a) => a._id === pid)[0]?._id
      };

      dispatch(
        shareDrawingRequest({
          payloadData: payload,
          responseCallback: (status) => {
            if (status) {
              toastAlert(
                `You Shared This Drawing With ${values.email}`,
                ALERT_TYPES.success
              );
              setLoading(false);
              setIsShareIndividual(false);
              navigate(`/project/${pid}`);
              // handleProjectCircle(res);
            } else {
              setLoading(false);
              setIsShareIndividual(false);
            }
          }
        })
      );
    } catch (error) {
      console.log({ error });
    }
  };
  const handleSubmit = () => {
    setIsShareIndividual(true);
  };
  return (
    <section className="inviteScreen">
      {drawingData && (
        <div className="new-form share-cont">
          <h3>
            Share the latest revision of <b>{drawingData[0]?.dname}</b> with an
            individual
          </h3>
          <Form className="share-form" onFinish={handleSubmit} form={form}>
            <p>
              Enter the email address that you would like to send the selected
              revision to.
            </p>
            <Form.Item
              name={email.title}
              rules={email.rules}
              className="share-input"
            >
              <Input
                className="share-input"
                placeholder="Recipient Email"
                type={email.type}
              />
            </Form.Item>{' '}
            <Button className="share-btn" size="large" htmlType="submit">
              {isLoading ? <BeatLoader size={8} color={'#fff'} /> : 'Share'}
            </Button>
          </Form>
        </div>
      )}
      {isShareIndividual && (
        <ShareModal
          isShareIndividual={true}
          title={'Are you sure you want to share'}
          drawingName={`${drawingData[0]?.dnumber}_${drawingData[0]?.dname}_${
            drawingData[0]?.revisions[drawingData[0]?.revisions.length - 1]
              ?.rnumber
          }.pdf`}
          recipient={form.getFieldsValue().email}
          handleShare={() => handleShare()}
          handleClose={() => setIsShareIndividual(false)}
          isLoading={isLoading}
        />
      )}
    </section>
  );
};

export default Share;
