// @flow
import { createSlice } from '@reduxjs/toolkit';
import Immutable from 'seamless-immutable';

const ProjectsReducer = createSlice({
  name: 'projects',
  initialState: Immutable({
    archivedProjectList: null,
    projectList: null,
    projectCircle: null,

    circleOptions: null,
    circleNames: null,
    circleInvites: null
  }),
  reducers: {
    //EDIT PROJECT
    editProjectRequest() {},
    // SET PROJECT ALIAS
    createProjectAliasRequest() {},
    //PROJECT DETAILS
    getProjectViewDetailsRequest() {},
    //CIRCLE INVITES
    getCircleInvitesRequest() {},
    getCircleInvitesSuccess(state, action) {
      state.circleInvites = action.payload;
    },
    rejectCircleInviteRequest() {},
    rejectCircleInviteSuccess() {},
    acceptCircleInviteRequest() {},
    acceptCircleInviteSuccess() {},
    //ARCHIVE PROJECTS
    archiveProjectRequest() {},
    archiveProjectSuccess() {},
    //CREATE PROJECTS
    createProjectRequest() {},
    createProjectSuccess() {},
    //LEAVE PROJECTS
    leaveProjectRequest() {},
    leaveProjectSuccess() {},
    //GET PROJECTS
    getProjectRequest() {},
    //GET PROJECTS SUCCESS
    getProjectSuccess(state, action) {
      state.projectList = action.payload;
    },

    //GET ARCHIVED PROJECTS
    getArchivedProjectRequest() {},
    //GET ARCHIVED PROJECTS SUCCESS
    getArchivedProjectSuccess(state, action) {
      state.archivedProjectList = action.payload;
    },

    //get notification count req
    getNotificationCountRequest() {},

    getCommentNotificationCountRequest() {},

    //get notification count success
    getNotificationCountSuccess(state, action) {
      state.projectList = state.projectList.map((item) => {
        const ifNotif = action.payload?.find((dt) => dt?._id === item?._id);
        if (ifNotif) {
          return {
            ...item,
            Notification: ifNotif?.NotificationCount
          };
        } else {
          return {
            ...item,
            Notification: 0
          };
        }
      });
    },

    //GET CIRCLE OPTIONS
    getCircleOptionsRequest() {},

    //GET CIRCLE OPTIONS
    getCircleOptionsSuccess(state, action) {
      state.circleOptions = action.payload?.splice(1, action.payload.length);
    },

    //GET CIRCLE NAMES OPTIONS
    getCircleNameRequest() {},

    //GET CIRCLE NAMES OPTIONS
    getCircleNameSuccess(state, action) {
      state.circleNames = action.payload;
    },

    //GET CIRCLES NAMES OPTIONS
    getProjectCirclesRequest() {},

    getProjectCirclesSuccess(state, action) {
      state.projectCircle = action.payload;
    },

    //GET CIRCLES NAMES OPTIONS
    createProjectCircleRequest() {},

    //GET CIRCLES NAMES OPTIONS
    addRegisteredCircleMemberRequest() {},

    addUnRegisteredCircleMemberRequest() {},

    deleteCircleRequest() {},

    updateCircleNameRequest() {},

    deleteCircleMemberRequest() {},

    deleteNotificationRequest() {},

    createCircleName(state, action) {
      state.projectCircle = [
        ...state.projectCircle,
        action.payload.payloadData
      ];
    },
    rejectCircleRequest() {},
    acceptCircleRequest() {},
    deleteNotificationDrawinngRequest() {},
    bulkDownloadDrawingsRequest() {},
    bulkShareDrawingsRequest() {},
    clearAllNotificationsRequest() {},
    circleOrderRequest() {}
  }
});

export const {
  deleteNotificationRequest,
  getCommentNotificationCountRequest,
  deleteCircleMemberRequest,
  updateCircleNameRequest,
  deleteCircleRequest,
  addUnRegisteredCircleMemberRequest,
  addRegisteredCircleMemberRequest,
  getProjectCirclesSuccess,
  createProjectCircleRequest,
  getProjectCirclesRequest,
  getCircleNameRequest,
  getCircleNameSuccess,
  getCircleOptionsRequest,
  getCircleOptionsSuccess,
  getNotificationCountRequest,
  getNotificationCountSuccess,
  getProjectRequest,
  getProjectSuccess,
  createProjectRequest,
  createProjectSuccess,
  archiveProjectRequest,
  archiveProjectSuccess,
  getArchivedProjectRequest,
  getArchivedProjectSuccess,
  leaveProjectRequest,
  leaveProjectSuccess,
  getCircleInvitesRequest,
  getCircleInvitesSuccess,
  rejectCircleInviteRequest,
  rejectCircleInviteSuccess,
  acceptCircleInviteRequest,
  acceptCircleInviteSuccess,
  getProjectViewDetailsRequest,
  createProjectAliasRequest,
  editProjectRequest,
  createCircleName,
  rejectCircleRequest,
  acceptCircleRequest,
  deleteNotificationDrawinngRequest,
  bulkDownloadDrawingsRequest,
  bulkShareDrawingsRequest,
  clearAllNotificationsRequest,
  circleOrderRequest
} = ProjectsReducer.actions;

export default ProjectsReducer.reducer;
