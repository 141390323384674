// @flow
import { createSlice } from '@reduxjs/toolkit';
import Immutable from 'seamless-immutable';

const ProjectsDrawingsReducer = createSlice({
  name: 'drawing',
  initialState: Immutable({
    projectDrawings: [],
    notificationsCount: [],
    CommentNotifications: []
  }),
  reducers: {
    //CANCEL REVISION
    cancelRevisionRequest() {},
    //SHARE DRAWING
    shareDrawingRequest() {},
    //ACTIVITY
    getDrawingsActivityRequest() {},
    getDrawingsSeenActivityRequest() {},
    getCirclesActivityRequest() {},
    //REVISIONS
    getDrawingsRevisionRequest() {},
    //ACCESS HISTORY
    getRevisionAccessHistoryRequest() {},
    //CREATE drawing
    createDrawingRequest() {},

    getDrawingsRequest() {},

    getDrawingsDetailRequest() {},

    getDrawingsSuccess(state, action) {
      state.projectDrawings = action.payload;
    },

    addRevisionRequest() {},
    addRevisionPerfLogRequest() {},
    updateDrawingPermissionRequest() {},

    getRevisionsSeenActivityRequest() {},
    downloadDrawingRequest() {},

    deleteDrawingRequest() {},

    sendDrawingAcceessReq() {},

    getNotificationsRequest() {},
    getNotificationsSuccess(state, action) {
      state.notificationsCount = action.payload;
    },

    getCommentsNotificationsRequest() {},

    updateDrawingRequest() {},
    downnloadDrawingsRegisterRequest() {},

    getUploadUrlsRequest() {},
    clearAllNotificationsDrawings(state) {
      state.notificationsCount = [];
      state.CommentNotifications = [];
    },
    removeNotificationByDrawingsIds(state, action) {
      state.notificationsCount = state?.notificationsCount?.map((item) => {
        if (action?.payload?.includes(item?._id)) {
          item.notification = 0;
        }
      });
    },
    setCommentNotification(state, action) {
      state.CommentNotifications = action.payload;
    },
    getDrawingUnregisterUserRequest() {},
    uploadDWGRevisionRequest() {}
  }
});

export const {
  updateDrawingRequest,
  getCommentsNotificationsRequest,
  getNotificationsRequest,
  getRevisionsSeenActivityRequest,
  updateDrawingPermissionRequest,
  addRevisionRequest,
  addRevisionPerfLogRequest,
  getDrawingsDetailRequest,
  getDrawingsSuccess,
  getDrawingsRequest,
  createDrawingRequest,
  getDrawingsRevisionRequest,
  getRevisionAccessHistoryRequest,
  getCirclesActivityRequest,
  getDrawingsActivityRequest,
  getDrawingsSeenActivityRequest,
  shareDrawingRequest,
  cancelRevisionRequest,
  downloadDrawingRequest,
  deleteDrawingRequest,
  sendDrawingAcceessReq,
  getUploadUrlsRequest,
  getNotificationsSuccess,
  clearAllNotificationsDrawings,
  removeNotificationByDrawingsIds,
  downnloadDrawingsRegisterRequest,
  setCommentNotification,
  getDrawingUnregisterUserRequest,
  uploadDWGRevisionRequest
} = ProjectsDrawingsReducer.actions;

export default ProjectsDrawingsReducer.reducer;
