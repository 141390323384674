import axios from 'axios';
import DataHandler from './DataHandler';
import {
  ERROR_API_NOT_FOUND,
  ERROR_ACCOUNT_BLOCKED,
  ERROR_SOMETHING_WENT_WRONG,
  ERROR_NETWORK_NOT_AVAILABLE
} from '../config/webService';
import { ALERT_TYPES, API_TIMEOUT } from '../constants';
import { userSignOutSuccess, setAuthError } from '../redux/slicers/user';
import { toastAlert } from './utils';

const onForbidden = async () => {
  DataHandler.getStore().dispatch(setAuthError(ERROR_ACCOUNT_BLOCKED));
  DataHandler.getStore().dispatch(userSignOutSuccess());
  return false;
};

const manipulateResponse = (response) => {
  return new Promise((resolve, reject) => {
    if (response.data && !response.data.error) {
      resolve(response.data);
    } else if (response.status === 204) {
      resolve({ status: true, data: response.data });
    } else {
      reject(response.data || ERROR_SOMETHING_WENT_WRONG);
    }
  });
};
const ApiHandler = async (request, url, data, headers, baseUrl, raw) => {
  try {
    const response = await axios({
      baseURL: baseUrl || process.env.REACT_APP_BACKEND_ENV,
      timeout: API_TIMEOUT,
      method: request,
      url: url,
      data: data,
      headers: headers,
      responseType: raw ? 'blob' : 'json'
    });

    if (raw) {
      return response;
    } else {
      return manipulateResponse(response);
    }
  } catch ({ response }) {
    if (response.status === 404) {
      const baseUrl = window.location.origin;
      window.location.replace(`${baseUrl}/Error`);
      toastAlert(ERROR_API_NOT_FOUND, ALERT_TYPES.error);
      return { status: false };
    }
    if (response.status === 403) {
      // userBlocked(response);
      const baseUrl = window.location.origin;

      window.location.replace(`${baseUrl}/Error`);
      return { status: false };
    }
    if (response.status === 401) {
      const baseUrl = window.location.origin;

      window.location.replace(`${baseUrl}/Error`);
      try {
        onForbidden();
        return { status: false };
      } catch (err) {
        console.error({ err });
      }
    }
    if (response.status === 500) {
      toastAlert(ERROR_SOMETHING_WENT_WRONG, ALERT_TYPES.error);
      return { status: false, message: ERROR_SOMETHING_WENT_WRONG };
    }
    if (response.problem === 'NETWORK_ERROR') {
      toastAlert(ERROR_NETWORK_NOT_AVAILABLE, ALERT_TYPES.error);
      return { status: false, message: ERROR_NETWORK_NOT_AVAILABLE };
    } else {
      if (typeof response.data.message == 'object') {
        return { status: false, errors: response.data.message };
      }
      return { status: false, message: response.data.message };
    }
  }
};

export default ApiHandler;
