import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DashboardContent, DashboardSidebar } from '../../../components';
import {
  getCircleInvitesRequest,
  getCommentNotificationCountRequest,
  getNotificationCountRequest,
  getProjectRequest
} from '../../../redux/slicers/projects';
import './styles.scss';
const Dashboard = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { projects } = state;
  const { projectList } = projects;
  const [userInvitesList, setUserInvitesList] = useState([]);
  const [commentNotification, setcommentNotification] = useState([]);
  const [notifications, setnotification] = useState([]);

  const handleCommentsNotifs = (val) => {
    setcommentNotification(val);
  };

  const fetchProjects = () => {
    dispatch(
      getProjectRequest({
        payloadData: {},
        responseCallback: () => {
          setLoading(false);
        }
      })
    );
  };

  useEffect(() => {
    setLoading(true);
    fetchProjects();
    dispatch(
      getCommentNotificationCountRequest({
        payloadData: null,
        responseCallback: (status, res) => {
          if (status) {
            handleCommentsNotifs(res);
          }
        }
      })
    );
    dispatch(
      getNotificationCountRequest({
        payloadData: null,
        responseCallback: (status, res) => {
          if (status) {
            setnotification(res);
          }
        }
      })
    );

    dispatch(
      getCircleInvitesRequest({
        payloadData: null,
        responseCallback: (status, res) => {
          setUserInvitesList(res);
        }
      })
    );
  }, []);

  const filterOutInvitees = (id) => {
    const filteredInvitees = [...userInvitesList].filter(
      (invitee) => invitee?._id !== id
    );

    setUserInvitesList([...filteredInvitees]);
  };

  const userProjects =
    projectList && projectList.length > 0
      ? projectList?.map((item) => {
          const findNotif = commentNotification?.find(
            (dt) => dt?._id === item?._id
          );
          const projNotif = notifications?.find((dt) => dt?._id === item?._id);

          if (findNotif || projNotif) {
            let totalnotifs =
              findNotif && projNotif
                ? findNotif?.NotificationCount + projNotif?.NotificationCount
                : !findNotif && projNotif
                ? projNotif?.NotificationCount
                : findNotif && !projNotif
                ? findNotif?.NotificationCount
                : 0;
            return {
              ...item,
              status: item?.status ? 'Active' : 'Inactive',
              Notification: totalnotifs,
              creationDatetime: item?.creationDatetime
                ? moment(item?.creationDatetime, 'YYYY-MM-DD').format(
                    'YYYY-MM-DD'
                  )
                : 'Never Updated'
            };
          } else {
            return {
              ...item,
              status: item?.status ? 'Active' : 'Inactive',
              creationDatetime: item?.creationDatetime
                ? moment(item?.creationDatetime, 'YYYY-MM-DD').format(
                    'YYYY-MM-DD'
                  )
                : 'Never Updated'
            };
          }
        })
      : [];

  return (
    <section className="dashboard">
      <DashboardSidebar
        invitesList={userInvitesList || []}
        filterOutInvitees={filterOutInvitees}
        fetchProjects={fetchProjects}
      />
      <DashboardContent
        projectList={userProjects || []}
        loading={loading}
        invitesList={userInvitesList || []}
        initialRequest={fetchProjects}
        filterOutInvitees={filterOutInvitees}
      />
    </section>
  );
};

export default Dashboard;
