import ErrorBackground2 from '../assets/backgrounds/404_bg2.png';
import Bell from '../assets/icons/Bell.png';
import Elipse from '../assets/icons/ellipsis.png';
import Tool from '../assets/icons/tools.png';
import Delete from '../assets/icons/delete.png';
import Tick from '../assets/icons/tick.png';
import Lock from '../assets/icons/lock.png';
import uploadDWG from '../assets/logo/uploadDWG.png';
import uploadPDF from '../assets/logo/uploadPDF.png';
import downloadIcon from '../assets/icons/downloadIcon/downloadIcon.png';
import uploadIcon from '../assets/icons/uploadIcon.png';
import DropdownIcon from '../assets/icons/DropdownIcon.png';
import rightTick from '../assets/icons/rightTick.png';
import CrossIcon from '../assets/icons/crossicon.svg';
import ArrowDown from '../assets/icons/arrowdown.png';
import downArrow from '../assets/icons/downArrow.png';
import upArrow from '../assets/icons/upArrow.png';
import UpdateDrawing from '../assets/icons/UpdateDrawing.png';
import Iconcrown from '../assets/icons/Iconcrown.png';
import crossIcon from '../assets/icons/crossIcon1.png';
import errorModal from '../assets/icons/errorModal.png';
import questionIcon from '../assets/icons/questionIcon.png';
import sortListing from '../assets/icons/sortListing.png';
import bulkUploadIcon from '../assets/icons/bulkUploadIcon.png';
import checkIcon from '../assets/icons/checkIcon.png';
import csvIcon from '../assets/icons/csvIcon.png';
import crossIconRed from '../assets/icons/crossIconRed.png';
import comments from '../assets/icons/comment.png';
import tick2 from '../assets/icons/tick-2.png';
import disCheckbox from '../assets/icons/disCheckbox.png';
import model from '../assets/icons/model.svg';
import modelGroup from '../assets/icons/modelGroup.svg';
import dropDown from '../assets/icons/dropDown.png';

const folder = require('../assets/icons/folder.png').default;

import Info from '../assets/icons/info.svg';
export default {
  dropDown,
  ArrowDown,
  CrossIcon,
  Info,
  Tick,
  Delete,
  Tool,
  Elipse,
  Bell,
  ErrorBackground2,
  folder,
  Lock,
  uploadDWG,
  uploadPDF,
  downloadIcon,
  uploadIcon,
  DropdownIcon,
  rightTick,
  downArrow,
  upArrow,
  UpdateDrawing,
  Iconcrown,
  crossIcon,
  errorModal,
  questionIcon,
  sortListing,
  bulkUploadIcon,
  checkIcon,
  crossIconRed,
  csvIcon,
  comments,
  tick2,
  disCheckbox,
  model,
  modelGroup
};
