import React, { useState, useEffect } from 'react';
import './styles.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Form, Input, Row, Select, Tooltip } from 'antd';
import { Images } from '../../theme';
import ConfirmationModal from '../confirmationModal';
import { useDispatch, useSelector } from 'react-redux';
import {
  circleOrderRequest,
  createProjectCircleRequest,
  deleteCircleRequest,
  getProjectCirclesRequest,
  updateCircleNameRequest
} from '../../redux/slicers/projects';
import { toastAlert } from '../../services/utils';
import { ALERT_TYPES } from '../../constants';
import { useRef } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import _ from 'lodash';
function CircleSidebar({
  handleGetCicleMembers,
  projectCircle,
  isUserAdmin,
  SelectedCircle
}) {
  const dispatch = useDispatch();
  const params = useParams();
  const [form] = Form.useForm();
  const refCircleName = useRef(null);
  const navigate = useNavigate();
  const state = useSelector((state) => state);
  const [selectedCircle, setselectedCircle] = useState(null);
  const [open, setOpen] = useState(false);
  const [UpdateOpen, setUpdateOpen] = useState(false);
  const [updatedName, setUpdatedName] = useState('');
  const [loader, setLoader] = useState(false);
  const [currentIndexDelete, setCurrentIndexDelete] = useState(0);
  const handleUpdateName = (e) => {
    setUpdatedName(e);
  };
  const { user } = state;
  const { profile } = user;

  const [projectCircleById, setProjectCircleById] = useState([]);

  useEffect(() => {
    setProjectCircleById(projectCircle);
  }, [projectCircle]);
  useEffect(() => {
    dispatch(
      getProjectCirclesRequest({
        payloadData: { projectid: params?.id },
        responseCallback: (status, res) => {
          if (status && res.length) {
            handleSelectedCircle(res[0]._id, '');
            handleGetCicleMembers(res[0]._id, res);
          } else {
            navigate(`/dashboard/${params?.id}/circle`);
          }
        }
      })
    );
  }, []);

  const handleLoader = (bool) => {
    setLoader(bool);
  };
  const handleDeleteCircle = () => {
    handleLoader(true);
    dispatch(
      deleteCircleRequest({
        payloadData: { circleid: selectedCircle?.value },
        responseCallback: (status, res) => {
          if (status && res) {
            toastAlert(res.msg, ALERT_TYPES.success);
            setselectedCircle({
              value: projectCircle[currentIndexDelete - 1]?._id,
              action: ''
            });
            SelectedCircle({ _id: res?._id });
            dispatch(
              getProjectCirclesRequest({
                payloadData: { projectid: params?.id },
                responseCallback: () => {
                  handleLoader(false);
                  handleCloseConfirm();
                }
              })
            );
          } else {
            handleLoader(false);
            toastAlert(res.msg, ALERT_TYPES.error);
          }
        }
      })
    );
  };

  const handleSelectedCircle = (val, action) => {
    if (action === 'edit') {
      setselectedCircle({ value: val, action });
    } else if (action === 'delete') {
      setselectedCircle({ value: val, action });
      handleCloseConfirm();
    } else {
      handleGetCicleMembers(val, projectCircle);
      setselectedCircle({ value: val, action: '' });
    }
  };
  const handleCloseConfirm = () => {
    setOpen(!open);
  };

  const handleCloseUpdateConfirm = () => {
    setUpdateOpen(!UpdateOpen);
  };
  const handleSubmit = () => {
    const values = form.getFieldsValue();
    const createCircle = {
      ...values,
      projectid: params?.id
    };
    if (
      projectCircle?.length > 0 &&
      projectCircle?.find((dt) => dt?.circlename === values?.circlename?.trim())
    ) {
      toastAlert('This circle name already exist!', ALERT_TYPES.error);
    } else {
      dispatch(
        createProjectCircleRequest({
          payloadData: createCircle,
          responseCallback: (status, res) => {
            if (status && res) {
              toastAlert(res.msg, ALERT_TYPES.success);
              form.resetFields();

              refCircleName.current.scrollIntoView();

              SelectedCircle({ _id: res._id });
              //  handleSelectedCircle(res._id, '');
              setselectedCircle({ value: res._id, action: '' });
              // handleGetCicleMembers(res._id, projectCircle);
            } else {
              toastAlert(res.msg, ALERT_TYPES.error);
            }
          }
        })
      );
    }
  };

  const handleConfirmUpdate = () => {
    const selectCircle =
      projectCircle?.length > 0 &&
      projectCircle?.find(({ _id }) => _id === selectedCircle?.value);

    const UpdateCircle = {
      ...selectCircle,
      newName: updatedName,
      renamerUserId: profile?.user?._id
    };

    handleLoader(true);

    dispatch(
      updateCircleNameRequest({
        payloadData: UpdateCircle,

        responseCallback: (status, res) => {
          if (status && res) {
            toastAlert(res.msg, ALERT_TYPES.success);
            dispatch(
              getProjectCirclesRequest({
                payloadData: { projectid: params?.id },
                responseCallback: () => {
                  handleLoader(false);
                  handleCloseUpdateConfirm();
                }
              })
            );
          } else {
            toastAlert(res.msg, ALERT_TYPES.error);
          }
        }
      })
    );
  };

  const projectCircleForSelect =
    projectCircle?.length > 0 &&
    projectCircle?.map((item) => {
      return {
        value: item?._id,
        label: item?.circlename
      };
    });

  const currentCircle =
    projectCircle?.length > 0 &&
    projectCircle?.find(
      (dt) =>
        dt?.circlename !== 'Administrators' && selectedCircle?.value === dt?._id
    );

  const handleRecipeStepDrop = (droppedItem) => {
    if (!droppedItem.destination) return;
    let updatedList = _.cloneDeep(projectCircleById);
    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
    updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
    setProjectCircleById(updatedList);
    const obj = {};
    updatedList.map((item, index) => {
      obj[item._id] = index;
    });
    dispatch(
      circleOrderRequest({
        payloadData: {
          projectid: params?.id,
          circles: obj
        },
        responseCallback: () => {}
      })
    );
  };

  return (
    <>
      <div className="sidebar-cont">
        <h5>Circles </h5>
        <hr style={{ height: '1px' }} />
        <div className="sidebar-circle-wrapper">
          <div className="sidebar-circle-inner-wrapper">
            <DragDropContext onDragEnd={handleRecipeStepDrop}>
              <Droppable droppableId="droppable">
                {(provided) => (
                  <div ref={provided.innerRef}>
                    {projectCircleById?.map((item, index) => {
                      const isAdmin = item?.circlename !== 'Administrators';
                      return (
                        <>
                          {isAdmin && isUserAdmin ? (
                            <Draggable
                              key={item}
                              draggableId={`dt${index + 1}`}
                              index={index}
                            >
                              {(provided) => (
                                <div
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  // ref={
                                  //   index === projectCircle?.length - 1 ? refCircleName : null
                                  // }
                                  ref={provided.innerRef}
                                  key={item?._id}
                                  className={
                                    selectedCircle?.value === item._id
                                      ? 'selected-sidebar-options'
                                      : 'sidebar-options'
                                  }
                                >
                                  {isUserAdmin &&
                                  selectedCircle?.action === 'edit' &&
                                  selectedCircle?.value === item?._id ? (
                                    <span className="circle-edit-wrapper">
                                      <Input
                                        className="edit-input"
                                        size="middle"
                                        onChange={(e) => {
                                          handleUpdateName(e.target.value);
                                        }}
                                      />

                                      <Button
                                        // htmlType="submit"
                                        onClick={handleCloseUpdateConfirm}
                                        style={{
                                          backgroundColor: 'transparent',
                                          border: 0
                                        }}
                                        icon={
                                          <img src={Images.Tick} width={25} />
                                        }
                                      />
                                    </span>
                                  ) : (
                                    <>
                                      <div className="circle-name-div">
                                        <Tooltip
                                          title={
                                            item?.circlename.length > 10
                                              ? item?.circlename
                                              : ''
                                          }
                                          placement="bottom"
                                        >
                                          <p
                                            className="circle-name"
                                            onClick={() =>
                                              handleSelectedCircle(item._id, '')
                                            }
                                          >
                                            {item.circlename}
                                          </p>
                                        </Tooltip>
                                      </div>

                                      {item?.circlename !== 'Administrators' &&
                                        isUserAdmin && (
                                          <span className={'icon-wrapper'}>
                                            <img
                                              src={Images.Tool}
                                              width={25}
                                              style={{ marginRight: 10 }}
                                              onClick={() =>
                                                handleSelectedCircle(
                                                  item._id,
                                                  'edit'
                                                )
                                              }
                                            />
                                            <img
                                              src={Images.Delete}
                                              width={25}
                                              style={{ marginRight: 10 }}
                                              onClick={() => {
                                                handleSelectedCircle(
                                                  item._id,
                                                  'delete'
                                                );
                                                setCurrentIndexDelete(index);
                                              }}
                                            />
                                            <img
                                              src={Images.dropDown}
                                              width={25}
                                              style={{ marginRight: 10 }}
                                            />
                                          </span>
                                        )}
                                    </>
                                  )}
                                </div>
                              )}
                            </Draggable>
                          ) : (
                            <div
                              ref={
                                index === projectCircle?.length - 1
                                  ? refCircleName
                                  : null
                              }
                              key={item?._id}
                              className={
                                selectedCircle?.value === item._id
                                  ? 'selected-sidebar-options'
                                  : 'sidebar-options'
                              }
                            >
                              {isUserAdmin &&
                              selectedCircle?.action === 'edit' &&
                              selectedCircle?.value === item?._id ? (
                                <span className="circle-edit-wrapper">
                                  <Input
                                    className="edit-input"
                                    size="middle"
                                    onChange={(e) => {
                                      handleUpdateName(e.target.value);
                                    }}
                                  />

                                  <Button
                                    // htmlType="submit"
                                    onClick={handleCloseUpdateConfirm}
                                    style={{
                                      backgroundColor: 'transparent',
                                      border: 0
                                    }}
                                    icon={<img src={Images.Tick} width={25} />}
                                  />
                                </span>
                              ) : (
                                <>
                                  <div className="circle-name-div">
                                    <Tooltip
                                      title={
                                        item?.circlename.length > 10
                                          ? item?.circlename
                                          : ''
                                      }
                                      placement="bottom"
                                    >
                                      <p
                                        className="circle-name"
                                        onClick={() =>
                                          handleSelectedCircle(item._id, '')
                                        }
                                      >
                                        {item.circlename}
                                      </p>
                                    </Tooltip>
                                  </div>

                                  {item?.circlename !== 'Administrators' &&
                                    isUserAdmin && (
                                      <span className={'icon-wrapper'}>
                                        <img
                                          src={Images.Tool}
                                          width={25}
                                          style={{ marginRight: 10 }}
                                          onClick={() =>
                                            handleSelectedCircle(
                                              item._id,
                                              'edit'
                                            )
                                          }
                                        />
                                        <img
                                          src={Images.Delete}
                                          width={25}
                                          style={{ marginRight: 10 }}
                                          onClick={() => {
                                            handleSelectedCircle(
                                              item._id,
                                              'delete'
                                            );
                                            setCurrentIndexDelete(index);
                                          }}
                                        />
                                      </span>
                                    )}
                                </>
                              )}
                            </div>
                          )}
                        </>
                      );
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
          <div className="sidebar-circle-inner-wrapper-mob">
            <div className="circle-select-wrapper">
              {currentCircle?._id === selectedCircle?.value &&
              selectedCircle?.action === 'edit' &&
              isUserAdmin ? (
                <span className="circle-edit-wrapper">
                  <Input
                    className="edit-input"
                    size="middle"
                    onChange={(e) => {
                      handleUpdateName(e.target.value);
                      //
                    }}
                  />

                  <Button
                    // htmlType="submit"
                    onClick={handleCloseUpdateConfirm}
                    style={{ backgroundColor: 'transparent', border: 0 }}
                    icon={<img src={Images.Tick} width={25} />}
                  />
                </span>
              ) : (
                <Select
                  className="select-search"
                  showSearch
                  placeholder="Select a Circle"
                  optionFilterProp="children"
                  onChange={(id) => {
                    handleSelectedCircle(id, '');
                  }}
                  filterOption={(input, option) =>
                    (option?.label ?? '')
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={projectCircleForSelect}
                />
              )}

              {isUserAdmin &&
                currentCircle &&
                selectedCircle?.action === '' && (
                  <span className={'icon-wrapper'}>
                    <img
                      src={Images.Tool}
                      width={25}
                      onClick={() =>
                        handleSelectedCircle(selectedCircle?.value, 'edit')
                      }
                    />
                    <img
                      src={Images.Delete}
                      width={25}
                      onClick={() =>
                        handleSelectedCircle(selectedCircle?.value, 'delete')
                      }
                    />
                  </span>
                )}
            </div>
          </div>

          {isUserAdmin && (
            <div className="add-circle-wrapper">
              <Row justify={'center'}>
                <Form form={form} onFinish={handleSubmit}>
                  <Form.Item
                    name={'circlename'}
                    rules={[
                      {
                        required: true,
                        message: 'Circle Name is required'
                      }
                    ]}
                  >
                    <Col span={24}>
                      <Input size="large" className="add-circle-input" />
                    </Col>
                  </Form.Item>
                  <Col span={24}>
                    <Button
                      htmlType="submit"
                      size="large"
                      className="add-circle-btn"
                    >
                      Add Circle
                    </Button>
                  </Col>
                </Form>
              </Row>
            </div>
          )}
        </div>
      </div>
      {open && (
        <ConfirmationModal
          loading={loader}
          title="Comfirmation!"
          onSubmit={handleDeleteCircle}
          description="Are you sure you want to Delete this circle name?"
          handleClose={handleCloseConfirm}
        />
      )}
      {UpdateOpen && (
        <ConfirmationModal
          loading={loader}
          title="Comfirmation!"
          onSubmit={handleConfirmUpdate}
          description="Are you sure you want to Update this circle?"
          handleClose={handleCloseUpdateConfirm}
        />
      )}
    </>
  );
}

export default CircleSidebar;
