import moment from 'moment';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { DetailsContainer, DetailsSidebar } from '../../../components';
import {
  getCommentsNotificationsRequest,
  getDrawingsRequest,
  getDrawingsSuccess,
  getNotificationsRequest
} from '../../../redux/slicers/drawing';
import {
  getCircleNameRequest,
  getCircleOptionsRequest
} from '../../../redux/slicers/projects';
import './styles.scss';
const ProjectDetails = () => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const [loading, setLoading] = useState(true);

  const { drawing } = state;
  const { notificationsCount, CommentNotifications } = drawing;
  const [commentNotifs, setcommentNotifs] = useState(
    () => CommentNotifications
  );
  const [notifications, setnotifications] = useState(() => notificationsCount);

  useEffect(() => {
    if (params?.id) {
      dispatch(
        getDrawingsRequest({
          payloadData: params?.id,
          responseCallback: (status) => {
            if (status) {
              setLoading(false);
            } else {
              setLoading(false);
              navigate('/pageNotFound', { replace: true });
            }
          }
        })
      );
      dispatch(
        getCircleNameRequest({
          payloadData: { projectid: params?.id }
        })
      );

      dispatch(
        getCircleOptionsRequest({
          payloadData: { projectid: params?.id }
        })
      );
      dispatch(
        getNotificationsRequest({
          payloadData: { id: params?.id },
          responseCallback: (status, res) => {
            if (status && res.length) {
              setnotifications(res);
            }
          }
        })
      );
      dispatch(
        getCommentsNotificationsRequest({
          payloadData: { id: params?.id },
          responseCallback: (status, res) => {
            if (status && res.length) {
              setcommentNotifs(res);
            }
          }
        })
      );
    }

    return () => dispatch(getDrawingsSuccess(null));
  }, []);

  useEffect(() => {
    setnotifications(notificationsCount);
    setcommentNotifs(CommentNotifications);
  }, [notificationsCount, CommentNotifications]);
  const handleRemoveNotifications = (drawing) => {
    const allNotifications = [...notifications]?.filter(
      (n) => n?._id !== drawing?._id
    );
    setnotifications([...allNotifications]);
  };

  const allDrawings =
    drawing?.projectDrawings?.length > 0
      ? drawing?.projectDrawings?.map((item) => {
          let getCommentNotif = commentNotifs?.find(
            (dt) => dt?._id === item?._id
          );
          let getNotif = notifications?.find((dt) => dt?._id === item?._id);
          let obj = {
            ...item,
            notifications: getNotif ? getNotif?.NotificationCount : 0,
            commentNotifications: getCommentNotif
              ? getCommentNotif?.CommentNotifCount
              : 0,
            revisionBy: item?.revisions?.length
              ? item?.revisions[item?.revisions?.length - 1]?.rnumber
              : 'None',
            rurlDwg: item?.revisions?.length
              ? item?.revisions[item?.revisions?.length - 1]?.rurl_dwg
              : null,
            lastUpdate: null,
            status: item?.revisions?.length
              ? item?.revisions[item?.revisions?.length - 1]?.status
              : '',
            notes: item?.revisions?.length
              ? item?.revisions[item?.revisions?.length - 1]?.notes
              : ''
          };
          if (item?.revisions?.length) {
            let arr = JSON.parse(JSON.stringify(item?.revisions));
            let revtime = arr?.sort(
              (a, b) => new Date(b.rdatetime) - new Date(a.rdatetime)
            );

            obj = {
              ...obj,
              lastUpdate: moment(revtime[0]?.rdatetime, 'YYYY-MM-DD').format(
                'YYYY-MM-DD'
              )
            };
          } else {
            obj = {
              ...obj,
              lastUpdate: moment(item?.creationDatetime, 'YYYY-MM-DD').format(
                'YYYY-MM-DD'
              )
            };
          }
          return {
            ...obj
          };
        })
      : [];

  return (
    <section className="details-drawing ">
      <DetailsSidebar />
      <DetailsContainer
        allDrawings={allDrawings || []}
        handleRemoveNotifications={handleRemoveNotifications}
        loading={loading}
      />
    </section>
  );
};

export default ProjectDetails;
