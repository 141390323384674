import { Checkbox } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { CircleContent, CircleSidebar } from '../../../components';
import { ADMINISTRATORS } from '../../../constants';
import {
  getProjectCirclesRequest,
  getProjectCirclesSuccess
} from '../../../redux/slicers/projects';
import './styles.scss';

const Circles = () => {
  const dispatch = useDispatch();
  const params = useParams();

  const state = useSelector((state) => state);
  const { projects, user } = state;
  const { projectList, projectCircle } = projects;
  const [selectedCircle, setSelectedCircle] = useState(null);
  const [isUserAdmin, setUserAdmin] = useState(() => false);

  const userId = user?.data?.data?.id ?? '';

  useEffect(() => {
    dispatch(
      getProjectCirclesRequest({
        payloadData: { projectid: params?.id }
      })
    );
    return () => dispatch(getProjectCirclesSuccess([]));
  }, []);

  useEffect(() => {
    try {
      if (!_.isEmpty(projectCircle)) {
        const adminCircle = projectCircle?.find(
          (circle) => circle.circlename === ADMINISTRATORS
        );
        if (!_.isEmpty(adminCircle) && !_.isEmpty(adminCircle?.circlemembers)) {
          const findAdmin = adminCircle?.circlemembers?.find(
            (member) => member.userid === userId
          );
          if (!_.isEmpty(findAdmin)) {
            setUserAdmin(true);
          }
        }
      }
    } catch (error) {
      console.error({ error });
    }
  }, [projectCircle]);

  const handleSelectedCircle = (data) => {
    setSelectedCircle(data);
  };

  const handleGetCircleMembers = (CircleId, proCircles) => {
    const getCurrCircle = proCircles?.find((dt) => dt?._id === CircleId);
    handleSelectedCircle(getCurrCircle);
  };

  const SelectedMembers =
    selectedCircle?.circlemembers?.length > 0
      ? selectedCircle?.circlemembers?.map((item) => {
          return {
            _id: item?._id,
            name: `${item?.fname} ${item?.lname}`,
            email: item?.email,
            invite_status: item?.status,
            registration_status: item?.userStatus
          };
        })
      : [];

  const userProjects =
    projectList && projectList.length > 0
      ? projectList?.map((item) => {
          return {
            ...item,
            status: item?.status ? 'Active' : 'Inactive',
            ...(isUserAdmin && {
              Delete: (
                <>
                  <Checkbox />
                </>
              )
            }),
            creationDatetime: item?.creationDatetime
              ? moment(item?.creationDatetime, 'YYYY-MM-DD').format(
                  'YYYY-MM-DD'
                )
              : 'Never Updated'
          };
        })
      : [];

  return (
    <section className="dashboard">
      <CircleSidebar
        projectCircle={projectCircle}
        handleGetCicleMembers={handleGetCircleMembers}
        isUserAdmin={isUserAdmin}
        SelectedCircle={handleSelectedCircle}
      />
      <CircleContent
        selectedCircle={selectedCircle || null}
        projectCircleMembers={SelectedMembers || []}
        projectCircle={projectCircle || []}
        projectList={userProjects || []}
        isUserAdmin={isUserAdmin}
        handleGetCicleMembers={handleGetCircleMembers}
      />
    </section>
  );
};

export default Circles;
