import { Input, Select } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { ListGroup } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  getCirclesActivityRequest,
  getDrawingsActivityRequest,
  getDrawingsSeenActivityRequest
} from '../../../redux/slicers/drawing';
import './styles.scss';

function ProjectActivity() {
  const [searchText, setSearchText] = useState('');
  const [selectActivity, setselectActivity] = useState('draw_act');
  const [filteredDrawingActivityData, setFilteredDrawingActivityData] =
    useState();
  const [filteredDrawingAccessLogs, setFilteredDrawingAccessLogs] = useState();
  const [filteredCircleActivity, setFilteredCircleActivity] = useState();
  const [drawingActivity, setDrawingActivity] = useState();
  const [accessLogs, setAccessLogs] = useState();
  const [circleActivity, setCircleActivity] = useState();
  const { id } = useParams();
  const dispatch = useDispatch();
  const handleSearch = (e) => {
    setSearchText(e.target.value);

    setTimeout(() => {
      filterActivities(e.target.value);
    }, 500);
  };

  const filterActivities = (value) => {
    setFilteredDrawingActivityData(
      drawingActivity?.filter(
        (a) =>
          a.drawingNumber?.toLowerCase()?.includes(value?.toLowerCase()) ||
          a.drawingName?.toLowerCase()?.includes(value?.toLowerCase()) ||
          a.userFullName?.toLowerCase()?.includes(value?.toLowerCase()) ||
          a.userCompany?.toLowerCase()?.includes(value?.toLowerCase()) ||
          a.circlename?.toLowerCase()?.includes(value?.toLowerCase()) ||
          a.action?.toLowerCase()?.includes(value?.toLowerCase())
      )
    );
    setFilteredCircleActivity(
      circleActivity?.filter(
        (a) =>
          a.adminFullName?.toLowerCase()?.includes(value?.toLowerCase()) ||
          a.circlename?.toLowerCase()?.includes(value?.toLowerCase()) ||
          a.memFullName?.toLowerCase()?.includes(value?.toLowerCase()) ||
          a.memEmail?.toLowerCase()?.includes(value?.toLowerCase()) ||
          a.memCompany?.toLowerCase()?.includes(value?.toLowerCase()) ||
          a.action?.toLowerCase()?.includes(value?.toLowerCase())
      )
    );
    setFilteredDrawingAccessLogs(
      accessLogs?.filter(
        (a) =>
          a.revisionNum?.toLowerCase()?.includes(value?.toLowerCase()) ||
          a.drawingNumber?.toLowerCase()?.includes(value?.toLowerCase()) ||
          a.drawingName?.toLowerCase()?.includes(value?.toLowerCase()) ||
          a.userFullName?.toLowerCase()?.includes(value?.toLowerCase()) ||
          a.email?.toLowerCase()?.includes(value?.toLowerCase()) ||
          a.status?.toLowerCase()?.includes(value?.toLowerCase()) ||
          a.company?.toLowerCase()?.includes(value?.toLowerCase())
      )
    );
  };
  useEffect(() => {
    dispatch(
      getDrawingsActivityRequest({
        payloadData: { pid: id },
        responseCallback: (status, res) => {
          if (status) {
            setFilteredDrawingActivityData(res);
            setDrawingActivity(res);
          }
        }
      })
    );
    dispatch(
      getDrawingsSeenActivityRequest({
        payloadData: { pid: id },
        responseCallback: (status, res) => {
          if (status) {
            setFilteredDrawingAccessLogs(res);
            setAccessLogs(res);
          }
        }
      })
    );
    dispatch(
      getCirclesActivityRequest({
        payloadData: { pid: id },
        responseCallback: (status, res) => {
          if (status) {
            setFilteredCircleActivity(res);
            setCircleActivity(res);
          }
        }
      })
    );
  }, []);
  console.log({ filteredDrawingActivityData });
  const options = [
    { label: 'Drawing Activity', value: 'draw_act' },
    { label: 'Drawing Access Logs', value: 'draw_access_log' },
    { label: 'Circle Activity', value: 'circle_act' }
  ];
  return (
    <section className="dashboard activity-cont">
      {filteredCircleActivity && (
        <>
          <Input
            placeholder="Search the project's activity"
            className="search-input activity-input"
            onChange={handleSearch}
            value={searchText}
          />
          <span className="select-curr-activity">
            <p>
              Please select the type of project activity that you want to view
              below.
            </p>
            <Select
              value={selectActivity}
              className="select-activity"
              options={options}
              onChange={(e) => {
                setselectActivity(e);
              }}
            />
          </span>

          <div className="activity-content-area">
            {selectActivity === 'draw_act' ? (
              <div className="content-cont draw-activity-cont">
                <div className="header-content activity-header">
                  <h5>Drawing Activity</h5>
                </div>
                <div className="table-cont">
                  <ListGroup>
                    {filteredDrawingActivityData?.map(
                      (item, index) =>
                        item.action !== 'commented on' && (
                          <ListGroup.Item
                            key={index}
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between'
                            }}
                          >
                            <p className="activity-info">
                              [
                              {moment(item.actionDatetime).format(
                                'DD/MM/YYYY HH:mm:ss'
                              )}
                              ] {item.userFullName} ({item.circlename}){' '}
                              {item.action === 'updated followerCircles' &&
                                ` changed the following circles for ${
                                  item?.drawingType === 'MODEL'
                                    ? 'model'
                                    : 'drawing'
                                } ` +
                                  "'" +
                                  item.drawingName +
                                  "'" +
                                  ' (' +
                                  item.drawingNumber +
                                  ');' +
                                  item.followerCirclesChange +
                                  ''}
                              {item.action === 'created' &&
                                ` created ${
                                  item?.drawingType === 'MODEL'
                                    ? 'model'
                                    : 'drawing'
                                } ` +
                                  item.drawingName +
                                  ' (' +
                                  item.drawingNumber +
                                  ')'}
                              {item.action === 'cancelled revision' &&
                                ' cancelled the revision ' +
                                  item.revisionNum +
                                  ` for the ${
                                    item?.drawingType === 'MODEL'
                                      ? 'model'
                                      : 'drawing'
                                  } ` +
                                  item.drawingName +
                                  ' (' +
                                  item.drawingNumber +
                                  ')'}
                              {item.action === 'revised' && (
                                <>
                                  {` added revision ${
                                    item?.revisionNum
                                  } for the ${
                                    item?.drawingType === 'MODEL'
                                      ? 'model'
                                      : 'drawing'
                                  } ${item?.drawingName} (${
                                    item?.drawingNumber
                                  }) ${item?.notes ? '- ' + item?.notes : ''}`}
                                </>
                              )}
                              {item.action === 'shared' &&
                                ` shared ${
                                  item?.drawingType === 'MODEL'
                                    ? 'model'
                                    : 'drawing'
                                } ` +
                                  item.drawingName +
                                  ' (' +
                                  item.drawingNumber +
                                  ') ' +
                                  'with ' +
                                  item.emailSharedWith +
                                  ''}
                              {item.action === 'updated drawingNumber' &&
                                ` changed the ${
                                  item?.drawingType === 'MODEL'
                                    ? 'model'
                                    : 'drawing'
                                } number from ` +
                                  " '" +
                                  item.drawingNumber +
                                  "' " +
                                  "to '" +
                                  item.newDrawingNumber +
                                  "' " +
                                  `for ${
                                    item?.drawingType === 'MODEL'
                                      ? 'model'
                                      : 'drawing'
                                  } ` +
                                  item.drawingName +
                                  ' (' +
                                  item.drawingNumber +
                                  ')'}
                              {item.action === 'updated drawingName' &&
                                ` changed the ${
                                  item?.drawingType === 'MODEL'
                                    ? 'model'
                                    : 'drawing'
                                } name from ` +
                                  " '" +
                                  item.drawingName +
                                  "' " +
                                  "to '" +
                                  item.newDrawingName +
                                  "' " +
                                  `for ${
                                    item?.drawingType === 'MODEL'
                                      ? 'model'
                                      : 'drawing'
                                  } ` +
                                  item.drawingName +
                                  ' (' +
                                  item.drawingNumber +
                                  ')'}
                            </p>
                          </ListGroup.Item>
                        )
                    )}
                  </ListGroup>
                </div>
              </div>
            ) : selectActivity === 'draw_access_log' ? (
              <div className="content-cont draw-activity-cont">
                {/* <div className="content-header"> */}
                <div className="header-content activity-header">
                  <h5>Drawing Access Logs</h5> {/* </div> */}
                </div>
                <div className="table-cont">
                  <ListGroup>
                    {filteredDrawingAccessLogs?.map(
                      (item, index) =>
                        item.action !== 'commented on' && (
                          <ListGroup.Item
                            key={index}
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between'
                            }}
                          >
                            <p className="activity-info">
                              [
                              {moment(item.datetime).format(
                                'DD/MM/YYYY HH:mm:ss'
                              )}
                              ] {item.userFullName} has seen revision{' '}
                              {item.revisionNum} for{' '}
                              {item?.drawingType === 'MODEL'
                                ? 'model'
                                : 'drawing'}{' '}
                              {item.drawingName} ({item.drawingNumber}.)
                            </p>
                          </ListGroup.Item>
                        )
                    )}
                  </ListGroup>
                </div>
              </div>
            ) : (
              selectActivity === 'circle_act' && (
                <div className="content-cont draw-activity-cont">
                  {/* <div className="content-header"> */}
                  <div className="header-content activity-header">
                    <h5>Circle Activity</h5> {/* </div> */}
                  </div>
                  <div className="table-cont">
                    <ListGroup>
                      {filteredCircleActivity?.map(
                        (item, index) =>
                          item.action !== 'commented on' && (
                            <ListGroup.Item
                              key={index}
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between'
                              }}
                            >
                              <p className="activity-info">
                                [
                                {moment(item.actionDatetime).format(
                                  'DD/MM/YYYY HH:mm:ss'
                                )}
                                ]
                                {item.action === 'added' &&
                                  ' ' +
                                    item.adminFullName +
                                    ' added ' +
                                    item.memFullName +
                                    ' (' +
                                    item.memEmail +
                                    ') to the ' +
                                    item.circlename +
                                    ' circle'}
                                {item.action === 'renamed circle' &&
                                  ' ' +
                                    item.memFullName +
                                    ' renamed the ' +
                                    item.circlename +
                                    ' circle ' +
                                    item.newcirclename +
                                    ''}
                                {item.action === 'accepted' &&
                                  ' ' +
                                    item.memFullName +
                                    ' (' +
                                    item.memEmail +
                                    ') ' +
                                    'accepted their invitation to the ' +
                                    item.circlename +
                                    ' circle'}
                                {item.action === 'rejected' &&
                                  ' ' +
                                    item.memFullName +
                                    ' (' +
                                    item.memEmail +
                                    ') ' +
                                    'rejected their invitation to the ' +
                                    item.circlename +
                                    ' circle'}
                                {item.action === 'created' &&
                                  ' ' +
                                    item.adminFullName +
                                    ' created the ' +
                                    item.circlename +
                                    ' circle'}
                              </p>
                            </ListGroup.Item>
                          )
                      )}
                    </ListGroup>
                  </div>
                </div>
              )
            )}
          </div>
          <div className="activity-content-area-web">
            <div className="content-cont draw-activity-cont">
              {/* <div className="content-header"> */}
              <div className="header-content activity-header">
                <h5>Drawing Activity</h5>
                {/* </div> */}
              </div>
              <div className="table-cont">
                <ListGroup>
                  {filteredDrawingActivityData?.map(
                    (item, index) =>
                      item.action !== 'commented on' && (
                        <ListGroup.Item
                          key={index}
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between'
                          }}
                        >
                          <p className="activity-info">
                            [
                            {moment(item.actionDatetime).format(
                              'DD/MM/YYYY HH:mm:ss'
                            )}
                            ] {item.userFullName} ({item.circlename}){' '}
                            {item.action === 'updated followerCircles' &&
                              ` changed the following circles for ${
                                item?.drawingType === 'MODEL'
                                  ? 'model'
                                  : 'drawing'
                              } ` +
                                "'" +
                                item.drawingName +
                                "'" +
                                ' (' +
                                item.drawingNumber +
                                ');' +
                                item.followerCirclesChange +
                                ''}
                            {item.action === 'created' &&
                              ` created ${
                                item?.drawingType === 'MODEL'
                                  ? 'model'
                                  : 'drawing'
                              } ` +
                                item.drawingName +
                                ' (' +
                                item.drawingNumber +
                                ')'}
                            {item.action === 'deleted' &&
                              ` deleted ${
                                item?.drawingType === 'MODEL'
                                  ? 'model'
                                  : 'drawing'
                              } ` +
                                item.drawingName +
                                ' (' +
                                item.drawingNumber +
                                ')'}
                            {item.action === 'cancelled revision' &&
                              ' cancelled the revision ' +
                                item.revisionNum +
                                ` for the ${
                                  item?.drawingType === 'MODEL'
                                    ? 'model'
                                    : 'drawing'
                                } ` +
                                item.drawingName +
                                ' (' +
                                item.drawingNumber +
                                ')'}
                            {item.action === 'revised' && (
                              <>
                                {` added revision ${
                                  item?.revisionNum
                                } for the ${
                                  item?.drawingType === 'MODEL'
                                    ? 'model'
                                    : 'drawing'
                                } ${item?.drawingName} (${
                                  item?.drawingNumber
                                }) ${item?.notes ? '- ' + item?.notes : ''}`}
                              </>
                            )}
                            {item.action === 'shared' &&
                              ` shared ${
                                item?.drawingType === 'MODEL'
                                  ? 'model'
                                  : 'drawing'
                              } ` +
                                item.drawingName +
                                ' (' +
                                item.drawingNumber +
                                ') ' +
                                'with ' +
                                item.emailSharedWith +
                                ''}
                            {item.action === 'updated drawingNumber' &&
                              ` changed the ${
                                item?.drawingType === 'MODEL'
                                  ? 'model'
                                  : 'drawing'
                              } number from ` +
                                " '" +
                                item.drawingNumber +
                                "' " +
                                "to '" +
                                item.newDrawingNumber +
                                "' " +
                                `for ${
                                  item?.drawingType === 'MODEL'
                                    ? 'model'
                                    : 'drawing'
                                } ` +
                                item.drawingName +
                                ' (' +
                                item.drawingNumber +
                                ')'}
                            {item.action === 'updated drawingName' &&
                              ` changed the ${
                                item?.drawingType === 'MODEL'
                                  ? 'model'
                                  : 'drawing'
                              } name from ` +
                                " '" +
                                item.drawingName +
                                "' " +
                                "to '" +
                                item.newDrawingName +
                                "' " +
                                `for ${
                                  item?.drawingType === 'MODEL'
                                    ? 'model'
                                    : 'drawing'
                                } ` +
                                item.drawingName +
                                ' (' +
                                item.drawingNumber +
                                ')'}
                          </p>
                        </ListGroup.Item>
                      )
                  )}
                </ListGroup>
              </div>
            </div>
            <div className="content-cont draw-activity-cont">
              {/* <div className="content-header"> */}
              <div className="header-content activity-header">
                <h5>Drawing Access Logs</h5> {/* </div> */}
              </div>
              <div className="table-cont">
                <ListGroup>
                  {filteredDrawingAccessLogs?.map(
                    (item, index) =>
                      item.action !== 'commented on' && (
                        <ListGroup.Item
                          key={index}
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between'
                          }}
                        >
                          <p className="activity-info">
                            [
                            {moment(item.datetime).format(
                              'DD/MM/YYYY HH:mm:ss'
                            )}
                            ] {item.userFullName} has seen revision{' '}
                            {item.revisionNum} for{' '}
                            {item?.drawingType === 'MODEL'
                              ? 'model'
                              : 'drawing'}{' '}
                            {item.drawingName} ({item.drawingNumber})
                          </p>
                        </ListGroup.Item>
                      )
                  )}
                </ListGroup>
              </div>
            </div>
            <div className="content-cont draw-activity-cont">
              {/* <div className="content-header"> */}
              <div className="header-content activity-header">
                <h5>Circle Activity</h5> {/* </div> */}
              </div>
              <div className="table-cont">
                <ListGroup>
                  {filteredCircleActivity?.map(
                    (item, index) =>
                      item.action !== 'commented on' && (
                        <ListGroup.Item
                          key={index}
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between'
                          }}
                        >
                          <p className="activity-info">
                            [
                            {moment(item.actionDatetime).format(
                              'DD/MM/YYYY HH:mm:ss'
                            )}
                            ]
                            {item.action === 'added' &&
                              ' ' +
                                item.adminFullName +
                                ' added ' +
                                item.memFullName +
                                ' (' +
                                item.memEmail +
                                ') to the ' +
                                item.circlename +
                                ' circle'}
                            {item.action === 'removed' &&
                              ' ' +
                                item.adminFullName +
                                ' removed ' +
                                item.memFullName +
                                ' (' +
                                item.memEmail +
                                ') to the ' +
                                item.circlename +
                                ' circle'}
                            {item.action === 'deleted' &&
                              ' ' +
                                item.adminFullName +
                                ' deleted ' +
                                item.circlename +
                                ' circle'}
                            {item.action === 'renamed circle' &&
                              ' ' +
                                item.memFullName +
                                ' renamed the ' +
                                item.circlename +
                                ' circle ' +
                                item.newcirclename +
                                ''}
                            {item.action === 'accepted' &&
                              ' ' +
                                item.memFullName +
                                ' (' +
                                item.memEmail +
                                ') ' +
                                'accepted their invitation to the ' +
                                item.circlename +
                                ' circle'}
                            {item.action === 'rejected' &&
                              ' ' +
                                item.memFullName +
                                ' (' +
                                item.memEmail +
                                ') ' +
                                'rejected their invitation to the ' +
                                item.circlename +
                                ' circle'}
                            {item.action === 'created' &&
                              ' ' +
                                item.adminFullName +
                                ' created the ' +
                                item.circlename +
                                ' circle'}
                          </p>
                        </ListGroup.Item>
                      )
                  )}
                </ListGroup>
              </div>
            </div>
          </div>
        </>
      )}
    </section>
  );
}

export default ProjectActivity;
