import { Table } from 'antd';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { ALERT_TYPES } from '../../constants';
import { toastAlert } from '../../services/utils';
import './styles.scss';
const DrawingTable = ({
  selectedCircleFollowedDrawings,
  currAction,
  handleSortCirclesInSelectedDrawing,
  selectedDrawing,
  selectedCircle
}) => {
  const state = useSelector((state) => state);
  const { drawing, projects } = state;
  const [isHas, setIsHas] = useState();
  console.log({
    selectedCircleFollowedDrawings: selectedCircleFollowedDrawings?.includes(
      isHas?._id
    )
  });

  const columns = [
    {
      title: <span className="table-head-cell">Drawing Number</span>,
      dataIndex: '_id',
      render: (item) => {
        const currDraw = drawing?.projectDrawings?.find(
          ({ _id }) => _id === item
        );
        setIsHas(currDraw);
        return (
          <span
            className={
              selectedCircleFollowedDrawings?.length &&
              selectedCircleFollowedDrawings?.includes(currDraw?._id)
                ? 'cell-span-selected'
                : currAction !== 'circle' && selectedDrawing?._id === item
                ? 'cell-span-selected'
                : 'cell-span-unselected'
            }
          >
            {currDraw?.dnumber}
          </span>
        );
      },
      responsive: ['sm', 'md', 'lg', 'xxl']
    },
    {
      title: <span className="table-head-cell">Drw. No.</span>,
      dataIndex: '_id',
      render: (item) => {
        const currDraw = drawing?.projectDrawings?.find(
          ({ _id }) => _id === item
        );
        setIsHas(currDraw);
        return (
          <span
            className={
              selectedCircleFollowedDrawings?.length &&
              selectedCircleFollowedDrawings?.includes(currDraw?._id)
                ? 'cell-span-selected'
                : currAction !== 'circle' && selectedDrawing?._id === item
                ? 'cell-span-selected'
                : 'cell-span-unselected'
            }
          >
            {currDraw?.dnumber}
          </span>
        );
      },
      responsive: ['xs']
    },
    {
      title: <span className="table-head-cell">Drawing Name</span>,
      dataIndex: '_id',
      key: '_id',
      render: (item) => {
        const currDraw = drawing?.projectDrawings?.find(
          ({ _id }) => _id === item
        );
        return (
          <span
            className={
              selectedCircleFollowedDrawings?.length &&
              selectedCircleFollowedDrawings?.includes(currDraw?._id)
                ? 'cell-span-selected'
                : currAction !== 'circle' && selectedDrawing?._id === item
                ? 'cell-span-selected'
                : ''
            }
          >
            {currDraw?.dname}
          </span>
        );
      },
      responsive: ['sm', 'md', 'lg', 'xxl']
    },
    {
      title: <span className="table-head-cell">Drw. Name</span>,
      dataIndex: '_id',
      key: '_id',
      className: 'cell-span-selected',
      render: (item) => {
        const currDraw = drawing?.projectDrawings?.find(
          ({ _id }) => _id === item
        );
        return (
          <span
            className={
              selectedCircleFollowedDrawings?.length &&
              selectedCircleFollowedDrawings?.includes(currDraw?._id)
                ? 'cell-span-selected'
                : currAction !== 'circle' && selectedDrawing?._id === item
                ? 'cell-span-selected'
                : ''
            }
          >
            {currDraw?.dname}
          </span>
        );
      },
      responsive: ['xs']
    }
  ];

  const circleNames = [...(projects?.projectCircle ?? [])] || [];

  return (
    <span className="drawing-table-wrapper">
      <span className="drawing-table-wrapper-tables">
        <h4>Drawings</h4>
        {circleNames?.length > 0 &&
          circleNames?.map(
            (item, index) =>
              drawing?.projectDrawings?.find(
                (dt) => dt?.circleid === item?._id
              ) && (
                <span key={index} className="drawing-by-wrapper">
                  <h5>{item?.circlename}</h5>
                  <Table
                    columns={columns}
                    dataSource={drawing?.projectDrawings?.filter(
                      (dt) => dt?.circleid === item?._id
                    )}
                    onRow={(item) => ({
                      onClick: () => {
                        if (currAction === 'drawing') {
                          handleSortCirclesInSelectedDrawing(item);
                        } else if (currAction === 'circle') {
                          if (selectedCircle?._id === item?.circleid) {
                            toastAlert(
                              "The selected circle is the owning circle of this drawing! It can't be removed",
                              ALERT_TYPES.error
                            );
                          } else if (
                            selectedCircle?.circlename === 'Administrators'
                          ) {
                            toastAlert(
                              'The drawings followed by the administrators circle cannot be modified.',
                              ALERT_TYPES.error
                            );
                          } else {
                            handleSortCirclesInSelectedDrawing(item);
                          }
                        }
                      }
                    })}
                    // rowClassName={
                    //   selectedCircleFollowedDrawings?.length
                    //     ? 'table-row-selected'
                    //     : 'table-row-unselected'
                    // }
                    pagination={false}
                  />
                </span>
              )
          )}
      </span>
    </span>
  );
};

export default DrawingTable;
