import React, { useState } from 'react';
import './auth.scss';
import logo from '../../assets/logo/Logo1.png';
import { Button, Col, Form, Input, Row } from 'antd';
import { ALERT_TYPES, LoginFields, SUCCESS_MESSAGES } from '../../constants';
import { useDispatch } from 'react-redux';
import { resendVerification } from '../../redux/slicers/user';
import { toastAlert } from '../../services/utils';
import { BeatLoader } from 'react-spinners';

function RequestVerification() {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { email } = LoginFields.fields;
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = () => {
    const values = form.getFieldsValue();
    setIsLoading(true);
    const payload = { email: values.email };

    dispatch(
      resendVerification({
        payloadData: payload,
        responseCallback: (status, res) => {
          if (!status) {
            toastAlert(res.msg, ALERT_TYPES.error);
            setIsLoading(false);
          } else {
            setIsLoading(false);
            toastAlert(SUCCESS_MESSAGES.RESEND_VERIFY, ALERT_TYPES.success);
          }
        }
      })
    );
  };
  return (
    <div className="login-container">
      <Row align={'middle'} className="loginWrapper" justify="center">
        <Col xs={24} lg={13}>
          <div className="loginLogoWrapper">
            <img src={logo} className="logo-login" />
            <div className="title-cont">
              <p style={{ fontWeight: 'bold' }}>Site</p>
              &nbsp;
              <p style={{ fontWeight: 'lighter' }}>Circles</p>
            </div>
            <p className="tagline">Current, Trackable, Always on Site</p>
            <p className="tagline">
              Please enter the email address with which you registered on
              SiteCircles in order to receive a new confirmation email.
            </p>
          </div>
          <Form className="form-login" form={form} onFinish={handleSubmit}>
            <Form.Item name={email.title} rules={email.rules}>
              <Input
                type={email.type}
                placeholder={email.label}
                className="login-input"
              />
            </Form.Item>
            <div className="login-button-wrapper">
              <div>
                <Button className="login-btn" size="large" htmlType="submit">
                  {isLoading ? (
                    <BeatLoader
                      // Type={ClipLoader}
                      size={8}
                      color={'#fff'}
                    />
                  ) : (
                    'Login'
                  )}
                </Button>
              </div>
            </div>
          </Form>
        </Col>
      </Row>
    </div>
  );
}

export default RequestVerification;
