import { Button, Checkbox, Col, Row } from 'antd';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { DrawingTablePermission } from '../../../components';
import { ALERT_TYPES } from '../../../constants';
import {
  getDrawingsRequest,
  updateDrawingPermissionRequest
} from '../../../redux/slicers/drawing';
import {
  getCircleOptionsRequest,
  getProjectCirclesRequest
} from '../../../redux/slicers/projects';
import { toastAlert } from '../../../services/utils';
import { Images } from '../../../theme';
import './styles.scss';

const SharingPermissions = () => {
  const state = useSelector((state) => state);
  const { drawing, projects } = state;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [sortByAction, setsortByAction] = useState('drawing');

  const [selectedCircle, setselectedCircle] = useState(null);
  const [selectedDrawing, setselectedDrawing] = useState(null);
  const [
    drawingsSelectedCircleHasFollowing,
    setdrawingsSelectedCircleHasFollowing
  ] = useState(null);

  const [circlesInDrawingsFollwers, setcirclesInDrawingsFollwers] =
    useState(null);
  console.log({ circlesInDrawingsFollwers });
  const params = useParams();
  //
  const circleOption = projects?.circleOptions?.length
    ? [...(projects?.circleOptions ?? [])]
    : [];
  console.log({ circleOption });
  const circleNames = projects?.projectCircle?.length
    ? [...(projects?.projectCircle ?? [])]
    : [];
  const alldrawings = drawing?.projectDrawings?.length
    ? [...(drawing?.projectDrawings ?? [])]
    : [];

  useEffect(() => {
    dispatch(
      getProjectCirclesRequest({
        payloadData: { projectid: params?.id },
        responseCallback: () => {}
      })
    );

    dispatch(
      getDrawingsRequest({
        payloadData: params?.id,
        responseCallback: () => {}
      })
    );
    dispatch(
      getCircleOptionsRequest({
        payloadData: { projectid: params?.id },
        responseCallback: () => {}
      })
    );
  }, []);

  //handlers
  const handleActionSelect = (val) => {
    if (val === 'drawing') {
      setdrawingsSelectedCircleHasFollowing(null);
      setsortByAction(val);
      setselectedCircle(null);
    } else {
      setcirclesInDrawingsFollwers(null);
      setsortByAction(val);
      setselectedDrawing(null);
    }
  };
  const handleSortDrawingsByCircle = (item) => {
    let drawingsSelectedCircleHasFollowing = alldrawings
      ?.filter((dt) => dt?.followerCircles.includes(item?._id))
      ?.map((dt) => dt?._id);
    setselectedCircle(item);
    setdrawingsSelectedCircleHasFollowing(drawingsSelectedCircleHasFollowing);
  };

  const handleSortCirclesInSelectedDrawing = (item) => {
    setselectedDrawing(item);
    setcirclesInDrawingsFollwers(item?.followerCircles);
    const { circleid, dname, dnumber, followerCircles } = item;

    if (sortByAction === 'circle' && selectedCircle) {
      let updateObj = {
        circleid,
        dname,
        dnumber,
        oldFollowerCircles: followerCircles,
        followerCircles: [],
        oldname: dname,
        oldnumber: dnumber
      };

      if (followerCircles.includes(selectedCircle?._id)) {
        let followers = [...followerCircles];
        let indx = followers.indexOf(selectedCircle?._id);
        followers.splice(indx, 1);
        updateObj = {
          ...updateObj,
          followerCircles: followers
        };

        handleUpdate({
          data: updateObj,
          id: params?.id,
          drawingId: item?._id
        });
        let circleDrawing = [...(drawingsSelectedCircleHasFollowing ?? [])];
        let indxOfDraw = circleDrawing?.indexOf(item?._id);
        circleDrawing.splice(indxOfDraw, 1);

        setdrawingsSelectedCircleHasFollowing(circleDrawing);
      } else {
        let updateObj = {
          circleid,
          dname,
          dnumber,
          oldFollowerCircles: followerCircles,
          followerCircles: [],
          oldname: dname,
          oldnumber: dnumber
        };
        let followers = [...followerCircles, selectedCircle?._id];
        updateObj = {
          ...updateObj,
          followerCircles: followers
        };

        handleUpdate({
          data: updateObj,
          id: params?.id,
          drawingId: item?._id
        });

        let circleDrawing = [
          ...(drawingsSelectedCircleHasFollowing ?? []),
          item?._id
        ];

        setdrawingsSelectedCircleHasFollowing(circleDrawing);
      }
    } else if (!selectedCircle && sortByAction === 'circle') {
      toastAlert(
        'Please select a circle on the right to view and modify the drawings which it follows',
        ALERT_TYPES.error
      );
    }
  };

  const handleUpdateDrawingFollowers = (
    actionCheck,
    oldFollowers,
    SelectedCircle
  ) => {
    const { circleid, dname, dnumber } = selectedDrawing;

    let updateCircle = {
      circleid,
      dname,
      dnumber,
      oldname: dname,
      oldnumber: dnumber,
      oldFollowerCircles: oldFollowers,
      followerCircles: []
    };

    if (SelectedCircle?._id === selectedDrawing?.circleid) {
      toastAlert(
        "The selected circle is the owning circle of this drawing! It can't be removed",
        ALERT_TYPES.error
      );
    } else {
      if (actionCheck.target.checked) {
        let follwers = [...oldFollowers, SelectedCircle?._id];
        updateCircle = {
          ...updateCircle,
          followerCircles: follwers
        };

        setcirclesInDrawingsFollwers(follwers);
        handleUpdate({
          data: updateCircle,
          id: params?.id,
          drawingId: selectedDrawing?._id
        });
      } else if (!actionCheck.target.checked) {
        let follwers = [...oldFollowers];
        let indx = follwers?.indexOf(SelectedCircle?._id);
        follwers.splice(indx, 1);
        updateCircle = {
          ...updateCircle,
          followerCircles: follwers
        };

        setcirclesInDrawingsFollwers(follwers);
        handleUpdate({
          data: updateCircle,
          id: params?.id,
          drawingId: selectedDrawing?._id
        });
      }
    }
  };

  const handleUpdateFollwersByCircle = () => {};

  const handleUpdate = (payload) => {
    dispatch(
      updateDrawingPermissionRequest({
        payloadData: { ...payload },
        responseCallback: (status, res) => {
          if (status && res) {
            toastAlert(res.msg, ALERT_TYPES.success);
            dispatch(
              getDrawingsRequest({
                payloadData: params?.id,
                responseCallback: () => {}
              })
            );
          }
        }
      })
    );
  };
  //handlers

  const renderCircleAdmin = (selectedCircle, item) => {
    return (
      <span
        className={
          selectedCircle?._id === item?._id
            ? 'span-selected'
            : 'span-not-selected'
        }
      >
        <b>{item?.circlename}</b>

        <img width={15} src={Images.Info} />
      </span>
    );
  };
  const renderCircleCheckBox = (selectedCircle, item) => {
    return (
      <span
        className={
          selectedCircle?._id === item?._id
            ? 'span-selected'
            : 'span-not-selected'
        }
      >
        <b>{item?.circlename}</b>
        <Checkbox
          value={item}
          onChange={(e) =>
            handleUpdateDrawingFollowers(e, circlesInDrawingsFollwers, item)
          }
          checked={
            circlesInDrawingsFollwers &&
            circlesInDrawingsFollwers?.includes(item?._id)
              ? true
              : false
          }
        />
      </span>
    );
  };

  return (
    <section className="dashboard">
      {/* <h1>Sharing permssions</h1> */}
      <Row style={{ width: '100%' }} gutter={[16, 16]}>
        <Col
          xl={{ span: 6 }}
          xxl={{ span: 6 }}
          lg={{ span: 8 }}
          md={{ span: 24 }}
          sm={{ span: 24 }}
          xs={{ span: 24 }}
        >
          <span className="col-1-main-wrapper">
            <span className="col-1-wrapper">
              <h3>To change between edit modes, use the toggle switch below</h3>
              <h4>
                <strong>By Drawing: </strong>
                Select a drawing, and the circles that follow the selected
                drawing will appear.
              </h4>
              <h4>
                <strong>By Circle: </strong>
                Select a Circle, and the drawings that the selected Circle is
                following will be highlighted
              </h4>
              <h6>Select Editing Mode</h6>
              <span className="button-wrapper">
                <Button
                  onClick={() => handleActionSelect('drawing')}
                  style={{
                    backgroundColor:
                      sortByAction === 'drawing' ? '#00aca8' : '#fff'
                  }}
                >
                  By Drawing
                </Button>
                <Button
                  onClick={() => handleActionSelect('circle')}
                  style={{
                    backgroundColor:
                      sortByAction === 'circle' ? '#00aca8' : '#fff'
                  }}
                >
                  By Circle
                </Button>
              </span>
            </span>
            <span className="back-btn-wrapper">
              <Button onClick={() => navigate(-1)}>Back</Button>
            </span>
          </span>
        </Col>
        <Col
          xl={{ span: 12 }}
          xxl={{ span: 12 }}
          lg={{ span: 8 }}
          md={{ span: 12 }}
          sm={{ span: 24 }}
          xs={{ span: 24 }}
        >
          <DrawingTablePermission
            currAction={sortByAction}
            handleSortCirclesInSelectedDrawing={
              handleSortCirclesInSelectedDrawing
            }
            selectedCircle={selectedCircle}
            handleUpdateFollwersByCircle={handleUpdateFollwersByCircle}
            handleUpdateDrawingFollowers={handleUpdateDrawingFollowers}
            selectedDrawing={selectedDrawing}
            selectedCircleFollowedDrawings={drawingsSelectedCircleHasFollowing}
          />
        </Col>
        <Col
          xl={{ span: 6 }}
          xxl={{ span: 6 }}
          lg={{ span: 8 }}
          md={{ span: 12 }}
          sm={{ span: 24 }}
          xs={{ span: 24 }}
        >
          <span className="col-1-main-wrapper">
            <span className="col-1-wrapper circle-wrapper">
              <h3>Following Circles</h3>
              <span className="col-3-wrapper">
                {sortByAction === 'circle'
                  ? circleNames?.map((item) => (
                      <span
                        key={item?._id}
                        className={
                          selectedCircle?._id === item?._id
                            ? 'span-selected'
                            : 'span-not-selected'
                        }
                        onClick={() => handleSortDrawingsByCircle(item)}
                      >
                        <b>{item?.circlename}</b>
                      </span>
                    ))
                  : sortByAction === 'drawing' &&
                    (selectedDrawing ? (
                      circleNames?.map((item) =>
                        item.circlename === selectedDrawing.circlename ||
                        item?.circlename === 'Administrators'
                          ? renderCircleAdmin(selectedCircle, item)
                          : renderCircleCheckBox(selectedCircle, item)
                      )
                    ) : (
                      <h6>
                        Please select a drawing to view and modify it's
                        following circles.
                      </h6>
                    ))}
                {/* <span>
                  Admin
                  <Checkbox />
                </span> */}
              </span>
            </span>
          </span>
        </Col>
      </Row>
    </section>
  );
};

export default SharingPermissions;
