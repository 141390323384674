import React from 'react';
import './auth.scss';
import logo from '../../assets/logo/Logo1.png';
import { Button, Col, Form, Input, Row } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { ALERT_TYPES, LoginFields, SUCCESS_MESSAGES } from '../../constants';
import { userLoginRequest } from '../../redux/slicers/user';
import { useDispatch } from 'react-redux';
import { toastAlert } from '../../services/utils';
import { useState } from 'react';
import { BeatLoader } from 'react-spinners';
function Login() {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { email, password } = LoginFields.fields;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const handleSubmit = () => {
    const values = form.getFieldsValue();
    setIsLoading(true);
    const payload = {
      email: values.email,
      password: values.password
    };
    dispatch(
      userLoginRequest({
        payloadData: payload,
        responseCallback: (status, res) => {
          if (!status) {
            toastAlert(res.msg, ALERT_TYPES.error);
            setIsLoading(false);
          } else {
            setIsLoading(false);
            toastAlert(SUCCESS_MESSAGES.LOGIN, ALERT_TYPES.success);
          }
        }
      })
    );
  };
  return (
    <div className="login-container">
      <Row align={'middle'} className="loginWrapper" justify="center">
        <Col xs={24} lg={13}>
          <div className="loginLogoWrapper">
            <img src={logo} className="logo-login" />
            <div className="title-cont">
              <p style={{ fontWeight: 'bold' }}>Site</p>
              &nbsp;
              <p style={{ fontWeight: 'lighter' }}>Circles</p>
            </div>
            <p className="tagline">Current, Trackable, Always on Site</p>
          </div>
          <Form className="form-login" form={form} onFinish={handleSubmit}>
            <Form.Item name={email.title} rules={email.rules}>
              <Input
                type={email.type}
                placeholder={email.label}
                className="login-input"
              />
            </Form.Item>
            <Form.Item name={password.title} rules={password.rules}>
              <Input
                type={password.type}
                placeholder={password.label}
                className="login-input"
              />
            </Form.Item>
            <div className="login-button-wrapper">
              <div>
                <Button className="login-btn" size="large" htmlType="submit">
                  {isLoading ? (
                    <BeatLoader
                      // Type={ClipLoader}
                      size={8}
                      color={'#fff'}
                    />
                  ) : (
                    'Login'
                  )}
                </Button>
              </div>
              <div>
                <Button
                  size="large"
                  className="register-btn"
                  onClick={() => {
                    navigate('/register');
                  }}
                >
                  Register
                </Button>
              </div>
            </div>
            <div className="label-wrapper">
              <Link to="/forget-pass" className="frgt-pass">
                Forgot your password?
              </Link>
              <br />
              <Link className="frgt-pass" to="/request-verify-email">
                Haven't received a verification email yet?
              </Link>
              <br />
              <a
                className="frgt-pass"
                target="_blank"
                rel="noreferrer"
                href="https://sitecircles.com"
              >
                What is SiteCircles?
              </a>
            </div>
          </Form>
        </Col>
      </Row>
    </div>
  );
}

export default Login;
