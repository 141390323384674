import { Dropdown, Menu, Table, Tooltip } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { revisionStatus } from '../../../constants';
import {
  bulkUploadItemRemove,
  bulkUploadSetStatusItem
} from '../../../redux/slicers/upload';
import Images from '../../../theme/Images';
import './styles.scss';
const UploadStep5 = () => {
  const state = useSelector((state) => state);
  const { bulkFilesForUpload } = state?.upload;
  const dispatch = useDispatch();

  const clickRemoveItem = (item) => {
    dispatch(bulkUploadItemRemove(item));
  };

  const setStatusItem = (item, menuItem) => {
    dispatch(bulkUploadSetStatusItem({ comingObj: item, menuItem }));
  };

  const columns = [
    {
      title: 'New Drawing',
      render: (item) => {
        if (item?.drawingExists) {
          return (
            <img style={{ width: 10, height: 10 }} src={Images.crossIcon} />
          );
        } else {
          return (
            <img style={{ width: 12, height: 8 }} src={Images.checkIcon} />
          );
        }
      }
    },

    {
      title: 'Drawing No',
      dataIndex: 'dnumber',
      render: (item) => (
        <Tooltip title={item?.length > 10 ? item : ''} placement="bottom">
          <p className="step5-item">{item}</p>
        </Tooltip>
      )
    },

    {
      title: 'Drawing name',
      dataIndex: 'dname',
      render: (item) => (
        <Tooltip title={item?.length > 10 ? item : ''} placement="bottom">
          <p className="step5-item">{item}</p>
        </Tooltip>
      )
    },

    {
      title: 'Status',
      render: (item) => (
        <div>
          <div className="step5-status-div">
            <Dropdown
              overlay={
                <Menu
                  onClick={(menuItem) => {
                    // setRevisionStatusIndex(item.key);
                    setStatusItem(item, menuItem?.key);
                  }}
                >
                  <Menu.Item key={revisionStatus[0]}>
                    {revisionStatus[0]}
                  </Menu.Item>
                  <Menu.Item key={revisionStatus[1]}>
                    {revisionStatus[1]}
                  </Menu.Item>
                  <Menu.Item key={revisionStatus[2]}>
                    {revisionStatus[2]}
                  </Menu.Item>
                  <Menu.Item key={revisionStatus[3]}>
                    {revisionStatus[3]}
                  </Menu.Item>
                  <Menu.Item key={revisionStatus[4]}>
                    {revisionStatus[4]}
                  </Menu.Item>
                  <Menu.Item key={revisionStatus[5]}>
                    {revisionStatus[5]}
                  </Menu.Item>
                </Menu>
              }
              trigger={['click']}
            >
              <div className="ant-dropdown-options">
                <a className="txt-step5-status">{item.status}</a>
                <img style={{ marginLeft: 10 }} src={Images.DropdownIcon} />
              </div>
            </Dropdown>
          </div>
        </div>
      )
    },

    {
      title: 'Existing Revision',
      render: (item) => (
        <p className="step5-item">
          <Tooltip
            title={
              item?.latestRevision?.length > 10 ? item?.latestRevision : ''
            }
            placement="bottom"
          >
            {item.latestRevision ? item?.latestRevision : '-'}
          </Tooltip>
        </p>
      )
    },
    {
      title: 'Uploaded Revision',
      render: (item) => (
        <Tooltip
          title={item?.rnumber?.length > 10 ? item?.rnumber : ''}
          placement="bottom"
        >
          <p className="step5-item">{item?.rnumber}</p>
        </Tooltip>
      )
    },

    {
      title: 'Format',
      render: (item) => {
        return (
          <div className="format-step5-div">
            <p
              style={{
                color: '#00aca8'
              }}
            >
              PDF
            </p>
            <p
              style={
                item.dwgFile
                  ? {
                      color: '#00aca8'
                    }
                  : {
                      color: '#d8d8d8'
                    }
              }
            >
              DWG
            </p>
          </div>
        );
      }
    },
    {
      title: 'Remove',
      render: (item) => (
        <img
          onClick={() => clickRemoveItem(item)}
          className="cross-icon-step5"
          src={Images.crossIconRed}
        />
      )
    }
  ];
  return (
    <div className="step5-main-div">
      <Table
        columns={columns}
        dataSource={bulkFilesForUpload}
        className="step5-table-style"
        // onChange={handleTableChange}
        pagination={false}
      />
    </div>
  );
};

export default UploadStep5;
