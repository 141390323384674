import { Table, Tooltip } from 'antd';
import axios from 'axios';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  addRevisionRequest,
  createDrawingRequest,
  getUploadUrlsRequest
} from '../../../redux/slicers/drawing';
import {
  bulkUploadedFIlesCount,
  bulkUploadingLoader,
  shareCircleIndividualCheck,
  shareCircleSelectAll,
  shareCircleSelectColumn,
  shareCircleSelectRow
} from '../../../redux/slicers/upload';
import './styles.scss';
import { Images } from '../../../theme';

const UploadStep6 = ({ setStep }) => {
  var filesUploadedCount = 0;
  const state = useSelector((state) => state);

  const dispatch = useDispatch();
  const { bulkFilesForUpload } = state?.upload;
  const selectedCircleID = bulkFilesForUpload[0]?.circle?.circleId;
  const { projects } = state;
  const { circleOptions, circleNames } = projects;
  const [sharePermissionCircle, setSharePermissionCircle] = useState([]);
  const [sharePermissionDrawing, setSharePermissionDrawings] = useState(
    () => bulkFilesForUpload
  );

  useEffect(() => {
    setSharePermissionCircle(
      circleNames?.filter((item) => item?._id != selectedCircleID)
    );
    const tempArray = [];
    bulkFilesForUpload.map((item) => {
      if (_.isUndefined(item.drawingid)) {
        tempArray.push(item);
      }
    });
    setSharePermissionDrawings(bulkFilesForUpload);
  }, [circleOptions, bulkFilesForUpload]);
  const params = useParams();
  const handleBulkUpload = async () => {
    dispatch(bulkUploadingLoader(true));
    setStep(7);
    bulkFilesForUpload.map((item) => {
      const idsWithAdmin = projects?.circleNames?.map((dt) => dt?._id);
      const idsWithNotAdmin = projects?.circleOptions?.map((dt) => dt?._id);
      const { dname, dnumber, circle, followercircle } = item;
      const adminId = idsWithAdmin?.filter(
        (dt) => !idsWithNotAdmin?.includes(dt)
      );
      const tempFollowercircle = followercircle ? followercircle : [];
      let payload = {
        dname: dname,
        dnumber: dnumber,
        circleid: circle?.circleId,
        followerCircles: [...adminId, ...tempFollowercircle]
      };
      if (!_.isUndefined(item?.drawingid)) {
        const payload = {
          id: params.id,
          drawingId: item?.drawingid
        };
        dispatch(
          getUploadUrlsRequest({
            payloadData: payload,
            responseCallback: async (status, response) => {
              if (status) {
                if (item.dwgFile) {
                  await uploadDwgFileToS3(response, item);
                  uploadPdfFileToS3(response, item, item?.drawingid);
                } else {
                  uploadPdfFileToS3(response, item, item?.drawingid);
                }
              }
            }
          })
        );
      } else {
        dispatch(
          createDrawingRequest({
            payloadData: { data: payload, id: params?.id },
            responseCallback: (status, res) => {
              if (status) {
                const payload = {
                  id: params.id,
                  drawingId: res?._id
                };
                dispatch(
                  getUploadUrlsRequest({
                    payloadData: payload,
                    responseCallback: async (status, response) => {
                      if (status) {
                        if (item.dwgFile) {
                          await uploadDwgFileToS3(response, item, res?._id);
                          uploadPdfFileToS3(response, item, res?._id);
                        } else {
                          uploadPdfFileToS3(response, item, res?._id);
                        }
                      }
                    }
                  })
                );
              }
            }
          })
        );
      }
    });
  };

  const uploadDwgFileToS3 = async (values, item) => {
    console.log({ item, values });
    const formData = new FormData();
    Object.keys(values?.dwg?.fields).map((keys) => {
      if (keys !== 'bucket') {
        formData.append(`${keys}`, values?.dwg?.fields[keys]);
      }
    });
    formData.append('file', item.dwgFile?.file);
    console.log({ formData });
    return axios.post(values?.dwg?.url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  };

  const uploadPdfFileToS3 = async (values, item, drawingId) => {
    const formData = new FormData();
    Object.keys(values?.pdf?.fields).map((keys) => {
      if (keys !== 'bucket') {
        formData.append(`${keys}`, values?.pdf?.fields[keys]);
      }
    });
    formData.append('file', item?.file);
    axios
      .post(values?.pdf?.url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(function () {
        apiReqForRevisionSubmit(item, drawingId);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const apiReqForRevisionSubmit = (values, drawingId) => {
    const { circle, dname, dnumber, status } = values;

    const addRevision = {
      ...values,
      projectid: params?.id,
      drawingid: drawingId,
      circle: circle?.circlename,
      dname,
      dnumber,
      status: !_.isEmpty(status) ? (status === 'No Status' ? '' : status) : '',
      hasDWG: values?.dwgFile ? true : false
    };

    let fileUploadStartTime = new Date(),
      fileUploadEndTime = new Date();

    const fileUploadDur =
      moment(fileUploadEndTime).diff(fileUploadStartTime, 'milliseconds') /
      1000;
    let revisionUploadStartTime = new Date(),
      revisionUploadEndTime = new Date();
    addRevisionReqSubmit(
      addRevision,
      () => {
        revisionUploadEndTime = moment();
        const revisionDuration =
          moment(revisionUploadEndTime).diff(
            revisionUploadStartTime,
            'milliseconds'
          ) / 1000;

        return revisionDuration;
      },
      fileUploadStartTime,
      fileUploadDur,
      values?.file,
      { ...values }
    );
  };

  const addRevisionReqSubmit = async (payload) => {
    await dispatch(
      addRevisionRequest({
        payloadData: { ...payload },
        responseCallback: () => {}
      })
    );

    filesUploadedCount++;
    dispatch(bulkUploadedFIlesCount(filesUploadedCount));
  };

  const onClickIndividualCheck = (circleItem, drawingItem) => {
    dispatch(shareCircleIndividualCheck({ circleItem, drawingItem }));
  };
  const onClickColumnnCheck = (circleItem) => {
    dispatch(shareCircleSelectColumn({ circleItem }));
  };
  const onClickRowCheck = (drawingItem) => {
    dispatch(
      shareCircleSelectRow({ drawingItem, circles: sharePermissionCircle })
    );
  };
  const onClickSelectAll = () => {
    dispatch(shareCircleSelectAll({ circles: sharePermissionCircle }));
  };

  const columnsForOne = [
    {
      title: '',
      align: 'center',
      render: (item) => {
        return (
          <div
            onClick={() => onClickRowCheck(item)}
            className="select-firsrt-column-row"
          >
            <p className="select-first-column-one-txt">Select Row</p>
          </div>
        );
      }
    },
    {
      title: <span className="step6-first-table-title"> Drw.Number </span>,
      render: (item) => {
        return (
          <div>
            <Tooltip
              title={item.dnumber.length > 10 ? item.dnumber : ''}
              placement="bottom"
            >
              <p className="step6-table-one-item">{item?.dnumber}</p>
            </Tooltip>
          </div>
        );
      }
    },

    {
      title: <span className="step6-first-table-title"> Drw.Name </span>,
      render: (item) => {
        return (
          <div>
            <Tooltip
              title={item.dname.length > 10 ? item.dname : ''}
              placement="bottom"
            >
              <p className="step6-table-column-one">{item?.dname}</p>
            </Tooltip>
          </div>
        );
      }
    }
  ];

  const columnstwo = sharePermissionCircle?.map((circleItem) => {
    return {
      title: (
        <div className="step6-columnsTwo-title">
          <span
            className={
              circleItem?.circlename == 'Administrators'
                ? 'step6-share-title-circle-admin'
                : 'step6-share-title-circle'
            }
          >
            {circleItem ? circleItem.circlename : ''}
          </span>
          {circleItem?.circlename != 'Administrators' && (
            <div
              onClick={() => onClickColumnnCheck(circleItem)}
              className="select-second-column-row"
            >
              <p className="select-second-column-two-txt">Select Column</p>
            </div>
          )}
        </div>
      ),
      render: (drawingItem) => {
        if (_.isUndefined(drawingItem.drawingid)) {
          return (
            <div className="column-checkbob-div">
              {circleItem?.circlename == 'Administrators' ? (
                <div className="step6-discheckBox">
                  <img src={Images.disCheckbox} />
                </div>
              ) : (
                <input
                  checked={
                    drawingItem?.followerCircleCheck
                      ? drawingItem?.followerCircleCheck[circleItem?._id]
                          ?.isCheck
                      : false
                  }
                  onClick={() =>
                    onClickIndividualCheck(circleItem, drawingItem)
                  }
                  type="checkbox"
                  className="columns-checkBox-style"
                />
              )}
            </div>
          );
        } else {
          return (
            <div className="column-checkbob-div">
              <div className="step6-discheckBox">
                <img src={Images.disCheckbox} />
              </div>
            </div>
          );
        }
      }
    };
  });
  return (
    <div className="step6-main-div">
      <div className="step6-div-tables">
        <div onClick={() => onClickSelectAll()} className="step6-select-all">
          <p>Select all</p>
        </div>

        <div className="share-perissions-div">
          {!_.isEmpty(sharePermissionDrawing) ? (
            <>
              <Table
                columns={columnsForOne}
                dataSource={sharePermissionDrawing}
                className="step6-first-table-style"
                pagination={false}
              />
              <Table
                columns={columnstwo}
                bordered
                dataSource={sharePermissionDrawing}
                className="step6-second-table-style"
                pagination={false}
              />
            </>
          ) : (
            <div className="step6-permission-table-empty">
              <p>
                There is no need for share permission since all drawings have
                been updated
              </p>
            </div>
          )}
        </div>
      </div>
      <div className="step6-div-uploading">
        <div onClick={() => handleBulkUpload()} className="start-uploading-Btn">
          <p className="next-txt">Start Uploading</p>
        </div>
      </div>
    </div>
  );
};

export default UploadStep6;
