import React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PrimiumProjectCreditRequest } from '../../../redux/slicers/general';
import './styles.scss';
const UserProfile = () => {
  const data = useSelector((state) => state.user.data);
  const primiumProject = useSelector((state) => state.general.primiumProject);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(PrimiumProjectCreditRequest({}, () => {}));
  }, []);

  return (
    <section className="profile">
      <div className="new-form info-div">
        {/* <h3>{data?.data?.fname + ' ' + data?.data?.lname}</h3> */}
        <div className="profile-info">
          <div className="profile-info-border">
            <div className="info-cont">
              <p className="bold-txt">Name: </p>
              <p>{data?.data?.fname + ' ' + data?.data?.lname}</p>
            </div>
            <div className="border-line"></div>
            <div className="info-cont">
              <p className="bold-txt">Company: </p>
              <p>{data?.data?.cname}</p>
            </div>

            <div className="border-line"></div>
            <div className="info-cont">
              <p className="bold-txt">Email: </p>
              <p>{data?.data?.email}</p>
            </div>
            <div className="border-line"></div>
            <div className="info-cont-credit">
              <div className="credite-div">
                <div className="creite-item">
                  <p className="bold-txt">Available Project Credits:</p>
                  <p>{primiumProject?.available} Projects</p>
                </div>
                <div className="creite-item">
                  <p className="bold-txt">Total Projects Created:</p>
                  <p>{primiumProject?.totalOwnedProjects} Projects</p>
                </div>
              </div>
              <div className="border-line-mo"></div>
              <div
                onClick={() => {
                  window.open('https://sitecircles.com', '_blank');
                }}
                className="primuim-credit-btn"
              >
                <p>Purchase more Project Credits</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default UserProfile;
