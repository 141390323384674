import React, { useEffect, useState } from 'react';
import './styles.scss';
import {
  ALERT_TYPES,
  PROJECTS_LIST_COLUMNS,
  PROJECT_DETAILS_ROUTE,
  SUCCESS_MESSAGES
} from '../../constants';
import { Button, Input, Table } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  acceptCircleInviteRequest,
  archiveProjectRequest,
  deleteNotificationRequest,
  leaveProjectRequest,
  rejectCircleInviteRequest
} from '../../redux/slicers/projects';
import { BeatLoader } from 'react-spinners';
import moment from 'moment';
import { toastAlert } from '../../services/utils';
import ConfirmationModal from '../confirmationModal';
function DashboardContent({
  archived,
  projectList,
  loading,
  invitesList,
  initialRequest,
  filterOutInvitees
}) {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingRej, setIsLoadingRej] = useState(false);
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState('');
  const [filteredList, setFilteredList] = useState([]);
  const [leavedProjects, setleavedProjects] = useState(() => []);
  const [selectedProject, setSelectedProject] = useState({});

  const [openModal, setOpenModal] = useState({
    title: '',
    desc: '',
    bool: false,
    loader: false
  });

  const user = useSelector((state) => state?.user?.data?.data);

  useEffect(() => {
    if (projectList) {
      const filteredProjects = projectList?.filter(
        (p) =>
          p?.projectname?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
          p?.creator?.toLowerCase()?.includes(searchText?.toLowerCase())
      );

      setFilteredList(filteredProjects);
    }
  }, [projectList]);

  const handleSearch = (e) => {
    setSearchText(e.target.value);
    setFilteredList(
      projectList?.filter(
        (a) =>
          a.projectname.toLowerCase().includes(e.target.value.toLowerCase()) ||
          a.creator.toLowerCase().includes(e.target.value.toLowerCase())
      )
    );
  };

  const handleRoute = (url) => {
    navigate(url);
  };

  const handleArchive = (id, status, callback) => {
    const payload = { id: id, status: { archiveStatus: status } };

    dispatch(
      archiveProjectRequest({
        ...payload,
        responseCallback: (status, res) => {
          setOpenModal({ ...openModal, bool: false, loader: false });
          toastAlert(
            res.msg,
            status ? ALERT_TYPES?.success : ALERT_TYPES?.error
          );
          if (callback) callback(res);
          initialRequest();
        }
      })
    );
  };

  const handleLeave = (id) => {
    const payload = { projectid: id };

    dispatch(
      leaveProjectRequest({
        payloadData: payload,
        responseCallback: (status, res) => {
          if (status && res) {
            setleavedProjects([...leavedProjects, id]);
            toastAlert(res.msg, ALERT_TYPES.success);
          } else if (!status && res) {
            toastAlert(res.msg, ALERT_TYPES.error);
          }
        }
      })
    );
  };

  const handleClick = (item) => {
    if (Object.keys(item).includes('Notification') && item?.Notification > 0) {
      handleDeleteNotif(item?._id);
    }
    handleRoute(`${PROJECT_DETAILS_ROUTE}${item?._id}`);
  };

  const handleViewActivity = (id) => {
    navigate(`/project/${id}/project-activity`);
  };

  const handleViewDetails = (id) => {
    navigate(`/project/${id}/view`);
  };

  const handleChangeDetails = (id) => {
    navigate(`/project/${id}/edit`);
  };

  // deleteNotificationRequest
  const handleDeleteNotif = (id) => {
    dispatch(
      deleteNotificationRequest({
        payloadData: { id: id },
        responseCallback: () => {}
      })
    );
  };

  const handleReject = (id) => {
    setIsLoadingRej(true);

    const payload = { circleid: id };
    dispatch(
      rejectCircleInviteRequest({
        payloadData: payload,
        responseCallback: (status, res) => {
          if (!status) {
            toastAlert(res.msg, ALERT_TYPES.error);
            setIsLoadingRej(false);
          } else {
            setIsLoadingRej(false);
            filterOutInvitees(id);
            toastAlert(SUCCESS_MESSAGES.REJECT_INVITE, ALERT_TYPES.success);
            navigate('/dashboard');
          }
        }
      })
    );
  };

  const handleAccept = (id, pid, pname) => {
    setIsLoading(true);
    const payload = { circleid: id, projectid: pid, projectname: pname };
    dispatch(
      acceptCircleInviteRequest({
        payloadData: payload,
        responseCallback: (status, res) => {
          if (!status) {
            toastAlert(res.msg, ALERT_TYPES.error);
            setIsLoading(false);
          } else {
            filterOutInvitees(id);
            setIsLoading(false);
            toastAlert(SUCCESS_MESSAGES.ACCEPT_INVITE, ALERT_TYPES.success);
            initialRequest();
            navigate('/dashboard');
          }
        }
      })
    );
  };

  const handleArchiveProject = () => {
    setOpenModal({
      ...openModal,
      loader: true
    });

    handleArchive(selectedProject?.itemId, selectedProject?.status, () => {
      setOpenModal({ ...openModal, bool: false, loader: false });
      setSelectedProject({});
    });
  };

  const handleOpenModal = (itemId, status) => {
    setSelectedProject({
      itemId,
      status
    });

    setOpenModal({
      title: 'Archive Project',
      desc: 'Are you sure you would like to archive this project? This can be undone by restoring it from your project archives list.',
      bool: true,
      loader: false
    });
  };

  return (
    <div className="content-cont">
      <div className="content-header">
        <div className="header-content-archived">
          <div className="heading-content-txt-div">
            {archived ? <h5>My Archived Projects</h5> : <h5>My Projects</h5>}
          </div>
          {/* <Form>
            <Form.Item> */}
          <div>
            <Input
              placeholder="Search"
              className="search-input-archived "
              onChange={handleSearch}
              value={searchText}
            />
          </div>
          {/* </Form.Item>
          </Form> */}
        </div>
      </div>
      <div className="table-cont-dashboad loader-cont">
        {loading ? (
          <BeatLoader
            // Type={ClipLoader}
            size={20}
            color={'#000'}
            style={{ marginTop: '50px' }}
          />
        ) : (
          <>
            <Table
              dataSource={filteredList
                .filter((dt) => !leavedProjects.includes(dt?._id))
                .sort(
                  (a, b) => new Date(b?.lastUpdate) - new Date(a?.lastUpdate)
                )}
              columns={PROJECTS_LIST_COLUMNS(
                handleArchive,
                handleLeave,
                handleClick,
                handleViewActivity,
                handleViewDetails,
                handleChangeDetails,
                user,
                archived,
                handleOpenModal
              )}
              rowClassName="table-row"
              pagination={false}
              style={{ marginBottom: '40px' }}
            />
            {invitesList?.length > 0 && (
              <div className="invites-list-cont">
                <h5>Invites</h5>
              </div>
            )}
            <div className="resp-invites-cont">
              {invitesList?.length > 0 &&
                invitesList.map((item) => (
                  <div className="invites-component" key={item._id}>
                    <p className="invites-info">
                      [{moment(item.datetime).format('DD/MM/YYYY HH:mm:ss')}]{' '}
                      <b>{item.creator} </b>added you to the{' '}
                      <b>{item.circlename}</b> circle in{' '}
                      <b>{item.creatorDetails.cname}'s</b> project{' '}
                      <b>{item.projectDetails.pname}.</b>
                    </p>
                    <div className="invites-btn-wrapper">
                      <Button
                        className="invites-btn-accept"
                        onClick={() =>
                          handleAccept(
                            item._id,
                            item.projectDetails._id,
                            item.projectDetails.pname
                          )
                        }
                      >
                        {isLoading ? (
                          <BeatLoader
                            // Type={ClipLoader}
                            size={8}
                            color={'#fff'}
                          />
                        ) : (
                          'Accept'
                        )}
                      </Button>
                      <Button
                        className="invites-btn-reject"
                        onClick={() => handleReject(item._id)}
                      >
                        {isLoadingRej ? (
                          <BeatLoader
                            // Type={ClipLoader}
                            size={8}
                            color={'#fff'}
                          />
                        ) : (
                          'Reject'
                        )}
                      </Button>
                    </div>
                  </div>
                ))}
            </div>
          </>
        )}
      </div>

      {openModal?.bool && (
        <ConfirmationModal
          loading={openModal.loader}
          onSubmit={handleArchiveProject}
          title={openModal.title}
          description={openModal.desc}
          handleClose={() => {
            setOpenModal({ ...openModal, bool: false, loader: false });
            setSelectedProject({});
          }}
        />
      )}
    </div>
  );
}

export default DashboardContent;
