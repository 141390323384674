const border = 'rgba(0,0,0,0.06)';
const offWhite = '#eee';
const offWhite2 = '#f9f9f9';
const offWhite3 = '#f3f3f3';
const offWhite4 = '#dadada';
const offWhite5 = '#9E9E9E';

const offBlack = '#3e4851';
const offBlack2 = '#4b4b4b';
const lightBlack = '#5b656e';
const whiteOpaque = 'rgba(204,204,204,0.50)';
const white = '#fff';
const tintedBlack = 'rgba(00,00,00,0.6)';
const black = '#000';
const orange = 'orange';
const red = '#f94242';
const red2 = '#ff2c2c';
const grey = '#979797';
const grey2 = '#e7e7e7';
const darkGrey = '#949494';
const greyish = '#3e3e3e';
const littleGrey = '#a2a2a2';
const doveGray = '#666666';
const polar = '#f1fcf7';
const ghost = '#ced4da;';
const viking = '#5ddea7';
const codGray = '#1D1D1D';
const boulder = '#787878';
const outerSpace = '#141313';
const codGrays = '#191818';
// colors
const hotPink = '#ff436d';
const deepPurple = '#983eeb';
const pastelBlue = '#47a4ff';
const blueGreen = '#0ACE9B';
const brightYellow = '#f6e754';
const blackish = '#393939';
const persimmon = '#FF5757';
const apGrey = '#828282';
const alto = '#E0E0E0';
const mercury = '#E4E4E4';
const lightGreen = '#f4fdf9';
const lightGray = '#ebebeb';
const emerald = '#54c796';
const mercurySolid = '#E5E5E5';
const silver = '#bbbbbb';
const altoSolid = '#DDDDDD';
const cornFlowerBlue = '#5fb2ec';
const vikings = '#5EDEA8';
const grey3 = '#8e8e8e';
const grey4 = '#656565';
const bittersweet = '#FF5D5D';
const oceanGreen = '#49BB8B';
const zuccini = '#085032';
const railRight = '#f0f0f0';
const curiousBlue = '#1D83E5';
const alto2 = '#d5d5d5';
const silver2 = '#cacaca';

// MAIN COLOR PALETTE - theme colors (dark)
const theme = '#2098f2';
const theme2 = '#1862f7';
const themelight = 'rgba(32, 152, 242,0.4)';

const background = {
  primary: offWhite2,
  secondary: offWhite3
};

const text = {
  primary: offBlack,
  primary1: '#404041',
  secondary: '#bcbcbc',
  quaternary: '#707070',
  accent: '#ff2824'
};

const navbar = {
  background: background.primary,
  text: text.primary
};

export default {
  alto2,
  border,
  offWhite,
  offWhite2,
  offWhite4,
  offBlack,
  lightBlack,
  whiteOpaque,
  white,
  black,
  orange,
  red,
  red2,
  grey,
  grey2,
  darkGrey,
  greyish,
  hotPink,
  deepPurple,
  pastelBlue,
  blueGreen,
  brightYellow,
  background,
  text,
  navbar,
  offWhite5,
  blackish,
  littleGrey,
  offBlack2,
  persimmon,
  apGrey,
  doveGray,
  polar,
  ghost,
  viking,
  tintedBlack,
  alto,
  mercury,
  lightGreen,
  lightGray,
  emerald,
  mercurySolid,
  silver,
  altoSolid,
  cornFlowerBlue,
  vikings,
  grey3,
  codGray,
  bittersweet,
  oceanGreen,
  boulder,
  zuccini,
  railRight,
  curiousBlue,
  silver2,
  outerSpace,
  codGrays,
  grey4,
  theme,
  theme2,
  themelight
};
