import React, { useEffect } from 'react';
import './auth.scss';
import logo from '../../assets/logo/Logo1.png';
import { Button, Form } from 'antd';
import { Col, Row } from 'antd';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { confirmAccount } from '../../redux/slicers/user';

function ConfirmAccount() {
  const [form] = Form.useForm();
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    const payload = { id: id };
    dispatch(confirmAccount(payload, () => {}));
  }, []);
  return (
    <div className="login-container">
      <Row align={'middle'} className="loginWrapper" justify="center">
        <Col xs={24} lg={13}>
          <div className="loginLogoWrapper">
            <img src={logo} className="logo-login" />
            <div className="title-cont">
              <p style={{ fontWeight: 'bold' }}>Site</p>
              &nbsp;
              <p style={{ fontWeight: 'lighter' }}>Circles</p>
            </div>
            <p className="tagline">
              Your account has successfully been confirmed!
            </p>
          </div>
          <Form className="form-login" form={form}>
            <div className="login-button-wrapper-forget">
              <div className="btn-cont-send-btn">
                <Button
                  size="large"
                  htmlType="submit"
                  className="login-btn"
                  onClick={() => {
                    navigate('/');
                  }}
                >
                  Back to Login
                </Button>
              </div>
            </div>
          </Form>
        </Col>
        <Col xs={24} lg={13}>
          <div className="label-wrapper-forgetpass">
            <Link className="frgt-pass-labels">What is SiteCircles?</Link>
            <Link className="frgt-pass-labels">Contact Us</Link>
            <Link className="frgt-pass-labels">Pricing</Link>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default ConfirmAccount;
