import React, { useEffect, useState } from 'react';
import './styles.scss';
import project from '../../assets/icons/project.png';
import archive from '../../assets/icons/archive.png';
import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
  acceptCircleInviteRequest,
  rejectCircleInviteRequest
} from '../../redux/slicers/projects';
import { Images } from '../../theme';
import CentreModal from '../CentreModal';
import { PrimiumProjectCreditRequest } from '../../redux/slicers/general';
import _ from 'lodash';

function DashboardSidebar({ invitesList, filterOutInvitees, fetchProjects }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [rejectLoading, setRejectLoading] = useState(false);
  const [acceptLoading, setAcceptLoading] = useState(false);
  const [subscriberModal, setSubscriberModal] = useState(() => false);

  const primiumProject = useSelector((state) => state.general.primiumProject);
  const { available } = primiumProject || {};

  useEffect(() => {
    dispatch(PrimiumProjectCreditRequest({}, () => {}));
  }, []);

  const handleReject = (id) => {
    const payload = { circleid: id };
    setRejectLoading(true);
    dispatch(
      rejectCircleInviteRequest({
        payloadData: payload,
        responseCallback: (status) => {
          if (status) {
            filterOutInvitees(id);
          }
          setRejectLoading(false);
        }
      })
    );
  };

  const handleAccept = (id, pid, pname) => {
    const payload = { circleid: id, projectid: pid, projectname: pname };
    setAcceptLoading(true);
    dispatch(
      acceptCircleInviteRequest({
        payloadData: payload,
        responseCallback: (status) => {
          if (status) {
            filterOutInvitees(id);
            fetchProjects();
          }

          setAcceptLoading(false);
        }
      })
    );
  };

  const clickOnNewProject = () => {
    if (_.isEqual(available, 0)) {
      setSubscriberModal(true);
    } else {
      navigate('/new-project');
    }
  };

  return (
    <div className="sidebar-cont">
      <h5>Tools</h5>
      <hr style={{ height: '1px' }} />
      <div
        // onClick={() => {
        //   navigate('/new-project');
        // }}
        onClick={() => clickOnNewProject()}
        className="sidebar-options"
      >
        <img src={project} className="sidebar-logo" />
        <div className="new-project-div">
          <img src={Images.Iconcrown} className="new-project-crowIcon" />
          <p> New Project</p>
        </div>
      </div>
      <div
        onClick={() => {
          navigate('/archives');
        }}
        className="sidebar-options"
      >
        <img src={archive} className="sidebar-logo" />
        <p>Project Archives</p>
      </div>

      <h5 style={{ marginTop: '20px' }}>Invites</h5>
      <hr style={{ height: '1px' }} />
      <div className="invites-cont">
        {invitesList?.length > 0 &&
          invitesList.map((item) => (
            <div className="invites-component" key={item?._id}>
              <p className="invites-info">
                [{moment(item.datetime).format('DD/MM/YYYY HH:mm:ss')}]{' '}
                <b>{item.creator} </b>added you to the <b>{item.circlename}</b>{' '}
                circle in <b>{item.creatorDetails.cname}'s</b> project{' '}
                <b>{item.projectDetails.pname}.</b>
              </p>
              <div className="invites-btn-wrapper">
                <Button
                  className="invites-btn-accept"
                  loading={acceptLoading}
                  onClick={() =>
                    handleAccept(
                      item._id,
                      item.projectDetails._id,
                      item.projectDetails.pname
                    )
                  }
                >
                  Accept
                </Button>
                <Button
                  className="invites-btn-reject"
                  onClick={() => handleReject(item._id)}
                  loading={rejectLoading}
                >
                  Reject
                </Button>
              </div>
            </div>
          ))}
      </div>

      {subscriberModal && (
        <CentreModal
          loading={false}
          onSubmit={() => {
            window.open('https://sitecircles.com', '_blank');
            setSubscriberModal(!subscriberModal);
          }}
          title={'Are you sure you want to clear all notifications? '}
          description={'This will mark all drawings as seen in the access logs'}
          handleClose={() => {
            setSubscriberModal(!subscriberModal);
          }}
        />
      )}
    </div>
  );
}

export default DashboardSidebar;
