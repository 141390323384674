import React, { useEffect } from 'react';
import '../auth.scss';
import logo from '../../../assets/logo/Logo1.png';
import { Button, Col, Row } from 'antd';
import './styles.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { rejectCircleRequest } from '../../../redux/slicers/projects';
import { useDispatch } from 'react-redux';
function RejectCircle() {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      rejectCircleRequest({
        payloadData: params,
        responseCallback: () => {}
      })
    );
  }, []);
  return (
    <div className="login-container">
      <Row align={'middle'} className="loginWrapper" justify="center">
        <Col xs={24} lg={13}>
          <div className="loginLogoWrapper">
            <img src={logo} className="logo-login" />
            <div className="title-cont">
              <p style={{ fontWeight: 'bold' }}>Site</p>
              &nbsp;
              <p style={{ fontWeight: 'lighter' }}>Circles</p>
            </div>
            <p className="tagline">Current, Trackable, Always on Site</p>
          </div>
        </Col>
        <Col xs={24} lg={13} xl={12}>
          <span className="gotologinwrapper">
            <h5>Could not reject your circle invite at this time.</h5>
            <span>
              <Button
                onClick={() => {
                  navigate('/');
                }}
                size="large"
                className="goToLoginbtn"
              >
                Go to Login Page!
              </Button>
            </span>
          </span>
        </Col>
        <Col xs={24} lg={13}>
          <span className="links-wrapper">
            <Button type="link" size="large" className="btn-links">
              What is Sitecircle?
            </Button>
            <Button className="btn-links" type="link" size="large">
              Contact Us
            </Button>
            <Button className="btn-links" type="link" size="large">
              Pricing
            </Button>
          </span>
        </Col>
      </Row>
    </div>
  );
}

export default RejectCircle;
