import React, { useState } from 'react';
import './auth.scss';
import logo from '../../assets/logo/Logo1.png';
import { Button, Form, Input } from 'antd';
import { Col, Row } from 'antd';
import { ALERT_TYPES, LoginFields, SUCCESS_MESSAGES } from '../../constants';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { userPasswordResetRequest } from '../../redux/slicers/user';
import { BeatLoader } from 'react-spinners';
import { toastAlert } from '../../services/utils';

function ForgotPass() {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { email } = LoginFields.fields;
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = () => {
    const values = form.getFieldsValue();
    setIsLoading(true);
    const payload = { email: values.email };
    dispatch(
      userPasswordResetRequest({
        payloadData: payload,
        responseCallback: (status, res) => {
          if (!status) {
            toastAlert(res.msg, ALERT_TYPES.error);
            setIsLoading(false);
          } else {
            setIsLoading(false);
            toastAlert(SUCCESS_MESSAGES.FORGOT_PASS, ALERT_TYPES.success);
          }
        }
      })
    );
  };
  return (
    <div className="login-container">
      <Row align={'middle'} className="loginWrapper" justify="center">
        <Col xs={24} lg={13}>
          <div className="loginLogoWrapper">
            <img src={logo} className="logo-login" />
            <div className="title-cont">
              <p style={{ fontWeight: 'bold' }}>Site</p>
              &nbsp;
              <p style={{ fontWeight: 'lighter' }}>Circles</p>
            </div>
            <p className="tagline">
              Please enter your email address to reset your password.
            </p>
          </div>
          <Form className="form-login" form={form} onFinish={handleSubmit}>
            <Form.Item name={email.title} rules={email.rules}>
              <Input
                type={email.type}
                placeholder={email.label}
                className="login-input"
              />
            </Form.Item>

            <div className="login-button-wrapper-forget">
              <div className="btn-cont-send-btn">
                <Button
                  size="large"
                  htmlType="submit"
                  className="send-pass-btn"
                >
                  {isLoading ? (
                    <BeatLoader size={8} color={'#4ca9a7'} />
                  ) : (
                    'Send Password Reset Email'
                  )}
                </Button>
              </div>
            </div>
          </Form>
        </Col>
        <Col xs={24} lg={13}>
          <div className="label-wrapper-forgetpass">
            <Link className="frgt-pass-labels">What is SiteCircles?</Link>
            <Link className="frgt-pass-labels">Contact Us</Link>
            <Link className="frgt-pass-labels">Pricing</Link>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default ForgotPass;
