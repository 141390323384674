import React, { useEffect } from 'react';
import { Menu, Dropdown } from 'antd';
import { Images } from '../../../theme';
import './styles.scss';
import { delimiterBulkUpload } from '../../../constants';
import {
  bulkUploadFilesDelimiter,
  bulkUploadNextStep
} from '../../../redux/slicers/upload';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
const UploadStep2 = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { bulkUploadFiles } = state?.upload;

  useEffect(() => {
    if (_.isUndefined(bulkUploadFiles[0].delimiter)) {
      dispatch(bulkUploadNextStep(false));
    } else {
      dispatch(bulkUploadNextStep(true));
    }
  }, []);
  return (
    <div>
      <div className="mainDivStep2">
        <p className="contentTxt">
          Choose a <span>delimiter </span> that you have used to
          <span> separate </span>
          information in your <span>drawing names</span>. Drawings should be
          named in the following format: Drawing Number/ Drawing Name/ Drawing
          Revision
        </p>
        <div className="exampleNote">
          <p className="exampleTxt">
            <span>Example:</span>
          </p>
          <p className="exampleTxt">123_Planing_1.pdf</p>
          <p className="exampleTxt">87/Floor Plan/E</p>
        </div>
      </div>
      <div className="selected-delimiter-div">
        <Dropdown
          overlay={
            <Menu
              className="delimiter-menu-item"
              onClick={(item) => {
                const circleIndex = delimiterBulkUpload.findIndex(
                  (x) => x?.name === item?.key
                );
                dispatch(
                  bulkUploadFilesDelimiter({
                    delimiter: {
                      key: delimiterBulkUpload[circleIndex]?.key,
                      name: delimiterBulkUpload[circleIndex]?.name
                    }
                  })
                );
              }}
            >
              {delimiterBulkUpload.map((item) => {
                return (
                  <Menu.Item className="delimiter-item" key={item?.name}>
                    <p>{item?.name}</p>
                    <div className="delimiter-circle-item-show">
                      <p>{item?.keyShow}</p>
                    </div>
                  </Menu.Item>
                );
              })}
            </Menu>
          }
          trigger={['click']}
        >
          <div className="ant-dropdown-link">
            <p className="selected-delimiter-txt">
              {bulkUploadFiles[0]?.delimiter?.name
                ? bulkUploadFiles[0]?.delimiter?.name
                : 'Select a delimiter'}
            </p>
            <img style={{ marginLeft: 10 }} src={Images.DropdownIcon} />
          </div>
        </Dropdown>
      </div>
      <p className="delimiter-txt">
        SiteCircles will use this delimiter to separate your drawing name
        information into the relevant fields
      </p>
    </div>
  );
};

export default UploadStep2;
