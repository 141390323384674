import { Button, Checkbox, Form, Input } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { Loader } from '../../../components';
import {
  ADMINISTRATORS,
  ALERT_TYPES,
  CreateModelFields
} from '../../../constants';
import {
  getDrawingsDetailRequest,
  getDrawingsRequest,
  updateDrawingRequest
} from '../../../redux/slicers/drawing';
import { getCircleNameRequest } from '../../../redux/slicers/projects';
import { toastAlert } from '../../../services/utils';
import './styles.scss';
const UpdateModel = () => {
  const params = useParams();
  const state = useSelector((state) => state);
  const { projects, drawing } = state;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [loader, setloader] = useState(false);
  const [drawingsDetails, setDrawingsDetails] = useState({});

  const selectedDrawing =
    drawing?.projectDrawings?.find((dt) => params?.drawingId === dt?._id) ||
    null;

  const handleLoader = (bool) => {
    setloader(bool);
  };

  const { drawingName, drawingNumber, readAbleAccess } =
    CreateModelFields.fields;

  const handleSubmit = (values) => {
    const idsWithAdmin = projects?.circleNames?.map((dt) => dt?._id);
    const { drawingNumber, drawingName, readAccess } = values;
    const adminId = idsWithAdmin?.filter(
      (dt) =>
        dt ===
          projects?.circleNames?.find((dt) => dt?.circlename === ADMINISTRATORS)
            ?._id ||
        selectedDrawing?.circleid === dt ||
        (readAccess && readAccess.includes(dt))
    );
    const { circleid, followerCircles, dname, dnumber } = selectedDrawing;
    let payload = {
      circleid,
      olddname: dname,
      olddnumber: dnumber,
      oldFollowerCircles: followerCircles,
      dname: drawingName,
      dnumber: drawingNumber,
      followerCircles: [...adminId]
    };

    if (readAccess && readAccess?.length) {
      payload = {
        ...payload,
        followerCircles: [...readAccess, ...payload.followerCircles]
      };
    }

    handleLoader(true);

    dispatch(
      updateDrawingRequest({
        payloadData: {
          data: payload,
          id: params?.id,
          drawingId: params?.drawingId
        },
        responseCallback: (status, res) => {
          handleLoader(false);
          if (status && res) {
            toastAlert(res.msg, ALERT_TYPES.success);
            navigate(-1);
          } else {
            navigate(-1);
            toastAlert(res.msg, ALERT_TYPES.error);
          }
        }
      })
    );
  };

  useEffect(() => {
    dispatch(
      getDrawingsRequest({
        payloadData: params?.id,
        responseCallback: () => {}
      })
    );
    dispatch(
      getCircleNameRequest({
        payloadData: { projectid: params?.id },
        responseCallback: () => {}
      })
    );
    dispatch(
      getDrawingsDetailRequest({
        payloadData: { id: params?.id, drawingId: params?.drawingId },
        responseCallback: (status, res) => {
          if (status && res) {
            setDrawingsDetails(res);
          }
        }
      })
    );
  }, []);

  useEffect(() => {
    // form.setFields
    form.setFieldsValue({
      drawingNumber: selectedDrawing?.dnumber,
      drawingName: selectedDrawing?.dname,
      readAccess: drawingsDetails?.followerCircles
    });
  }, [selectedDrawing, drawingsDetails]);

  const allCircleOptions =
    projects?.circleNames?.map((item) => {
      return {
        label: item?.circlename,
        value: item?._id
      };
    }) || [];

  return (
    <section className="new-cont">
      {!selectedDrawing ? (
        <Loader />
      ) : (
        <div className="new-form">
          <h3 className="draw-head">Model</h3>
          <p className="draw-para">
            Edit the information below to alter the model properties
          </p>
          <Form className="form-create" onFinish={handleSubmit} form={form}>
            <div className="drawing-input-cont">
              <div className="form-side">
                <Form.Item
                  name={drawingNumber.title}
                  rules={drawingNumber.rules}
                >
                  <Input
                    className="create-input"
                    placeholder={drawingNumber.label}
                    type={drawingNumber.type}
                  />
                </Form.Item>
                <span className="mob-view-draw">
                  <Form.Item name={drawingName.title} rules={drawingName.rules}>
                    <Input
                      className="create-input"
                      placeholder={drawingName.label}
                      type={drawingName.type}
                    />
                  </Form.Item>
                </span>
                <p>The circle which owns this model:</p>
                <Input
                  disabled
                  className="circle-select-disabled"
                  placeholder="Select Circle"
                  style={{
                    width: 120,
                    color: '#000',
                    border: '0px',
                    backgroundColor: 'transparent'
                  }}
                  value={selectedDrawing?.circlename}
                />
                <p
                  style={{
                    color: '#3e3f3a',
                    fontSize: '10px',
                    fontStyle: 'italic',
                    fontWeight: 200
                  }}
                >
                  The owning circle cannot be changed. If there is an error,
                  please contact the project administrator to delete the model.
                </p>
              </div>
              <div className="vr"></div>
              <div className="form-side">
                <span className="web-view-drawname">
                  <Form.Item name={drawingName.title} rules={drawingName.rules}>
                    <Input
                      className="create-input"
                      placeholder={drawingName.label}
                      type={drawingName.type}
                    />
                  </Form.Item>
                </span>
                <p>
                  Select the circles which need to follow the activity of the
                  model and have read access.
                </p>
                <Form.Item
                  name={readAbleAccess.title}
                  className="checkbox-wrapper"
                >
                  <Checkbox.Group
                    options={allCircleOptions?.filter(
                      (dt) =>
                        dt?.value !== selectedDrawing?.circleid &&
                        dt.label !== ADMINISTRATORS
                    )}
                    value={drawingsDetails?.followerCircles}
                  />
                </Form.Item>
              </div>
            </div>
            <Button
              className="create-btn-drawing"
              size="large"
              htmlType="submit"
            >
              {loader ? (
                <Loader Type={ClipLoader} size={20} />
              ) : (
                'Save Settings'
              )}
            </Button>
          </Form>
        </div>
      )}
    </section>
  );
};

export default UpdateModel;
