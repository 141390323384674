// @flow
import { createSlice } from '@reduxjs/toolkit';
import Immutable from 'seamless-immutable';
import { cloneDeepItem } from '../../services/utils';

const UserReducer = createSlice({
  name: 'user',
  initialState: Immutable({
    data: {},
    profile: null,
    profileSections: [],
    isAuthenticated: false,
    isError: null
  }),
  reducers: {
    //PASSWORD RESET
    userPasswordResetRequest() {},
    userNewPasswordRequest() {},
    // USER SIGNUP
    userSignUpRequest() {},
    // USER LOGIN
    userLoginRequest() {},
    userLoginSuccess(state, action) {
      //
      if (!action.payload) {
        state.data = null;
        state.isAuthenticated = false;
      } else {
        const userData = {
          access_token: action.payload.token.split(' ')[1],
          data: action.payload.user,
          authenticated: true
        };

        state.data = userData;
        state.isAuthenticated = true;
      }
      // state.user = null
    },
    resendVerification() {},
    confirmAccount() {},
    // REFRESH TOKEN
    refreshToken(state, action) {
      let newData = cloneDeepItem(state.data);
      newData.access_token = action.payload.access_token;
      newData.refresh_token = action.payload.refresh_token;
      Immutable.merge(state, { data: newData });
    },

    // SET AUTH ERROR
    setAuthError(state, action) {
      state.isError = action.payload;
    },

    // REMOVE AUTH ERROR
    removeAuthError(state) {
      state.isError = null;
    },

    // USER SIGNOUT

    // USER SIGNOUT
    userSignOutRequest(state) {
      state.isAuthenticated = false;
      state.data = null;
    },

    userSignOutSuccess(state) {
      state.isAuthenticated = false;
      state.data = null;
    },

    getuserProfileRequest() {},
    getuserProfileSuccess(state, action) {
      state.profile = action.payload;
    }
  }
});

export const {
  getuserProfileRequest,
  getuserProfileSuccess,
  userLoginSuccess,
  userLoginRequest,
  refreshToken,
  setAuthError,
  removeAuthError,
  userSignOutRequest,
  userSignOutSuccess,
  userSignUpRequest,
  userPasswordResetRequest,
  resendVerification,
  userNewPasswordRequest,
  confirmAccount
} = UserReducer.actions;

export default UserReducer.reducer;
