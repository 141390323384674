import { Button, Dropdown, Form, Input, Menu, Space, Upload } from 'antd';
import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import {
  ALERT_TYPES,
  DETAILS_ROUTE,
  drawingRevision,
  revisionStatus
} from '../../../constants';
import {
  addRevisionRequest,
  getDrawingsDetailRequest,
  getDrawingsRequest,
  getUploadUrlsRequest
} from '../../../redux/slicers/drawing';
import { toastAlert } from '../../../services/utils';
import { Images } from '../../../theme';
import './styles.scss';
const UploadModelRevision = () => {
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const params = useParams();
  //states
  const [drawingDetail, setDrawingDetail] = useState(null);
  const [pdfBloFile, setPdfBlobfile] = useState(null);
  const [pdfUploadFile, setPdfUploadFile] = useState(null);
  const [revisionStatusIndex, setRevisionStatusIndex] = useState(0);

  const drawings = useSelector((state) => state.drawing?.projectDrawings);

  console.log({ drawings });

  //states
  const handleLoader = (bool) => {
    setLoader(bool);
  };

  const { notes, revisionNumber } = drawingRevision.fields;

  useEffect(() => {
    dispatch(
      getDrawingsRequest({
        payloadData: params?.id
        // responseCallback: () => {}
      })
    );

    dispatch(
      getDrawingsDetailRequest({
        payloadData: { id: params?.id, drawingId: params?.modelId },
        responseCallback: (status, res) => {
          if (status && res) {
            setDrawingDetail(res);
          }
        }
      })
    );
    handleFileChange();
  }, []);

  const uploadPdfFileToS3 = async () => {
    const formData = new FormData();
    Object.keys(pdfUploadFile?.fields).map((keys) => {
      if (keys !== 'bucket') {
        formData.append(`${keys}`, pdfUploadFile.fields[keys]);
      }
    });
    formData.append('file', pdfBloFile);
    return axios.post(pdfUploadFile?.url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  };

  const apiReqForRevisionSubmit = (values) => {
    const { _id, circlename, dname, dnumber } = drawingDetail;

    const fileName = pdfBloFile?.name?.split('.');
    const extension = fileName[fileName?.length - 1];

    const addRevision = {
      ...values,
      projectid: params?.id,
      drawingid: _id,
      circle: circlename,
      dname,
      dnumber,
      extension: extension,
      status:
        revisionStatus[revisionStatusIndex] === 'No Status'
          ? ''
          : revisionStatus[revisionStatusIndex]
    };

    let fileUploadStartTime = new Date(),
      fileUploadEndTime = new Date();

    const fileUploadDur =
      moment(fileUploadEndTime).diff(fileUploadStartTime, 'milliseconds') /
      1000;
    handleLoader(true);
    let revisionUploadStartTime = new Date(),
      revisionUploadEndTime = new Date();

    addRevisionReqSubmit(
      addRevision,

      () => {
        revisionUploadEndTime = moment();
        const revisionDuration =
          moment(revisionUploadEndTime).diff(
            revisionUploadStartTime,
            'milliseconds'
          ) / 1000;

        return revisionDuration;
      },

      fileUploadStartTime,
      fileUploadDur,
      pdfBloFile,
      { ...values }
    );
  };
  const handleOnSubmit = async (values) => {
    handleLoader(true);

    if (!pdfBloFile) {
      toastAlert('A File must be attached first', ALERT_TYPES.error);
      handleLoader(false);
    } else {
      await uploadPdfFileToS3();
      apiReqForRevisionSubmit(values);
    }
  };

  const addRevisionReqSubmit = (payload) => {
    dispatch(
      addRevisionRequest({
        payloadData: { ...payload },
        responseCallback: (status, res) => {
          handleLoader(false);
          if (status && res) {
            toastAlert(res.msg, ALERT_TYPES.success);
            navigate(DETAILS_ROUTE.replace(':id', params?.id));
          } else if (!status) {
            toastAlert(res.msg, ALERT_TYPES.error);
          }
        }
      })
    );
  };

  const handleFileChange = () => {
    dispatch(
      getUploadUrlsRequest({
        payloadData: { ...params, drawingId: params?.modelId },
        responseCallback: (status, response) => {
          setPdfUploadFile(response?.fileUrl);
        }
      })
    );
  };

  return (
    <section className="new-cont">
      <div className="new-form-upload">
        <h3 className="rev-upload-head">Update Model</h3>
        <p className="rev-upload-para">Upload a new revision for Model</p>
        <Form className="form-upload-rev" onFinish={handleOnSubmit} form={form}>
          <div className="revision-input-cont">
            <div className="form-side-rev">
              <h2 className="info-rev">
                <b>Owner Circle: </b>
                {drawingDetail?.circlename}
              </h2>
              <h2 className="info-rev">
                <b>Model Number: </b>
                {drawingDetail?.dnumber}
              </h2>
              <h2 className="info-rev">
                <b>Model Name: </b>
                {drawingDetail?.dname}
              </h2>
              <h2 className="info-rev">
                <b>Current Revision: </b>

                {drawingDetail?.revisions?.length
                  ? drawingDetail?.revisions[0]?.rnumber
                  : 'No revisions uploaded'}
              </h2>
              <span className="input-rev-wrapper">
                <p className="rev-label">Enter the new revision number</p>
                <Form.Item
                  name={revisionNumber.title}
                  rules={revisionNumber.rules}
                >
                  <Input
                    className="rev-create-input"
                    placeholder={revisionNumber.placeholder}
                    type={revisionNumber.type}
                  />
                </Form.Item>
                <p className="rev-label">
                  Please add notes on the updates made for this revision:
                </p>
                <Form.Item name={notes.title}>
                  <Input
                    className="rev-create-input-notes"
                    placeholder={notes.placeholder}
                    type={notes.type}
                  />
                </Form.Item>
                <p className="rev-label">
                  Please select a status for the model:
                </p>
                <div className="selected-status-div">
                  <Dropdown
                    overlay={
                      <Menu
                        onClick={(item) => {
                          setRevisionStatusIndex(item.key);
                        }}
                      >
                        <Menu.Item key="0">{revisionStatus[0]}</Menu.Item>
                        <Menu.Item key="1">{revisionStatus[1]}</Menu.Item>
                        <Menu.Item key="2">{revisionStatus[2]}</Menu.Item>
                        <Menu.Item key="3">{revisionStatus[3]}</Menu.Item>
                        <Menu.Item key="4">{revisionStatus[4]}</Menu.Item>
                        <Menu.Item key="5">{revisionStatus[5]}</Menu.Item>
                        <Menu.Item key="6">{revisionStatus[6]}</Menu.Item>
                      </Menu>
                    }
                    trigger={['click']}
                  >
                    <div className="ant-dropdown-options">
                      <a
                        className={
                          revisionStatusIndex
                            ? 'options-text'
                            : 'options-text-placeholder'
                        }
                      >
                        {' '}
                        {revisionStatusIndex
                          ? revisionStatus[revisionStatusIndex]
                          : 'No Status'}
                      </a>
                      <img
                        style={{ marginLeft: 10 }}
                        src={Images.DropdownIcon}
                      />
                    </div>
                  </Dropdown>
                </div>

                <div className="upload-file-wrapper">
                  <p>Upload:</p>
                  <Space direction="horizontal" size="large">
                    <Upload
                      accept=".dwg, .ifc, .rvt, .daz, .obj, .skp, .pln, .nwd, .cobie, .bswx"
                      multiple={false}
                      maxCount={1}
                      beforeUpload={() => {
                        return false;
                      }}
                      onChange={(e) => {
                        console.log(e.file.type);
                        if (e.file.status !== 'removed') {
                          setPdfBlobfile(e.file);
                        } else {
                          setPdfBlobfile(null);
                        }
                      }}
                      className="upload-btn"
                    >
                      <img src={Images.model} className="img_upload" />
                    </Upload>

                    {/* <Upload
                      accept=".dwg"
                      multiple={false}
                      maxCount={1}
                      onRemove={() => {
                        return setDwgBlobfile(null);
                      }}
                      beforeUpload={() => {
                        return false;
                      }}
                      onChange={(e) => {
                        if (e?.file?.status !== 'removed') {
                          if (e?.file?.name?.split('.')[1] == 'dwg') {
                            setDwgBlobfile(e.file);
                          } else {
                            setDwgBlobfile(null);
                          }
                        } else {
                          setDwgBlobfile(null);
                        }
                      }}
                      className="upload-btn"
                    >
                      <img src={Images.uploadDWG} className="img_upload" />
                    </Upload> */}
                  </Space>
                </div>
              </span>
              <div className="mainTxtWarring">
                <div className="main-choose-txt2">
                  {
                    'Please note: the upload process is dependant on your file size and internet connection speed. This may take a while. Please do not close this window until the process is completed'
                  }
                </div>
              </div>
            </div>
          </div>
          <Button
            style={{ height: 50 }}
            className="upload-rev-btn"
            size="large"
            htmlType="submit"
          >
            {loader ? <ClipLoader size={25} color="#fff" /> : 'Upload Revision'}
          </Button>
        </Form>
      </div>
    </section>
  );
};

export default UploadModelRevision;
