import React, { useState } from 'react';
import './styles.scss';
import { Dropdown, Menu, Table, Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { ALERT_TYPES } from '../../../constants';
import { Images } from '../../../theme';
import { toastAlert } from '../../../services/utils';
import {
  bulkUploadDrawingsName,
  bulkUploadDrawingsNumber,
  bulkUploadDrawingsRevision,
  bulkUploadNextStep
} from '../../../redux/slicers/upload';

var isColumnsSelected = false;

const UploadStep3 = () => {
  const state = useSelector((state) => state);
  const { bulkUploadFiles } = state?.upload;

  const dispatch = useDispatch();
  const [fileName, setFileName] = useState(() => []);
  const [columnTitle, setColumnTitle] = useState({});
  const [delimiterLength, setDelimiterLength] = useState([]);
  const [selectedColumnsKeys, setSelectedColumnnsKeys] = useState(() => []);
  const step3ChooseOption = [
    {
      name: 'None',
      key: 'none'
    },

    {
      name: 'Drw.Number',
      key: 'DNumber'
    },
    {
      name: 'Drw.Name',
      key: 'DName'
    },
    {
      name: 'Drw.Revision',
      key: 'DRevision'
    }
  ];

  useEffect(() => {
    dispatch(bulkUploadNextStep(false));
    const names = bulkUploadFiles?.map((item) => {
      let obj = {
        fileName: item?.file?.name
      };
      return obj;
    });
    let delimiterLengthArr = [];
    const delimiter = bulkUploadFiles[0]?.delimiter?.key;

    const namesArray = names.map((item) => {
      const nameOnly = [item?.fileName?.split('.')?.slice(0, -1).join('.')];
      const nameing = nameOnly[0]?.split(delimiter);
      delimiterLengthArr.length < nameing.length
        ? (delimiterLengthArr = nameing)
        : delimiterLengthArr;
      let obj = {
        0: nameing[0],
        1: nameing[1],
        2: nameing[2],
        3: nameing[3],
        4: nameing[4],
        5: nameing[5]
      };
      return obj;
    });

    setDelimiter(delimiterLengthArr);
    setFileName(namesArray);
  }, []);

  useEffect(() => {
    const valueObjects = Object.values(columnTitle);
    const tempCheckArray = ['Drw.Name', 'Drw.Number', 'Drw.Revision'];
    if (valueObjects.length >= 3) {
      const hasThreeColumns = valueObjects?.filter((item) => item);
      isColumnsSelected =
        hasThreeColumns?.length === tempCheckArray?.length &&
        hasThreeColumns?.every((el) => tempCheckArray?.includes(el));
      setSelectedColumnnsKeys(hasThreeColumns);
    } else {
      isColumnsSelected = false;
    }
  }, [columnTitle]);

  const setDelimiter = (delimiterLengthArr) => {
    if (delimiterLengthArr.length > 6) {
      const temparray = [1, 2, 3, 4, 5, 6];
      toastAlert(
        `Sitecircles permits the upload of file 
        names with a maximum of 6 file 
        name paramaters (e.g. PARAMETER1_PARMETER2_
        PARAMETER3_PARAMETER4_
        PARAMETER5_PARAMETER6.pdf).   
         You have uploaded a file name with more than 6   
         parameters. We have removed     
         the excessive parameters from some of your files.`,
        ALERT_TYPES.error
      );
      setDelimiterLength(temparray);
    } else {
      setDelimiterLength(delimiterLengthArr);
    }
  };

  const columns1 = delimiterLength?.map((item, index) => {
    const hasSelected = selectedColumnsKeys?.includes(columnTitle[index]);

    return {
      title: (
        <span
          className={
            isColumnsSelected
              ? hasSelected
                ? 'step3-column-title'
                : 'step3-column-title-disable'
              : 'step3-column-title'
          }
        >
          {columnTitle[index] ? columnTitle[index] : `Column ${index + 1}`}
        </span>
      ),

      render: (item) => {
        const tilte = columnTitle[0]?.replace('.', '');

        return (
          <div
            className={
              isColumnsSelected
                ? hasSelected
                  ? 'step3-column-item-div'
                  : 'step3-column-item-div-disable'
                : 'step3-column-item-div'
            }
          >
            <Tooltip
              title={item[index]?.length > 12 ? item[index] : ''}
              placement="bottom"
            >
              <p>{item[tilte] ? item[tilte] : item[index]}</p>
            </Tooltip>
          </div>
        );
      }
    };
  });

  const selectOptionsColumns = (columnName, index) => {
    let tempColumnTitle = { ...columnTitle };
    const hasCheckColumn = Object.values(tempColumnTitle).some(
      (v) => v === columnName
    );
    var mIndex = -1;
    Object.entries(tempColumnTitle).map((v) => {
      if (v[1]?.title === columnName) {
        mIndex = Number(v[0]);
      }
    });
    if (hasCheckColumn) {
      toastAlert(
        `${columnName} already selected at ${mIndex + 1} column`,
        ALERT_TYPES.error
      );
    } else {
      if (columnName === 'Drw.Name') {
        Object.assign(tempColumnTitle, {
          [index]: 'Drw.Name'
        });
        const DnameArray = fileName.map((item) => item[index]);
        dispatch(bulkUploadDrawingsName(DnameArray));
      } else if (columnName === 'Drw.Number') {
        Object.assign(tempColumnTitle, {
          [index]: 'Drw.Number'
        });
        const DnumberArray = fileName.map((item) => item[index]);
        dispatch(bulkUploadDrawingsNumber(DnumberArray));
      } else if (columnName === 'Drw.Revision') {
        Object.assign(tempColumnTitle, {
          [index]: 'Drw.Revision'
        });
        const DrevisionArray = fileName.map((item) => item[index]);
        dispatch(bulkUploadDrawingsRevision(DrevisionArray));
      } else if (columnName === 'None') {
        Object.assign(tempColumnTitle, {
          [index]: ''
        });
        const emptyArray = fileName.map(() => {});
        if (columnTitle[index] === 'Drw.Number') {
          dispatch(bulkUploadDrawingsNumber(emptyArray));
        } else if (columnTitle[index] === 'Drw.Name') {
          dispatch(bulkUploadDrawingsName(emptyArray));
        } else if (columnTitle[index] === 'Drw.Revision') {
          dispatch(bulkUploadDrawingsRevision(emptyArray));
        }
      }
    }
    setColumnTitle(tempColumnTitle);
  };

  const renderCleaningOption = () => {
    return (
      <div className="cleaning-option-naming-div">
        {columns1?.map((_, index) => (
          <div className="cleaning-option-name-main-div" key={index}>
            <div className="step3-dropdown-div">
              <Dropdown
                className="step3-dropdrown"
                overlay={
                  <Menu
                    className="step3-menu-item"
                    onClick={(item) => {
                      selectOptionsColumns(item?.key, index);
                    }}
                  >
                    {step3ChooseOption?.map((item) => {
                      return (
                        <Menu.Item className="step3-item" key={item?.name}>
                          <p>{item?.name}</p>
                        </Menu.Item>
                      );
                    })}
                  </Menu>
                }
                trigger={['click']}
              >
                <div className="ant-dropdown-link">
                  <p className="step3-dropdown-txt">
                    {columnTitle[index]
                      ? columnTitle[index]
                      : 'Choose an option'}
                  </p>
                  <img
                    style={{ marginLeft: 15, width: 10 }}
                    src={Images.DropdownIcon}
                  />
                </div>
              </Dropdown>
            </div>
          </div>
        ))}
      </div>
    );
  };
  return (
    <div>
      <p className="Drawings-information-txt">
        Use the dropdowns below to assign a Drawing Number, Drawing Name, and
        revision to your uploaded file names
      </p>
      {renderCleaningOption()}
      <Table
        columns={columns1}
        dataSource={fileName}
        // rowClassName={'step3-table-row-drawings'}
        className="step3-drawing-table"
        pagination={false}
      />
    </div>
  );
};

export default UploadStep3;
