import React, { useEffect, useState } from 'react';
import '../detailsContent/styles.scss';
import Bell from '../../assets/icons/Bell.png';
import ellipsis from '../../assets/icons/ellipsis.png';
// import comment from '../../assets/icons/comment.png';
import draw_circles from '../../assets/icons/drawing circles.png';
import {
  ALERT_TYPES,
  COMMENTS_ROUTE,
  DRAWING_REVISONS_ROUTE,
  UPDATE_DRAWING_ROUTE,
  UPLOAD_REVISION_ROUTE,
  SHARE_ROUTE,
  sortingDrawing,
  BULK_DOWNLOAD,
  UPLOAD_MODEL_REVISION_ROUTE,
  UPDATE_MODEL_ROUTE
} from '../../constants';
import { Dropdown, Form, Input, Menu, Popover, Table, Tooltip } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  toastAlert,
  getCountFromDrawingsByCircleId,
  getNotificationOnCircleByDrawings
} from '../../services/utils';
import _ from 'lodash';
import {
  clearAllNotificationsDrawings,
  deleteDrawingRequest,
  downloadDrawingRequest,
  getDrawingsRequest,
  sendDrawingAcceessReq
} from '../../redux/slicers/drawing';
import { downloadFile } from '../../helpers.js';
import ConfirmationModal from '../confirmationModal';
import { Images } from '../../theme';
import { BeatLoader, ClipLoader } from 'react-spinners';
import {
  bulkShareDrawingsRequest,
  clearAllNotificationsRequest
} from '../../redux/slicers/projects';
import ErrorModal from '../ErrorModal';
import ShareModal from '../ShareModal';
import NotificationModal from '../NotificationModal';
import Loader from '../loader';
import { bulkUploadReducerEmpty } from '../../redux/slicers/upload';

function DetailsContainer({ allDrawings, handleRemoveNotifications, loading }) {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  // const { projectList } = state?.projects;

  const { projects } = state;
  const { circleNames, circleOptions } = projects;

  // const project = projectList?.find((p) => p._id === params.id);
  const user = state?.user?.data?.data;

  const [selectedDrawing, setSelectedDrawing] = useState({});
  const [circleProjects, setcircleProjects] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [filteredList, setFilteredList] = useState(allDrawings);
  const [isSelectedAllCheckbox, setIsSelectedAllCheckbox] = useState(
    () => false
  );
  const [isModalbulkDownload, setIsModalbulkDownload] = useState(() => false);
  const [isModalbulkShare, setIsModalbulkShare] = useState(() => false);
  const [isModalShare, setIsModalShare] = useState(() => false);
  const [isModalEnterShareEmail, setIsModalEnterShareEmail] = useState(
    () => false
  );
  const [shareEmail, setShareEmail] = useState(() => []);
  const [shareNameDrawings, setShareNameDrawings] = useState(() => []);
  const [sorting, setSortinng] = useState(
    () => sortingDrawing.D_NUMBER_ASCENDING
  );
  const [hasNotification, setHasNotification] = useState(() => false);
  const [drawingsIDs, setDrawingsIDs] = useState(() => []);
  const [isLoadingDownload, setIsLoadingDownload] = useState(() => false);
  const [isLoadingShare, setIsLoadingShare] = useState(() => false);
  const [circleUserOwn, setCircleUserOwn] = useState(() => []);
  const [admindIds, setAdminnIds] = useState(() => []);
  const [open, setopen] = useState({
    title: '',
    desc: '',
    bool: false,
    circleid: '',
    loader: false,
    drawingId: null
  });
  const [requestModal, setRequestModal] = useState({
    title: '',
    desc: '',
    bool: false,
    circleid: '',
    loader: false,
    drawingId: null
  });

  useEffect(() => {}, []);

  useEffect(() => {
    if (allDrawings && allDrawings?.length > 0) {
      const filteredDrawings = allDrawings?.filter(
        (d) =>
          d?.dnumber?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
          d?.dname?.toLowerCase()?.includes(searchText?.toLowerCase())
      );

      setFilteredList(filteredDrawings);
      sortingFunction(sortingDrawing.D_NUMBER_ASCENDING, filteredDrawings);
    } else {
      // setFilteredList([])
    }
  }, [allDrawings]);
  useEffect(() => {
    if (circleNames?.length > 0) {
      let circleNameProject = _.cloneDeep(
        circleNames?.length ? circleNames : []
      );
      setcircleProjects(
        [...circleNameProject]?.map((v) => Object.assign(v, { isOpen: false }))
      );
    }
  }, [circleNames]);
  useEffect(() => {
    let tempArray = [];
    if (!_.isEmpty(circleOptions)) {
      const checlNameCircle = circleNames?.[0]?.circlemembers?.some(
        (item) => item?.userid == user?.id
      );
      if (checlNameCircle) {
        setCircleUserOwn(circleOptions);
      } else {
        circleOptions?.map((item) => {
          const hasUser = item?.circlemembers?.some(
            (item) => item?.userid == user?.id
          );
          if (hasUser) {
            tempArray.push(item);
          }
        });
        setCircleUserOwn(tempArray);
      }
    }

    let adminUserIds = [];
    circleNames?.map((dt) => {
      if (dt.circlename === 'Administrators') {
        adminUserIds = dt.circlemembers?.map((it) => it.userid);
      }
    });
    setAdminnIds(adminUserIds);
  }, [circleOptions]);

  useEffect(() => {
    dispatch(bulkUploadReducerEmpty());
  }, []);

  const openAnTable = (item, index) => {
    let tempdata = [...circleProjects];
    tempdata[index].isOpen = !item.isOpen;
    setcircleProjects(tempdata);
  };

  const handleClose = () => {
    setopen({
      circleid: '',
      bool: false,
      loader: false,
      drawingId: null,
      title: '',
      desc: ''
    });
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
    setFilteredList(
      allDrawings.filter(
        (d) =>
          d?.dnumber
            ?.toLowerCase()
            ?.includes(e?.target?.value?.toLowerCase()) ||
          d?.dname?.toLowerCase()?.includes(e?.target?.value?.toLowerCase())
      )
    );
  };

  const handleRevisions = (id) => {
    let revisionroute = DRAWING_REVISONS_ROUTE.replace(':pid', params?.id);
    revisionroute = revisionroute.replace(':did', id);
    navigate(revisionroute);
  };

  const handleAccessHistory = (id, item) => {
    if (item?._id && !_.isEmpty(item?.revisions)) {
      let allRevisions = [...item?.revisions];

      if (!_.isEmpty(allRevisions)) {
        allRevisions.sort((a, b) => (a.rdatetime > b.rdatetime ? 1 : -1));

        const revision = allRevisions[allRevisions.length - 1];

        navigate(
          `/project/${params.id}/revision-activity/${id}/${revision?.rnumber}`
        );
      } else {
        toastAlert('No Active revision for this drawing.', ALERT_TYPES.info);
      }
    } else {
      toastAlert('No revision for this drawing.', ALERT_TYPES.info);
    }
  };

  const handleShare = (id, item) => {
    if (item?._id && !_.isEmpty(item?.revisions)) {
      let allRevisions = [...item?.revisions];

      if (!_.isEmpty(allRevisions)) {
        allRevisions.sort((a, b) => (a?.rdatetime > b?.rdatetime ? 1 : -1));

        const revision = allRevisions[allRevisions.length - 1];

        navigate(
          SHARE_ROUTE.replace(':pid', params?.id)
            .replace(':did', id)
            .replace(':revisionNo', revision?.rurl)
        );
      } else {
        toastAlert('No Active revision for this drawing.', ALERT_TYPES.info);
      }
    } else {
      toastAlert('No revision for this drawing.', ALERT_TYPES.info);
    }
  };

  const handleUpdateDrawing = (item, id) => {
    if (item?.drawingType === 'MODEL') {
      let route = UPDATE_MODEL_ROUTE.replace(':id', params?.id);
      route = route.replace(':drawingId', id);
      navigate(route);
    } else {
      let route = UPDATE_DRAWING_ROUTE.replace(':id', params?.id);
      route = route.replace(':drawingId', id);
      navigate(route);
    }
  };

  const handleDownloadFile = (drawing, revision, url) => {
    const name = `${drawing?.dnumber}_${drawing?.dname}_${revision?.rnumber}`;
    downloadFile(url, name);
    handleRemoveNotifications(drawing);
  };

  const downloadRevision = (item, fileType) => {
    if (item?._id && !_.isEmpty(item?.revisions)) {
      let allRevisions = [...item?.revisions];
      if (!_.isEmpty(allRevisions)) {
        allRevisions.sort((a, b) => (a.rdatetime > b.rdatetime ? 1 : -1));

        const revision = allRevisions[allRevisions.length - 1];

        const payloadData = {
          data: {
            dName: item.dname,
            dNumber: item?.dnumber,
            rNum: revision.rnumber
          },
          pId: params?.id,
          drawingId: item?._id,
          rurl: revision?.rurl
        };

        dispatch(
          downloadDrawingRequest({
            payloadData,
            responseCallback: (status, res) => {
              if (status) {
                handleDownloadFile(
                  item,
                  revision,
                  item?.drawingType === 'MODEL'
                    ? res?.fileUrl
                    : _.isEqual(fileType, 'PDF')
                    ? res.pdfUrl
                    : res.pdfDwg
                );
              }
            }
          })
        );
      } else {
        toastAlert('No Active revision for this drawing.', ALERT_TYPES.info);
      }
    } else {
      toastAlert('No revision for this drawing.', ALERT_TYPES.info);
    }
  };

  const isDrawingInCircle = (item) => {
    let isValid = false;
    if (!_.isEmpty(circleUserOwn))
      for (let circle of circleUserOwn) {
        if (item?.followerCircles?.includes(circle?._id)) {
          isValid = true;
        }
      }
    return isValid;
    // let isValid = false;
    // circleOptions?.map((circle) => {
    //   if (item.circleid == circle._id) {
    //     isValid = circleNames[0].circlemembers?.some(
    //       (item) => item?.userid == user?.id
    //     )
    //       ? true
    //       : circle?.circlemembers?.some((it) => it?.userid == user?.id);
    //   }
    // });
    // return isValid;
  };

  const checkCircleHasDrawingFollowing = (circleId, allDrawigs) => {
    let isValid = false;
    allDrawigs?.map((item) => {
      if (item.circleid === circleId) {
        for (let circle of circleUserOwn) {
          if (item?.followerCircles?.includes(circle?._id)) {
            isValid = true;
          }
        }
      }
    });
    return isValid;
  };

  const handleSendAccessRequest = () => {
    setRequestModal({ ...requestModal, loader: true });

    const circle = circleOptions?.[0];
    const project = projects?.projectList?.find((p) => p?._id === params.id);
    const payload = {
      cid: circle?._id,
      cname: circle?.circlename,
      dname: selectedDrawing?.dname,
      projectid: project?._id,
      projectname: project?.projectname,
      useremail: state?.user?.data?.data?.email,
      username: state?.user?.data?.data?.fname
    };

    dispatch(
      sendDrawingAcceessReq({
        payloadData: { data: payload },
        responseCallback: () => {
          setRequestModal({
            ...requestModal,
            bool: false,
            loader: false
          });
        }
      })
    );
  };

  const handleOpenRequestModal = (item) => {
    setSelectedDrawing(item);

    setRequestModal({
      desc: `Are you sure you would like to request access to the ${item?.dname} drawing? An email will be sent to the project administrators with your request.`,
      bool: true,
      loader: false,
      drawingId: item?._id,
      title: 'Request Access'
    });
  };

  const columns = (isModel = false) => {
    return [
      {
        render: (item) => {
          const isFollower = isDrawingInCircle(item);
          return (
            <>
              {isFollower && (
                <div className="select-checkBox-all">
                  <input
                    checked={item?.isCheckbox}
                    type="checkbox"
                    onClick={() => clickOnDrawingCheckbox(item)}
                    className="checkBox-style"
                  />
                </div>
              )}
            </>
          );
        },
        responsive: ['sm', 'xs', 'md', 'lg', 'xl', 'xxl']
      },
      {
        title: () => <img src={Bell} className={'bell-icon-column'} />,

        render: (item) => {
          const isFollower = isDrawingInCircle(item);
          return (
            <div style={{ width: 10 }}>
              {item?.notifications > 0 && isFollower && (
                <div className="detail-notification-circle">
                  {/* <p>2</p> */}
                </div>
              )}
            </div>
          );
        },
        responsive: ['sm', 'xs', 'md', 'lg', 'xl', 'xxl']
      },

      {
        title: (
          <div>
            <span className="title-col">
              {isModel ? 'M.Number' : 'D.Number'}
            </span>
            <span className="title">{isModel ? 'M.Number' : 'D.Number'}</span>
          </div>
        ),

        render: (item) => {
          const isFollower = isDrawingInCircle(item);

          return (
            <div
              onClick={() => isFollower && downloadRevision(item, 'PDF')}
              style={{ color: isFollower ? '#000' : '#3e3f3a1a' }}
              className="div-columns-width-80"
            >
              <Tooltip
                title={item?.dnumber.length > 10 ? item?.dnumber : ''}
                placement="bottom"
              >
                <p className="drawings-item-notes-txt">{item?.dnumber}</p>
              </Tooltip>
            </div>
          );
        },
        responsive: ['sm', 'xs', 'md', 'lg', 'xl', 'xxl']
      },
      {
        title: (
          <>
            <span className="title-col"> {isModel ? 'M.Name' : 'D.Name'}</span>
            <span className="title"> {isModel ? 'M.Name' : 'D.Name'}</span>
          </>
        ),

        render: (item) => {
          const isFollower = isDrawingInCircle(item);
          return (
            <div
              onClick={() => isFollower && downloadRevision(item, 'PDF')}
              style={{ color: isFollower ? '#000' : '#3e3f3a1a' }}
              className="div-columns-width-Dname"
            >
              <Tooltip
                title={item?.dname.length > 30 ? item?.dname : ''}
                placement="bottom"
              >
                <p className="drawings-item-dname-txt">{item?.dname}</p>
              </Tooltip>
            </div>
          );
        },
        responsive: ['sm', 'xs', 'md', 'lg', 'xl', 'xxl']
      },
      {
        title: (
          <>
            <span className="title-col">Status</span>
            <span className="title">Status</span>
          </>
        ),
        render: (item) => {
          const isFollower = isDrawingInCircle(item);
          return (
            <div
              onClick={() => isFollower && downloadRevision(item, 'PDF')}
              style={{ color: isFollower ? '#000' : '#3e3f3a1a' }}
              className="div-columns-width-status"
            >
              {item?.status}
            </div>
          );
        },
        responsive: ['sm', 'xs', 'md', 'lg', 'xl', 'xxl']
      },
      {
        title: (
          <>
            <span className="title-col">Notes</span>
            <span className="title">Notes</span>
          </>
        ),
        render: (item) => {
          const isFollower = isDrawingInCircle(item);
          return (
            <div
              onClick={() => isFollower && downloadRevision(item, 'PDF')}
              style={{ color: isFollower ? '#000' : '#3e3f3a1a' }}
              className="drawings-item-notes"
            >
              <Tooltip
                title={item?.notes?.length > 26 ? item?.notes : ''}
                placement="bottom"
              >
                <p className="drawings-item-notes-txt">{item?.notes}</p>
              </Tooltip>
            </div>
          );
        },
        responsive: ['sm', 'xs', 'md', 'lg', 'xl', 'xxl']
      },
      {
        title: (
          <>
            <span className="title-col">Revision</span>
            <span className="title">Revision</span>
          </>
        ),
        // dataIndex: "revisionBy",
        // key: "revisionBy",
        render: (item) => {
          const isFollower = isDrawingInCircle(item);
          return (
            <div
              onClick={() => downloadRevision(item, 'PDF')}
              style={{ color: isFollower ? '#000' : '#3e3f3a1a' }}
              className="div-columns-width-80"
            >
              <Tooltip
                title={item?.revisionBy.length > 10 ? item?.revisionBy : ''}
                placement="bottom"
              >
                <p className="drawings-item-notes-txt">{item?.revisionBy}</p>
              </Tooltip>
            </div>
          );
        },
        responsive: ['sm', 'xs', 'md', 'lg', 'xl', 'xxl']
      },
      {
        title: (
          <>
            <span className="title-col">Last Updated</span>
            <span className="title">Last Updated</span>
          </>
        ),
        // dataIndex: "lastUpdate",
        // key: "lastUpdate",

        render: (item) => {
          const isFollower = isDrawingInCircle(item);
          return (
            <div
              onClick={() => downloadRevision(item, 'PDF')}
              style={{ color: isFollower ? '#000' : '#3e3f3a1a' }}
              className="div-columns-width-80"
            >
              {item?.lastUpdate}
            </div>
          );
        },
        responsive: ['lg', 'xl', 'xxl']
      },
      {
        render: (item) => {
          const isCircleNameInOptions = circleOptions?.find(
            (circle) => circle?._id === item?.circleid
          );

          const isFollower = isDrawingInCircle(item);
          const isAdmin = admindIds.includes(user?.id) ?? false;

          return (
            <div className="options-table">
              {isFollower ? (
                <>
                  <div className="options-table-wrapper">
                    {item?.drawingType === 'MODEL' &&
                      item?.revisions?.length > 0 && (
                        <div className="uploaded_txt">
                          <button
                            className="files-btn"
                            onClick={() => downloadRevision(item, 'PDF')}
                          >
                            <p className={'uploadedTxt'}>
                              Model{' '}
                              <span>
                                {' '}
                                {`[${item?.latestRevision?.[0]?.extension}]`}
                              </span>
                            </p>
                          </button>
                        </div>
                      )}
                    {item?.drawingType !== 'MODEL' && (
                      <>
                        {item?.revisions?.length > 0 && (
                          <div className="uploaded_txt">
                            <button
                              className="files-btn"
                              onClick={() => downloadRevision(item, 'PDF')}
                            >
                              <p className="uploadedTxt">PDF</p>
                            </button>
                          </div>
                        )}
                        {item?.revisions?.length > 0 && (
                          <div className="uploaded_txt">
                            <button
                              disabled={item?.rurlDwg ? false : true}
                              className="files-btn"
                              onClick={() => downloadRevision(item, 'DWG')}
                            >
                              <p
                                className={
                                  item?.rurlDwg
                                    ? 'uploadedTxt'
                                    : 'disable-txt-dwg'
                                }
                              >
                                DWG
                              </p>
                            </button>
                          </div>
                        )}
                      </>
                    )}

                    {isCircleNameInOptions && (
                      <Tooltip title="Update Drawing" color={'#000'}>
                        <img
                          src={Images.UpdateDrawing}
                          className="options-icons"
                          onClick={() => {
                            let revisionRoute =
                              item?.drawingType === 'MODEL'
                                ? UPLOAD_MODEL_REVISION_ROUTE.replace(
                                    ':id',
                                    params?.id
                                  ).replace(':modelId', item?._id)
                                : UPLOAD_REVISION_ROUTE.replace(
                                    ':id',
                                    params?.id
                                  ).replace(':drawingId', item?._id);

                            navigate(revisionRoute);
                            //
                          }}
                        />
                      </Tooltip>
                    )}

                    {/* ////comment */}
                    <Popover
                      className="popover-options"
                      placement="bottomRight"
                      trigger="click"
                      content={
                        item &&
                        item?.followerCircles?.length && (
                          <div className="circles-pop-cont">
                            <div className="circles-pop-item first-pop">
                              <p className="">
                                Circles following this drawing:
                              </p>
                            </div>
                            {circleNames
                              ?.filter((dt) =>
                                item?.followerCircles.includes(dt?._id)
                              )
                              ?.map((follower) => (
                                <div
                                  className="circles-pop-item"
                                  key={follower?.circlename}
                                >
                                  <p className="">{follower?.circlename}</p>
                                </div>
                              ))}
                          </div>
                        )
                      }
                    >
                      <Tooltip title="Following Circles" color={'#000'}>
                        <img src={draw_circles} className="options-icons" />
                      </Tooltip>
                    </Popover>
                    {/* ////commentcommentNotifications */}
                    {item?.revisions?.length > 0 && (
                      <span
                        style={{ display: 'flex', alignItems: 'flex-start' }}
                      >
                        <Tooltip title="Comments" color={'#000'}>
                          <img
                            src={Images.comments}
                            className="options-icons"
                            onClick={() => handleComment(item)}
                          />
                        </Tooltip>
                        {item?.commentNotifications > 0 && (
                          <span
                            style={{
                              borderRadius: '15px',
                              width: 8,
                              height: 8,
                              backgroundColor: '#ec1c24'
                            }}
                          ></span>
                        )}
                      </span>
                    )}
                  </div>

                  {item?.revisions?.length > 0 && (
                    <div className="comment-show-for-mobile">
                      <span
                        style={{ display: 'flex', alignItems: 'flex-start' }}
                      >
                        <Tooltip title="Comments" color={'#000'}>
                          <img
                            src={Images.comments}
                            className="options-icons"
                            onClick={() => handleComment(item)}
                          />
                        </Tooltip>
                        {item?.commentNotifications > 0 && (
                          <span
                            style={{
                              borderRadius: '15px',
                              width: 8,
                              height: 8,
                              backgroundColor: '#ec1c24'
                            }}
                          ></span>
                        )}
                      </span>
                    </div>
                  )}
                  <Popover
                    className="popover-options"
                    placement="bottomRight"
                    trigger="click"
                    content={
                      <div className="options-cont">
                        {item?.revisions?.length > 0 && (
                          <>
                            <span className="web-options">
                              <div
                                className="options-item"
                                onClick={() => handleRevisions(item._id)}
                              >
                                <a className="">Revisions</a>
                              </div>

                              <div
                                className="options-item"
                                onClick={() =>
                                  handleAccessHistory(item._id, item)
                                }
                              >
                                <a className="">Access History</a>
                              </div>

                              <div
                                className="options-item"
                                onClick={() => handleShare(item._id, item)}
                              >
                                <a className="">Share</a>
                              </div>
                              {item?.drawingType !== 'MODEL' &&
                                !item?.rurlDwg && (
                                  <div
                                    className="options-item"
                                    onClick={() => {
                                      let revisionRoute =
                                        UPLOAD_REVISION_ROUTE.replace(
                                          ':id',
                                          params?.id
                                        ).replace(':drawingId', item?._id);

                                      navigate(revisionRoute, {
                                        state: { upload_DWG: true }
                                      });
                                    }}
                                  >
                                    <a className="">Attach DWG</a>
                                  </div>
                                )}
                            </span>

                            <span className="mob-options">
                              {isCircleNameInOptions && (
                                <div
                                  className="options-item"
                                  onClick={() => {
                                    let revisionRoute =
                                      item?.drawingType === 'MODEL'
                                        ? UPLOAD_MODEL_REVISION_ROUTE.replace(
                                            ':id',
                                            params?.id
                                          ).replace(':modelId', item?._id)
                                        : UPLOAD_REVISION_ROUTE.replace(
                                            ':id',
                                            params?.id
                                          ).replace(':drawingId', item?._id);

                                    navigate(revisionRoute);
                                  }}
                                >
                                  <a className="">Update</a>
                                </div>
                              )}
                              <div
                                className="options-item"
                                onClick={() => handleRevisions(item._id)}
                              >
                                <a className="">Revisions</a>
                              </div>

                              <div
                                className="options-item"
                                onClick={() =>
                                  handleAccessHistory(item._id, item)
                                }
                              >
                                <a className="">Access History</a>
                              </div>

                              <div
                                className="options-item"
                                onClick={() => handleShare(item._id, item)}
                              >
                                <a className="">Share</a>
                              </div>

                              {item?.revisions?.length > 0 && (
                                <div
                                  className="options-item"
                                  onClick={() => downloadRevision(item, 'PDF')}
                                >
                                  <a className="">
                                    Download{' '}
                                    {item?.drawingType !== 'MODEL' && 'PDF'}
                                  </a>
                                </div>
                              )}
                              {item?.revisions?.length > 0 && item?.rurlDwg && (
                                <div
                                  className="options-item"
                                  onClick={() => downloadRevision(item, 'DWG')}
                                >
                                  <a className="">Download DWG</a>
                                </div>
                              )}
                              {item?.drawingType !== 'MODEL' &&
                                !item?.rurlDwg && (
                                  <div
                                    className="options-item"
                                    onClick={() => {
                                      let revisionRoute =
                                        UPLOAD_REVISION_ROUTE.replace(
                                          ':id',
                                          params?.id
                                        ).replace(':drawingId', item?._id);

                                      navigate(revisionRoute, {
                                        state: { upload_DWG: true }
                                      });
                                    }}
                                  >
                                    <a className="">Attach DWG</a>
                                  </div>
                                )}
                              {/* {item?.revisions?.length > 0 && (
                              <div
                                className="options-item"
                                onClick={() => handleComment(item)}
                              >
                                <a className="">Comments</a>
                              </div>
                            )} */}
                            </span>
                          </>
                        )}

                        <div
                          className="options-item"
                          onClick={() => handleUpdateDrawing(item, item?._id)}
                        >
                          <a className="">Properties</a>
                        </div>
                        {/* // project?.creatorid === user?.id */}

                        {isAdmin && (
                          <div
                            className="options-item"
                            onClick={() => {
                              setopen({
                                ...open,
                                bool: true,
                                drawingId: item?._id,
                                circleid: item?.circleid,
                                title: 'Delete Drawing!',
                                desc: 'Are you sure you want to delete this Drawing!'
                              });
                            }}
                          >
                            <a className="">Delete</a>
                          </div>
                        )}
                      </div>
                    }
                    // onConfirm={() => handleDelete(record.key)}
                  >
                    <img src={ellipsis} alt="" className="ellipsis" />
                  </Popover>
                </>
              ) : (
                <>
                  <div className="options-table">
                    <img
                      src={Images.Lock}
                      height={25}
                      width={25}
                      onClick={() => handleOpenRequestModal(item)}
                    />
                  </div>
                </>
              )}
            </div>
          );
        }
      }
    ];
  };

  const handleBulkDownload = async () => {
    const drawingFiltered = drawingsIDs?.map((item) => {
      return {
        _id: item?._id,
        dname: item?.dname,
        dnumber: item?.dnumber,
        latestRevision: item?.latestRevision,
        lastUpdate: item?.lastUpdate
      };
    });

    navigate(BULK_DOWNLOAD.replace(':id', params?.id), {
      state: drawingFiltered
    });

    clickOnSelectAllCheckBox(true);
    setIsSelectedAllCheckbox(false);
    setIsLoadingDownload(true);
  };
  const handleBulkShare = () => {
    setIsLoadingShare(true);
    const payload = {
      drawingIds: drawingsIDs?.map((item) => item?._id),
      projectId: params?.id,
      shareAddresses: shareEmail
    };
    dispatch(
      bulkShareDrawingsRequest({
        payloadData: { data: payload },
        responseCallback: (res) => {
          if (res) {
            setIsModalShare(false);
            setIsLoadingShare(false);
            clickOnSelectAllCheckBox(true);
            setIsSelectedAllCheckbox(false);
          }
        }
      })
    );
  };

  const handleClearAllNotificaions = () => {
    const payload = {
      projectId: params?.id
    };
    dispatch(
      clearAllNotificationsRequest({
        payloadData: payload,
        responseCallback: () => {}
      })
    );
    dispatch(clearAllNotificationsDrawings());
    setHasNotification(false);
    clickOnSelectAllCheckBox(true);
  };

  const handleComment = (value) => {
    // if(value.r)
    let commentUrl = COMMENTS_ROUTE.replace(':id', params?.id);
    commentUrl = commentUrl.replace(':drawingId', value._id);
    // commentUrl

    navigate(commentUrl);
  };

  const handleSubmit = () => {
    setopen({ ...open, loader: true });
    dispatch(
      deleteDrawingRequest({
        payloadData: {
          id: params?.id,
          drawingId: open?.drawingId,
          data: { circleid: open.circleid }
        },
        responseCallback: (status, res) => {
          if (status && res) {
            toastAlert(res.msg, ALERT_TYPES.error);
            handleGetDrawing();
          } else if (!status && !res.success) {
            toastAlert(res.msg, ALERT_TYPES.error);
          }
          handleClose();
        }
      })
    );
  };

  const handleGetDrawing = () => {
    dispatch(
      getDrawingsRequest({
        payloadData: params?.id,
        responseCallback: () => {}
      })
    );
  };

  const removeDrawingsIDs = (id) => {
    var index = drawingsIDs.indexOf(id);
    if (index !== -1) {
      drawingsIDs.splice(index, 1);
    }
    setDrawingsIDs(drawingsIDs);
  };

  const clickOnSelectAllCheckBox = (isSelectedAllCheckbox) => {
    setShareNameDrawings([]);
    let tempCircleData = [...circleProjects];
    let tempDrawinngsData = [...filteredList];
    let tempDrawinngsName = [];
    let tempDrawingsID = [];
    tempCircleData?.map((item) =>
      Object.assign(item, { isCheckbox: !isSelectedAllCheckbox })
    );
    tempDrawinngsData?.map((item) => {
      console.log({ itemitem: item });
      if (item?.drawingType !== 'MODEL') {
        Object.assign(item, { isCheckbox: !isSelectedAllCheckbox });
        tempDrawinngsName?.push(
          item?.dnumber + '_' + item?.dname + '_' + item?.revisionBy + '.pdf'
        );
        for (let circle of circleUserOwn) {
          if (item?.followerCircles?.includes(circle?._id)) {
            !isSelectedAllCheckbox && tempDrawingsID.push(item);
          }
        }
      }
    });
    setcircleProjects(tempCircleData);
    setFilteredList(tempDrawinngsData);
    setIsSelectedAllCheckbox(!isSelectedAllCheckbox);
    setShareNameDrawings(tempDrawinngsName);
    setDrawingsIDs(tempDrawingsID);
  };
  const clickOnCircleCheckbox = (circleId) => {
    let isCheckBox;
    setShareNameDrawings([]);
    let tempCircleData = [...circleProjects];
    let tempDrawinngsData = [...filteredList];
    let tempDrawinngsName = [];
    let tempDrawingsIDs = [];
    tempCircleData?.map((item) => {
      if (item?._id === circleId) {
        Object.assign(item, { isCheckbox: !item?.isCheckbox });
        isCheckBox = item?.isCheckbox;
      }
    });
    tempDrawinngsData?.map((item) => {
      if (item.circleid === circleId) {
        Object.assign(item, { isCheckbox: isCheckBox });
        tempDrawinngsName?.push(
          item?.dnumber + '_' + item?.dname + '_' + item?.revisionBy + '.pdf'
        );
        if (isCheckBox) {
          for (let circle of circleUserOwn) {
            if (item?.followerCircles?.includes(circle?._id)) {
              item?.isCheckbox
                ? tempDrawingsIDs.push(item)
                : removeDrawingsIDs(item);
            }
          }
        } else {
          removeDrawingsIDs(item);
        }
      }
    });
    setcircleProjects(tempCircleData);
    setFilteredList(tempDrawinngsData);
    setIsSelectedAllCheckbox(false);
    setShareNameDrawings(tempDrawinngsName);

    const mergeDrawingIds = _.unionBy(drawingsIDs, tempDrawingsIDs, '_id');
    setDrawingsIDs(mergeDrawingIds);
  };
  const clickOnDrawingCheckbox = (selectDrawingItem) => {
    setShareNameDrawings([]);
    setIsSelectedAllCheckbox(false);
    let tempDrawinngsData = [...filteredList];
    let tempDrawinngsName = [];
    let tempDrawingsIDs = [];
    tempDrawinngsData?.map((item) => {
      console.log({ item });
      if (
        item.circleid === selectDrawingItem.circleid &&
        item._id === selectDrawingItem._id
      ) {
        Object.assign(item, { isCheckbox: !item?.isCheckbox });
        tempDrawinngsName?.push(
          item?.dnumber + '_' + item?.dname + '_' + item?.revisionBy + '.pdf'
        );
        item?.isCheckbox ? tempDrawingsIDs.push(item) : removeDrawingsIDs(item);
      }
    });
    setFilteredList(tempDrawinngsData);
    setShareNameDrawings(tempDrawinngsName);
    const mergeDrawingIds = drawingsIDs.concat(tempDrawingsIDs);
    setDrawingsIDs(mergeDrawingIds);
  };

  const clickOnBulkDownload = (isDrawingsSelected) => {
    isDrawingsSelected ? handleBulkDownload() : setIsModalbulkDownload(true);
  };
  const clickOnBulkShare = (isDrawingsSelected) => {
    isDrawingsSelected
      ? setIsModalEnterShareEmail(true)
      : setIsModalbulkShare(true);
  };

  const sortingFunction = (sort, drawingsArray) => {
    let tempDrawinngsData = [...drawingsArray];
    tempDrawinngsData?.sort((a, b) => {
      if (sort === sortingDrawing.LAST_UPDATE_DESCENDING) {
        return b.lastUpdate.localeCompare(a.lastUpdate);
      } else if (sort === sortingDrawing.LAST_UPDATE_ASCENDING) {
        return a.lastUpdate.localeCompare(b.lastUpdate);
      } else if (sort === sortingDrawing.D_NAME_DESCENDING) {
        return b.dname.localeCompare(a.dname);
      } else if (sort === sortingDrawing.D_NAME_ASCENDING) {
        return a.dname.localeCompare(b.dname);
      } else if (sort === sortingDrawing.D_NUMBER_DESCENDING) {
        return b.dnumber.localeCompare(a.dnumber);
      } else if (sort === sortingDrawing.D_NUMBER_ASCENDING) {
        return a.dnumber.localeCompare(b.dnumber);
      }
    });
    setFilteredList(tempDrawinngsData);
  };
  const renderTopDrawings = () => {
    return (
      <div className="content-header-new">
        <div className="all-checkbox-drawings">
          <div className="select-checkBox-all">
            <input
              type="checkbox"
              checked={isSelectedAllCheckbox}
              onClick={() => {
                if (loading) return;
                clickOnSelectAllCheckBox(isSelectedAllCheckbox);
              }}
              className="checkBox-style"
              disabled={loading}
            />
            <p className="checkBox-txt">Select all Drawings</p>
          </div>
        </div>
        <div className="sort-dropdown-div">
          <Dropdown
            className="sorting-dropdrown"
            overlay={
              <Menu
                className="sorting-menu-item"
                onClick={(item) => {
                  setSortinng(item?.key);
                  sortingFunction(item?.key, filteredList);
                }}
              >
                <Menu.Item
                  className="sorting-item"
                  key={sortingDrawing.LAST_UPDATE_DESCENDING}
                >
                  <p>Last Update descending</p>
                </Menu.Item>
                <Menu.Item
                  className="sorting-item"
                  key={sortingDrawing.LAST_UPDATE_ASCENDING}
                >
                  <p>Last Update ascending</p>
                </Menu.Item>
                <Menu.Item
                  className="sorting-item"
                  key={sortingDrawing.D_NAME_DESCENDING}
                >
                  <p>Drw.Name descending</p>
                </Menu.Item>
                <Menu.Item
                  className="sorting-item"
                  key={sortingDrawing.D_NAME_ASCENDING}
                >
                  <p>Drw.Name ascending</p>
                </Menu.Item>
                <Menu.Item
                  className="sorting-item"
                  key={sortingDrawing.D_NUMBER_DESCENDING}
                >
                  <p>Drw.Number descending</p>
                </Menu.Item>
                <Menu.Item
                  className="sorting-item"
                  key={sortingDrawing.D_NUMBER_ASCENDING}
                >
                  <p>Drw.Number ascending</p>
                </Menu.Item>
              </Menu>
            }
            trigger={['click']}
          >
            <div className="ant-dropdown-link">
              <p className="sort-selected-txt">
                {sorting ? sorting : 'Sorting'}
              </p>
              <img style={{ marginLeft: 10 }} src={Images.DropdownIcon} />
            </div>
          </Dropdown>
        </div>
        <div className="header-content">
          <h5>Drawings</h5>
          <div className="search-drawings-div">
            <Form>
              <Form.Item>
                <Input
                  placeholder="Search"
                  className="search-input"
                  onChange={handleSearch}
                  value={searchText}
                />
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    );
  };

  const renderDrawingsTable = () => {
    return (
      <>
        {loading ? (
          <BeatLoader size={20} color="#000" />
        ) : (
          <div className="table-cont">
            {circleProjects?.length > 0 &&
              circleProjects?.map((item, index) => {
                // const isUserFollowCircle = circleNames[0]?.circlemembers?.some(
                //   (item) => item?.userid == user?.id
                // )
                //   ? true
                //   : item?.circlemembers?.some(
                //     (item) => item?.userid == user?.id
                //   );
                const isUserFollowCircle = checkCircleHasDrawingFollowing(
                  item?._id,
                  allDrawings
                );
                const count = getCountFromDrawingsByCircleId(
                  allDrawings,
                  item?._id
                );
                const notificationCount = getNotificationOnCircleByDrawings(
                  allDrawings,
                  item?._id
                );
                return (
                  <>
                    {allDrawings?.find((dt) => dt?.circleid === item?._id) && (
                      <>
                        <div className="circle-label-cont">
                          {isUserFollowCircle && (
                            <div className="select-checkBox-all">
                              <input
                                checked={item?.isCheckbox}
                                type="checkbox"
                                onClick={() => clickOnCircleCheckbox(item?._id)}
                                className="checkBox-style"
                              />
                            </div>
                          )}
                          <div className="name-count-div">
                            <Tooltip
                              title={
                                item?.circlename.length > 30
                                  ? item?.circlename
                                  : ''
                              }
                            >
                              <p
                                className="circle-name-drawings"
                                style={{ marginLeft: 10 }}
                              >
                                {item?.circlename}
                              </p>
                            </Tooltip>
                            {notificationCount[item?._id]?.notifitionCount !==
                              0 && (
                              <div className="count-notification-circle">
                                <p>
                                  {
                                    notificationCount[item?._id]
                                      ?.notifitionCount
                                  }
                                </p>
                              </div>
                            )}
                          </div>
                          <div>
                            <p className="drawings-count-per-circle">
                              {count[item?._id]?.count} Drawings
                            </p>
                          </div>
                          {item?.isOpen ? (
                            <img
                              src={Images.upArrow}
                              type="button"
                              onClick={() => openAnTable(item, index)}
                            />
                          ) : (
                            <img
                              src={Images.downArrow}
                              type="button"
                              onClick={() => openAnTable(item, index)}
                            />
                          )}
                        </div>

                        {item.isOpen && (
                          <>
                            {filteredList?.filter(
                              (dt) =>
                                dt?.circleid === item?._id &&
                                dt?.drawingType !== 'MODEL'
                            )?.length > 0 && (
                              <>
                                <div className="heading">Drawings</div>
                                <Table
                                  dataSource={filteredList?.filter(
                                    (dt) =>
                                      dt?.circleid === item?._id &&
                                      dt?.drawingType !== 'MODEL'
                                  )}
                                  columns={columns()}
                                  rowClassName="table-row"
                                  className="custom-drawing-table"
                                  pagination={false}
                                />
                              </>
                            )}

                            {filteredList?.filter(
                              (dt) =>
                                dt?.circleid === item?._id &&
                                dt?.drawingType === 'MODEL'
                            ).length > 0 && (
                              <>
                                <div className="heading">Models</div>
                                <Table
                                  dataSource={filteredList?.filter(
                                    (dt) =>
                                      dt?.circleid === item?._id &&
                                      dt?.drawingType === 'MODEL'
                                  )}
                                  columns={columns(true)}
                                  rowClassName="table-row"
                                  className="custom-drawing-table"
                                  pagination={false}
                                />
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                );
              })}
          </div>
        )}
      </>
    );
  };

  const renderBottomDiv = () => {
    const isDrawingsSelected = filteredList?.some(
      (item) => item?.isCheckbox == true
    );
    return (
      <div className="bulk-download">
        <div className="bulk-btns-div">
          <button
            className={
              isDrawingsSelected ? 'btn-upload' : 'btn-upload-unselected'
            }
            onClick={() => clickOnBulkDownload(isDrawingsSelected)}
          >
            {isLoadingDownload ? (
              <div className="loader-div">
                <Loader Type={ClipLoader} size={20} />
              </div>
            ) : (
              <>
                <img
                  className="download-img-bulk"
                  src={Images.downloadIcon}
                  height={15}
                  width={15}
                />
                <p className="download-txt-bulk">Bulk Download Drawings</p>
              </>
            )}
          </button>
          <button
            style={{ marginLeft: 20 }}
            className={
              isDrawingsSelected ? 'btn-upload' : 'btn-upload-unselected'
            }
            onClick={() => clickOnBulkShare(isDrawingsSelected)}
          >
            <img
              className="download-img-bulk"
              src={Images.uploadIcon}
              height={15}
              width={15}
            />
            <p className="download-txt-bulk">Bulk Share Drawings</p>
          </button>
        </div>
        <button
          onClick={() => setHasNotification(true)}
          className="btn-upload-clear"
        >
          <p className="txt-clean-all">Clear all notifications</p>
        </button>
      </div>
    );
  };

  return (
    <div className="content-cont">
      {renderTopDrawings()}
      {renderDrawingsTable()}
      {renderBottomDiv()}

      {open.bool && (
        <ConfirmationModal
          loading={open.loader}
          onSubmit={handleSubmit}
          title={open.title}
          description={open.desc}
          handleClose={handleClose}
        />
      )}

      {requestModal.bool && (
        <ConfirmationModal
          loading={requestModal.loader}
          onSubmit={handleSendAccessRequest}
          title={requestModal.title}
          description={requestModal.desc}
          handleClose={() => {
            setRequestModal({ ...requestModal, bool: false, loader: false });
            setSelectedDrawing({});
          }}
        />
      )}
      {isModalbulkDownload && (
        <ErrorModal
          onSubmit={() => setIsModalbulkDownload(false)}
          handleClose={() => setIsModalbulkDownload(false)}
          title={'Please select drawings to download'}
        />
      )}
      {isModalEnterShareEmail && (
        <ShareModal
          onSubmit={() => {
            setIsModalEnterShareEmail(false);
            setIsModalShare(true);
          }}
          handleClose={() => setIsModalEnterShareEmail(false)}
          setShareEmail={setShareEmail}
          title={'Share drawing to:'}
          isModalEnterShareEmail={isModalEnterShareEmail}
        />
      )}
      {isModalShare && (
        <ShareModal
          onSubmit={() => {
            handleBulkShare();
          }}
          shareEmail={shareEmail}
          handleClose={() => setIsModalShare(false)}
          title={'Are you sure you want to share'}
          isModalShare={isModalShare}
          shareNameDrawings={shareNameDrawings}
          loading={isLoadingShare}
        />
      )}

      {isModalbulkShare && (
        <ErrorModal
          onSubmit={() => setIsModalbulkShare(false)}
          handleClose={() => setIsModalbulkShare(false)}
          title={'Please select drawings to share'}
        />
      )}
      {hasNotification && (
        <NotificationModal
          handleClose={() => setHasNotification(false)}
          handleSubmit={() => handleClearAllNotificaions()}
        />
      )}
    </div>
  );
}

export default DetailsContainer;
