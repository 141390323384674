import { Modal } from 'antd';
import React from 'react';
import { Images } from '../../theme';
import './styles.scss';
export default function NotificationModal(props) {
  const { handleClose, handleSubmit } = props;
  return (
    <Modal
      visible={true}
      centered
      onCancel={handleClose}
      footer={null}
      closeIcon={
        <img className="cross-Icon-share" src={Images.crossIcon} alt="" />
      }
      width={600}
      className="certer-modal"
    >
      <div className="notification-wrapper-div">
        <div className="modal-title-div">
          <p className="modal-title">
            Are you sure you want to clear all notifications?
          </p>
        </div>
        <p className="notification-dis">
          This will mark all drawings as seen in the access logs
        </p>
        <div className="bottom-btn-notification">
          <div
            className="cancel-btn-notification-center-modal"
            onClick={handleSubmit}
          >
            <p>{'Cancel'}</p>
          </div>
          <div
            className="confirm-btn-notification-center-modal"
            onClick={handleSubmit}
          >
            <p>{'Confirm'}</p>
          </div>
        </div>
      </div>
    </Modal>
  );
}
