import _ from 'lodash';
import * as pdfMake from 'pdfmake/build/pdfmake.js';
import * as pdfFonts from 'pdfmake/build/vfs_fonts.js';
import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import circle from '../../assets/icons/circle.png';
import draw_reg from '../../assets/icons/Drawing Register.svg';
import history from '../../assets/icons/history.png';
import draw from '../../assets/icons/new dwg.png';
import sharing from '../../assets/icons/sharing.png';
import {
  ACTIVITY_ROUTE,
  ALERT_TYPES,
  BULK_UPLOAD_ROUTE,
  NEW_DRAWING_ROUTE,
  NEW_MODEL_ROUTE,
  PROJECT_CIRCLE_ROUTE,
  SHARING_PERMISSIONS_ROUTE
} from '../../constants';
import { toastAlert } from '../../services/utils';
import './styles.scss';

import { useEffect, useState } from 'react';
import {
  POPPINS_BOLD,
  POPPINS_BOLD_ITALIC,
  POPPINS_ITALIC,
  POPPINS_REGULAR
} from '../../constants';
import { getProjectRequest } from '../../redux/slicers/projects';
import { Images } from '../../theme';
// import { CSVLink } from 'react-csv';
import { downnloadDrawingsRegisterRequest } from '../../redux/slicers/drawing';

pdfMake.vfs = pdfFonts.pdfMake.vfs; // eslint-disable-line

function DetailsSidebar() {
  window.pdfMake.vfs['Poppins-Regular.ttf'] = POPPINS_REGULAR;
  window.pdfMake.vfs['Poppins-Bold.ttf'] = POPPINS_BOLD;
  window.pdfMake.vfs['Poppins-Italic.ttf'] = POPPINS_ITALIC;
  window.pdfMake.vfs['Poppins-BoldItalic.ttf'] = POPPINS_BOLD_ITALIC;
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isModalDownload, setIsModalDownload] = useState(false);
  const circleNames = useSelector((state) => state?.projects?.circleNames);
  const [width, setWidth] = useState(window.innerWidth);
  const downloadRegisterRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        downloadRegisterRef.current &&
        !downloadRegisterRef.current.contains(event.target)
      ) {
        setIsModalDownload(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
  }, [downloadRegisterRef]);

  const projectList =
    useSelector((state) => state?.projects?.projectList) || [];

  const project =
    (projectList && projectList?.find((p) => p?._id === params?.id)) || {};

  useEffect(() => {
    if (_.isEmpty(project)) {
      dispatch(getProjectRequest({}, () => {}));
    }
  }, [projectList]);

  const handleRoute = (url) => {
    navigate(url);
  };

  const updateDimensions = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  const clickOnPFD = (type) => {
    const payload = {
      id: params.id,
      type: type
    };
    setIsModalDownload(!isModalDownload);
    dispatch(
      downnloadDrawingsRegisterRequest({
        payloadData: payload,
        responseCallback: (res) => {
          if (res) {
            setIsModalDownload(false);
          }
        }
      })
    );
  };

  return (
    <div className="sidebar-cont projectDetailSidebar">
      <h5>Tools</h5>
      <hr style={{ height: '1px' }} />
      <div
        onClick={(e) => {
          e.preventDefault();
          if (!_.isEmpty(circleNames) && circleNames?.length > 1)
            navigate(NEW_DRAWING_ROUTE.replace(':id', params.id));
          else toastAlert('Add Circle before creating new Drawing', 'error');
        }}
        className="sidebar-options"
      >
        <img src={draw} className="sidebar-logo" />
        <p> New Drawing</p>
      </div>

      <div
        onClick={(e) => {
          e.preventDefault();
          if (!_.isEmpty(circleNames) && circleNames?.length > 1)
            navigate(NEW_MODEL_ROUTE.replace(':id', params.id));
          else toastAlert('Add Circle before creating new Model', 'error');
        }}
        className="sidebar-options"
      >
        <img src={Images.modelGroup} className="sidebar-logo" />
        <p> New Model</p>
      </div>

      <div
        onClick={() => {
          handleRoute(PROJECT_CIRCLE_ROUTE.replace(':id', params?.id));
        }}
        className="sidebar-options"
      >
        <img src={circle} className="sidebar-logo" />
        <p>Project Circles</p>
      </div>
      <div
        onClick={(e) => {
          e.preventDefault();
          navigate(ACTIVITY_ROUTE.replace(':id', params.id));
        }}
        className="sidebar-options"
      >
        <img src={history} className="sidebar-logo" />
        <p>Project Activity</p>
      </div>
      <div className="sidebar-options-div">
        <div
          onClick={() => {
            setIsModalDownload(!isModalDownload);
          }}
          className="sidebar-options"
        >
          <img src={draw_reg} className="sidebar-logo" />
          <p className="resp-draw-reg">Download Drawing Register</p>
          <p className="resp-draw-reg-2">Drawing Register</p>
        </div>
        {isModalDownload && (
          <div
            ref={downloadRegisterRef}
            className="download-register-modal-div"
          >
            <p>Download Drawing Register as:</p>
            <div className="download-register-pdf-cvs">
              <img
                src={Images.uploadPDF}
                className="image-pfd-csv"
                onClick={() => {
                  setIsModalDownload(!isModalDownload);
                  // createPdf();
                  clickOnPFD('pdf');
                }}
              />

              <div className="line-between-files" />
              <img
                src={Images.csvIcon}
                className="image-pfd-csv"
                onClick={() => {
                  setIsModalDownload(!isModalDownload);
                  clickOnPFD('csv');
                }}
              />
              {/* {createCSV()} */}
            </div>
          </div>
        )}
      </div>

      <div
        onClick={() => {
          handleRoute(SHARING_PERMISSIONS_ROUTE.replace(':id', params?.id));
        }}
        className="sidebar-options"
      >
        <img src={sharing} className="sidebar-logo" />
        <p>Sharing Permissions</p>
      </div>

      <div
        onClick={() => {
          if (width > 960) {
            handleRoute(BULK_UPLOAD_ROUTE.replace(':id', params?.id));
          } else {
            toastAlert(
              'This feature is only available on Desktop Please use a desktop PC ',
              ALERT_TYPES.error
            );
          }
        }}
        className="sidebar-options"
      >
        <img src={Images.bulkUploadIcon} className="sidebar-logo-bulk-upload" />
        <p>Bulk Upload</p>
      </div>
    </div>
  );
}

export default DetailsSidebar;
