import moment from 'moment';
import React from 'react';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { Loader } from '../../../components';
import { DETAILS_ROUTE } from '../../../constants';

import { bulkDownloadDrawingsRequest } from '../../../redux/slicers/projects';
import { Images } from '../../../theme';
import './styles.scss';
import { removeNotificationByDrawingsIds } from '../../../redux/slicers/drawing';
import _ from 'lodash';

const BulkDownload = () => {
  const currentState = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [selectedDrawings, setSelectedDrawings] = useState([]);

  console.log({ selectedDrawings });
  const [isLoading, setIsLoadingDownload] = useState(false);
  useEffect(() => {
    const data = _.cloneDeep(currentState?.state);

    if (data?.length > 0) {
      const obj = {};
      for (let drawing of data) {
        obj[drawing?._id] = {
          ...drawing
        };
      }

      const uniqueData = [...Object.values(obj)];

      setSelectedDrawings(uniqueData);
    } else {
      setSelectedDrawings([]);
    }

    // const tempArray = _.cloneDeep(currentState.state);
    // const uniqueArray = tempArray.filter((value, index) => {
    //   const _value = JSON.stringify(value);
    //   return (
    //     index ===
    //     tempArray.findIndex((obj) => {
    //       return JSON.stringify(obj) === _value;
    //     })
    //   );
    // });

    // setSelectedDrawings([...currentState?.state]);
  }, [currentState]);

  const handleBulkDownload = () => {
    setIsLoadingDownload(true);
    const payload = {
      drawingIds: selectedDrawings.map((item) => item._id),
      projectId: params.id
    };
    dispatch(
      bulkDownloadDrawingsRequest({
        payloadData: { data: payload },
        responseCallback: () => {
          setIsLoadingDownload(false);
          navigate(DETAILS_ROUTE.replace(':id', params?.id));
        }
      })
    );
    removeDrawingsNotification();
  };
  const removeDrawingsNotification = () => {
    dispatch(removeNotificationByDrawingsIds(selectedDrawings));
  };
  const removeItems = (item) => {
    let tempArr = [...selectedDrawings];
    var index = tempArr.indexOf(item);
    if (index !== -1) {
      tempArr.splice(index, 1);
    }
    setSelectedDrawings(tempArr);
  };
  const renderBulkDownload = () => {
    return (
      <div className="main-div-bulk">
        <p className="txt1">Bulk Download</p>
        <p className="bulk-download-txt2">
          SiteCircles will send you an email shortly with a link to download
          your selected drawings.
        </p>
        <p className="bulk-download-txt2">
          Please check your mailbox for the download link. Download link expires
          in 7 days.
        </p>
        <div className="bulk-download-mainDiv">
          <p className="bulk-download-following-txt">
            You are about to download the following{' '}
            <span>{selectedDrawings?.length} drawings .</span>{' '}
          </p>

          <p className="bulk-download-txt3">
            Please note: it can take up to 30 minutes to receive your bulk
            download link via email, depending on the amount of files in your
            download.
          </p>

          {renderSelectedDrawings()}
          {renderLinkButton()}
        </div>
      </div>
    );
  };

  const renderDrawingItems = (item) => {
    const lastRevision = item?.latestRevision[0]?.rnumber;
    const rDate = item?.latestRevision[0]?.rdatetime;
    return (
      <div className="bulk-download-drawings-items">
        <p style={{ width: 150, fontSize: 12 }}> {item.dnumber}</p>
        <p style={{ width: 250, fontSize: 12 }}>{item.dname}</p>
        <p style={{ width: 100, fontSize: 12 }}>{lastRevision}</p>
        <p style={{ width: 100, fontSize: 12 }}>
          {moment(rDate).format('DD-MM-YYYY')}
        </p>
        <div className="item-img-div" onClick={() => removeItems(item)}>
          <img style={{ width: 10, height: 10 }} src={Images.crossIconRed} />
        </div>
      </div>
    );
  };

  const renderSelectedDrawings = () => {
    return (
      <div className="mainn-bulk-donwload-div">
        <div className="bulk-download-row-table-header">
          <p className="BS_item_DNumber_PC">Drawing Number</p>
          <p className="BS_item_DNumber_MO">D.Number</p>
          <p className="BS_item_DName_PC">Drawing Name</p>
          <p className="BS_item_DName_MO">D.Name</p>
          <p style={{ width: 100 }}>Revision</p>
          <p className="BS_item_LUPDATE_PC">Last Update</p>
          <p className="BS_item_LUPDATE_MO">L.Update</p>
          <p style={{ width: 100, textAlign: 'center' }}>Remove</p>
        </div>

        <div className="bulk-download-inner-div">
          {selectedDrawings?.map((item) => renderDrawingItems(item))}
        </div>
      </div>
    );
  };

  const renderLinkButton = () => {
    return (
      <button
        disabled={selectedDrawings?.length == 0}
        onClick={() => handleBulkDownload()}
        className={
          selectedDrawings?.length == 0
            ? 'bulk-download-btn-disable'
            : 'bulk-download-button-link'
        }
      >
        <p>
          {isLoading ? (
            <Loader Type={ClipLoader} size={20} />
          ) : (
            ' Email downloadable link'
          )}
        </p>
      </button>
    );
  };
  return <section className="new-cont">{renderBulkDownload()}</section>;
};

export default BulkDownload;
