import React from 'react';
import { Modal } from 'antd';
import './styles.scss';
import Loader from '../loader';
import { ClipLoader } from 'react-spinners';
import { Images } from '../../theme';
const CentreModal = ({
  handleClose,
  onSubmit,

  loading = false
}) => {
  const handleSubmit = () => {
    onSubmit();
  };

  return (
    <Modal
      visible={true}
      centered
      onCancel={handleClose}
      footer={null}
      closeIcon={<img src={Images.crossIcon} alt="" />}
      width={600}
      className="certer-modal"
    >
      <div className="modal-wrapper-div">
        <div className="iconCrow-div">
          <img src={Images.Iconcrown} />
        </div>
        <div className="modal-title-div">
          <p className="modal-title">
            Project Creation is only available for SiteCircles premium
            subscribers.
          </p>
        </div>
        <div className="modal-body-div">
          <p className="modal-confirmation-des">
            Not yet a premium subscriber?
          </p>
        </div>

        <div className="signup-btn-center-modal" onClick={handleSubmit}>
          <p>
            {loading ? (
              <Loader Type={ClipLoader} size={20} />
            ) : (
              'Purchase Credits'
            )}
          </p>
        </div>
      </div>
    </Modal>
  );
};

export default CentreModal;
