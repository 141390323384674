import {
  Button,
  Dropdown,
  Form,
  Input,
  Menu,
  Space,
  Tooltip,
  Upload
} from 'antd';
import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import {
  ALERT_TYPES,
  DETAILS_ROUTE,
  drawingRevision,
  revisionStatus
} from '../../../constants';
import {
  addRevisionRequest,
  getDrawingsDetailRequest,
  getDrawingsRequest,
  getUploadUrlsRequest,
  uploadDWGRevisionRequest
} from '../../../redux/slicers/drawing';
import { toastAlert } from '../../../services/utils';
import { Images } from '../../../theme';
import './styles.scss';
import _ from 'lodash';
const UploadRevision = () => {
  const [loader, setLoader] = useState(false);
  const currentState = useLocation();
  const upload_DWG = _.cloneDeep(currentState?.state?.upload_DWG);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const params = useParams();
  //states
  const [drawingDetail, setDrawingDetail] = useState(null);
  const [pdfBloFile, setPdfBlobfile] = useState(null);
  const [dwgBloFile, setDwgBlobfile] = useState(null);
  const [pdfUploadFile, setPdfUploadFile] = useState(null);
  const [dwgUploadFile, setDwgUploadFile] = useState(null);
  const [revisionStatusIndex, setRevisionStatusIndex] = useState(0);
  const [uploadedRevision, setUploadRevision] = useState(0);
  const [uploadedNotes, setUploadedNotes] = useState('');
  const [uploadedStatus, setUploadedStatus] = useState('');

  const drawings = useSelector((state) => state.drawing?.projectDrawings);
  console.log(drawings);
  //states
  const handleLoader = (bool) => {
    setLoader(bool);
  };

  const { notes, revisionNumber } = drawingRevision.fields;

  useEffect(() => {
    dispatch(
      getDrawingsRequest({
        payloadData: params?.id
        // responseCallback: () => {}
      })
    );

    dispatch(
      getDrawingsDetailRequest({
        payloadData: { id: params?.id, drawingId: params?.drawingId },
        responseCallback: (status, res) => {
          if (status && res) {
            setDrawingDetail(res);
            setUploadRevision(
              res?.revisions[res?.revisions?.length - 1]?.rnumber
            );
            setUploadedNotes(res?.revisions[res?.revisions?.length - 1]?.notes);
            setUploadedStatus(
              res?.revisions[res?.revisions?.length - 1]?.status
            );
          }
        }
      })
    );
    handleFileChange();
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      notes: upload_DWG ? uploadedNotes : '',
      rnumber: upload_DWG ? uploadedRevision : ''
    });
  }, [drawingDetail, uploadedRevision, uploadedNotes]);

  const uploadPdfFileToS3 = async () => {
    const formData = new FormData();
    Object.keys(pdfUploadFile?.fields).map((keys) => {
      if (keys !== 'bucket') {
        formData.append(`${keys}`, pdfUploadFile.fields[keys]);
      }
    });
    formData.append('file', pdfBloFile);
    return axios.post(pdfUploadFile?.url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  };
  const uploadDwfFileToS3 = async () => {
    const formData = new FormData();
    Object.keys(dwgUploadFile?.fields).map((keys) => {
      if (keys !== 'bucket') {
        formData.append(`${keys}`, dwgUploadFile.fields[keys]);
      }
    });
    formData.append('file', dwgBloFile);
    return axios.post(dwgUploadFile?.url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  };
  const apiReqForRevisionSubmit = (values) => {
    const { _id, circlename, dname, dnumber } = drawingDetail;

    const addRevision = {
      ...values,
      projectid: params?.id,
      drawingid: _id,
      circle: circlename,
      dname,
      dnumber,
      status:
        revisionStatus[revisionStatusIndex] === 'No Status'
          ? ''
          : revisionStatus[revisionStatusIndex],
      hasDWG: dwgBloFile ? true : false
    };

    let fileUploadStartTime = new Date(),
      fileUploadEndTime = new Date();

    const fileUploadDur =
      moment(fileUploadEndTime).diff(fileUploadStartTime, 'milliseconds') /
      1000;
    handleLoader(true);
    let revisionUploadStartTime = new Date(),
      revisionUploadEndTime = new Date();
    addRevisionReqSubmit(
      addRevision,
      () => {
        revisionUploadEndTime = moment();
        const revisionDuration =
          moment(revisionUploadEndTime).diff(
            revisionUploadStartTime,
            'milliseconds'
          ) / 1000;

        return revisionDuration;
      },
      fileUploadStartTime,
      fileUploadDur,
      pdfBloFile,
      { ...values }
    );
  };
  const handleOnSubmit = async (values) => {
    handleLoader(true);

    if (upload_DWG) {
      if (!dwgBloFile && upload_DWG) {
        toastAlert('A DWG must be attached first', ALERT_TYPES.error);
        handleLoader(false);
      } else {
        await uploadDwfFileToS3();

        dispatch(
          uploadDWGRevisionRequest({
            payloadData: {
              drawingid: drawingDetail._id
            },
            responseCallback: (res) => {
              handleLoader(false);
              if (res) {
                navigate(DETAILS_ROUTE.replace(':id', params?.id));
              }
            }
          })
        );
      }
    } else {
      if (dwgBloFile) {
        await uploadDwfFileToS3();
      }
      if (!pdfBloFile) {
        toastAlert('A PDF must be attached first', ALERT_TYPES.error);
        handleLoader(false);
      } else {
        await uploadPdfFileToS3();
        apiReqForRevisionSubmit(values);
      }
    }
  };

  const addRevisionReqSubmit = (payload) => {
    dispatch(
      addRevisionRequest({
        payloadData: { ...payload },
        responseCallback: (status, res) => {
          handleLoader(false);
          if (status && res) {
            toastAlert(res.msg, ALERT_TYPES.success);
            navigate(DETAILS_ROUTE.replace(':id', params?.id));
          } else if (!status) {
            toastAlert(res.msg, ALERT_TYPES.error);
          }
        }
      })
    );
  };

  const handleFileChange = () => {
    dispatch(
      getUploadUrlsRequest({
        payloadData: params,
        responseCallback: (status, response) => {
          setDwgUploadFile(response?.dwg);
          setPdfUploadFile(response?.pdf);
        }
      })
    );
  };

  return (
    <section className="new-cont">
      <div className="new-form-upload">
        <h3 className="rev-upload-head">
          {' '}
          {upload_DWG
            ? 'Attach DWG to existing Revision'
            : 'Update Drawing'}{' '}
        </h3>
        <p className="rev-upload-para">
          {upload_DWG
            ? 'You are attaching a .DWG file to an existing revision that currently only has a .PDF file'
            : 'Upload a new revision for Drawing'}
        </p>
        <Form className="form-upload-rev" onFinish={handleOnSubmit} form={form}>
          <div className="revision-input-cont">
            <div className="form-side-rev">
              <h2 className="info-rev">
                <b>Owner Circle: </b>
                {drawingDetail?.circlename}
              </h2>
              <h2 className="info-rev">
                <b>Drawing Number: </b>
                {drawingDetail?.dnumber}
              </h2>
              <h2 className="info-rev">
                <b>Drawing Name: </b>
                {drawingDetail?.dname}
              </h2>
              <h2 className="info-rev">
                <b>Current Revision: </b>

                {drawingDetail?.revisions?.length
                  ? drawingDetail?.revisions[0]?.rnumber
                  : 'No revisions uploaded'}
              </h2>
              <span className="input-rev-wrapper">
                <p className="rev-label">Enter the new revision number</p>
                <Tooltip
                  title={
                    upload_DWG
                      ? 'You have already uploaded a PDF, you cannot change the revision information here. Simply attach a DWG to your current revision'
                      : ''
                  }
                  color={'#000'}
                >
                  <Form.Item
                    name={revisionNumber.title}
                    rules={revisionNumber.rules}
                  >
                    <Input
                      className="rev-create-input"
                      placeholder={revisionNumber.placeholder}
                      type={revisionNumber.type}
                      disabled={upload_DWG}
                      id="rnumber"
                    />
                  </Form.Item>
                </Tooltip>
                <p className="rev-label">
                  Please add notes on the updates made for this revision:
                </p>
                <Tooltip
                  title={
                    upload_DWG
                      ? 'You have already uploaded a PDF, you cannot change the revision information here. Simply attach a DWG to your current revision'
                      : ''
                  }
                  color={'#000'}
                >
                  <Form.Item name={notes.title}>
                    <Input
                      className="rev-create-input-notes"
                      placeholder={notes.placeholder}
                      type={notes.type}
                      id="notes"
                      disabled={upload_DWG}
                    />
                  </Form.Item>
                </Tooltip>
                <p className="rev-label">
                  Please select a status for the drawing:
                </p>
                <Tooltip
                  title={
                    upload_DWG
                      ? 'You have already uploaded a PDF, you cannot change the revision information here. Simply attach a DWG to your current revision'
                      : ''
                  }
                  color={'#000'}
                >
                  <div
                    className={
                      upload_DWG
                        ? 'selected-status-div-disable'
                        : 'selected-status-div'
                    }
                  >
                    <Dropdown
                      disabled={upload_DWG}
                      overlay={
                        <Menu
                          onClick={(item) => {
                            setRevisionStatusIndex(item.key);
                          }}
                        >
                          <Menu.Item key="0">{revisionStatus[0]}</Menu.Item>
                          <Menu.Item key="1">{revisionStatus[1]}</Menu.Item>
                          <Menu.Item key="2">{revisionStatus[2]}</Menu.Item>
                          <Menu.Item key="3">{revisionStatus[3]}</Menu.Item>
                          <Menu.Item key="4">{revisionStatus[4]}</Menu.Item>
                          <Menu.Item key="5">{revisionStatus[5]}</Menu.Item>
                          <Menu.Item key="6">{revisionStatus[6]}</Menu.Item>
                        </Menu>
                      }
                      trigger={['click']}
                    >
                      <div className="ant-dropdown-options">
                        <a
                          className={
                            revisionStatusIndex
                              ? 'options-text'
                              : 'options-text-placeholder'
                          }
                        >
                          {revisionStatusIndex
                            ? revisionStatus[revisionStatusIndex]
                            : upload_DWG
                            ? uploadedStatus
                            : 'No Status'}
                        </a>
                        <img
                          style={{ marginLeft: 10 }}
                          src={Images.DropdownIcon}
                        />
                      </div>
                    </Dropdown>
                  </div>
                </Tooltip>
                <div className="upload-file-wrapper">
                  <p>Upload:</p>
                  <Space direction="horizontal" size="large">
                    <Tooltip
                      title={
                        upload_DWG
                          ? 'You have already uploaded a PDF, you cannot change the revision information here. Simply attach a DWG to your current revision'
                          : ''
                      }
                      color={'#000'}
                    >
                      <div
                        style={
                          upload_DWG
                            ? {
                                backgroundColor: '#f5f5f5',
                                height: 80,
                                width: 85,
                                borderRadius: 15
                              }
                            : {}
                        }
                      >
                        <Upload
                          accept=".pdf"
                          multiple={false}
                          maxCount={1}
                          disabled={upload_DWG}
                          beforeUpload={() => {
                            return false;
                          }}
                          onChange={(e) => {
                            if (e.file.type === 'application/pdf') {
                              setPdfBlobfile(e.file);
                            } else {
                              setPdfBlobfile(null);
                            }
                          }}
                          className="upload-btn"
                        >
                          <img src={Images.uploadPDF} className="img_upload" />
                        </Upload>
                      </div>
                    </Tooltip>
                    <Upload
                      accept=".dwg"
                      multiple={false}
                      maxCount={1}
                      onRemove={() => {
                        return setDwgBlobfile(null);
                      }}
                      beforeUpload={() => {
                        return false;
                      }}
                      onChange={(e) => {
                        if (e?.file?.status !== 'removed') {
                          const fileSplit = e?.file?.name?.split('.');
                          if (fileSplit[fileSplit.length - 1] == 'dwg') {
                            setDwgBlobfile(e.file);
                          } else {
                            setDwgBlobfile(null);
                          }
                        } else {
                          setDwgBlobfile(null);
                        }
                      }}
                      className="upload-btn"
                    >
                      <img src={Images.uploadDWG} className="img_upload" />
                    </Upload>
                  </Space>
                </div>
              </span>
            </div>
          </div>

          <Button
            style={{ height: 50 }}
            className="upload-rev-btn"
            size="large"
            htmlType="submit"
          >
            {loader ? <ClipLoader size={25} color="#fff" /> : 'Upload Revision'}
          </Button>
        </Form>
      </div>
    </section>
  );
};

export default UploadRevision;
