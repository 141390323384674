import React, { useEffect, useState } from 'react';
import './styles.scss';
import { ALERT_TYPES } from '../../constants';
import { Button, Checkbox, Form, Input, Table } from 'antd';
import { useParams } from 'react-router-dom';
import ConfirmationModal from '../confirmationModal';
import { useDispatch } from 'react-redux';
import {
  addRegisteredCircleMemberRequest,
  addUnRegisteredCircleMemberRequest,
  deleteCircleMemberRequest,
  getProjectCirclesRequest
} from '../../redux/slicers/projects';
import { toastAlert } from '../../services/utils';
import { ERROR_SOMETHING_WENT_WRONG } from '../../config/webService';

function CircleContent({
  projectCircleMembers,
  selectedCircle,
  isUserAdmin,
  handleGetCicleMembers
}) {
  const handleDeleteCircles = () => {
    handleCloseRemoveConfirm();
  };
  const CIRCLE_LIST_COLUMNS = [
    ...[
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        responsive: ['lg', 'sm', 'xs']
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        responsive: ['lg', 'sm', 'xs']
      },
      {
        title: 'Registration Status',
        dataIndex: 'registration_status',
        key: 'registration_status',
        responsive: ['lg']
      },
      {
        title: 'Invite Status',
        dataIndex: 'invite_status',
        key: 'invite_status',
        responsive: ['lg', 'sm', 'xs']
      }
    ],
    ...(isUserAdmin
      ? [
          {
            title: (
              <>
                <Button
                  size="middle"
                  className="remove-btn"
                  onClick={handleDeleteCircles}
                >
                  Remove
                </Button>
              </>
            ),
            dataIndex: 'Delete',
            key: 'Delete',
            responsive: ['lg', 'sm', 'xs']
          }
        ]
      : [])
  ];
  const dispatch = useDispatch();
  const params = useParams();

  const [memberLoader, setmemberLoader] = useState(false);
  const handleAddMemberLoader = (bool) => {
    setmemberLoader(bool);
  };
  const [memberIds, setmemberIds] = useState([]);
  const [open, setopen] = useState(false);
  const [Updateopen, setUpdateopen] = useState(false);
  // const [msg, setmsg] = useState('');
  const [form] = Form.useForm();

  const handleMsg = () => {};
  const handleCloseRemoveConfirm = () => {
    setUpdateopen(!Updateopen);
  };
  const handleClose = () => {
    setopen(!open);
  };
  useEffect(() => {
    setmemberIds([]);
  }, [selectedCircle]);

  const handleSetDeleteIds = (data, bool) => {
    const ids = [...memberIds];
    if (!bool) {
      const indx = ids.indexOf(data);
      ids.splice(indx, 1);
      setmemberIds(ids);
    } else {
      setmemberIds([...memberIds, data]);
    }
  };

  const tableMemberRows = projectCircleMembers?.map((item) => {
    console.log({ item });
    return {
      ...item,
      ...(isUserAdmin && {
        Delete: (
          <Checkbox
            checked={memberIds.includes(item?._id)}
            value={item?._id}
            onChange={(e) => {
              handleSetDeleteIds(e.target.value, e.target.checked);
            }}
          />
        )
      })
    };
  });

  const handleSubmit = (values) => {
    const payload = {
      ...values,
      circleid: selectedCircle?._id
    };
    if (
      projectCircleMembers?.find((dt) => dt?.email === values?.email.trim())
    ) {
      toastAlert('Email already exist in this circle!', ALERT_TYPES.error);
    } else {
      handleAddMemberLoader(true);
      dispatch(
        addRegisteredCircleMemberRequest({
          payloadData: payload,
          responseCallback: (status, res) => {
            if (!status) {
              handleAddMemberLoader(false);
              handleClose();
              handleMsg(res.msg);
            } else {
              form.resetFields();
              handleAddMemberLoader(false);
              toastAlert(res.msg, ALERT_TYPES.success);
              dispatch(
                getProjectCirclesRequest({
                  payloadData: { projectid: params?.id },
                  responseCallback: (status, res) => {
                    if (status && res.length) {
                      handleGetCicleMembers(selectedCircle?._id, res);
                    }
                  }
                })
              );
            }
          }
        })
      );
    }
  };

  const handleUnRegistered = () => {
    const values = form.getFieldsValue();
    const payload = {
      ...values,
      circleid: selectedCircle?._id
    };
    handleAddMemberLoader(true);
    dispatch(
      addUnRegisteredCircleMemberRequest({
        payloadData: payload,
        responseCallback: (status, res) => {
          form.resetFields();
          handleAddMemberLoader(false);
          handleAddMemberLoader(false);
          if (!status) {
            //handleMsg(res.msg);
            toastAlert(
              res.msg || ERROR_SOMETHING_WENT_WRONG,
              ALERT_TYPES.error
            );
          } else {
            toastAlert(res.msg, ALERT_TYPES.success);

            dispatch(
              getProjectCirclesRequest({
                payloadData: { projectid: params?.id },
                responseCallback: (status, res) => {
                  if (status && res.length) {
                    handleGetCicleMembers(selectedCircle?._id, res);
                  }
                }
              })
            );
            // toastAlert(SUCCESS_MESSAGES.LOGIN, ALERT_TYPES.success);
          }
          handleClose();
        }
      })
    );
    //
  };

  const handleRemoveSelectedCircles = () => {
    const payload = {
      userids: memberIds,
      circleid: selectedCircle?._id
    };

    handleAddMemberLoader(true);
    dispatch(
      deleteCircleMemberRequest({
        payloadData: payload,
        responseCallback: (status, res) => {
          if (!status) {
            handleAddMemberLoader(false);
            toastAlert(res.msg, ALERT_TYPES.error);
          } else {
            setmemberIds([]);
            handleAddMemberLoader(false);
            toastAlert(res.msg, ALERT_TYPES.success);
            dispatch(
              getProjectCirclesRequest({
                payloadData: { projectid: params?.id },
                responseCallback: (status, res) => {
                  if (status && res.length) {
                    handleGetCicleMembers(selectedCircle?._id, res);
                    handleCloseRemoveConfirm();
                  }
                }
              })
            );
          }
          handleCloseRemoveConfirm();
          setmemberIds([]);
        }
      })
    );
  };
  console.log({ tableMemberRows });
  return (
    <div className="CircleContent">
      <div className="content-cont">
        <div className="table-cont-circle">
          <Table
            dataSource={tableMemberRows?.filter(
              (item) => item?._id !== undefined
            )}
            columns={CIRCLE_LIST_COLUMNS}
            onRow={() => {
              return {
                onClick: () => {}
              };
            }}
            rowClassName="table-row"
            pagination={false}
          />
        </div>

        {isUserAdmin && (
          <div className="add-member-wrapper">
            <Form form={form} onFinish={handleSubmit}>
              <Form.Item
                name={'email'}
                rules={[
                  {
                    type: 'email',
                    required: true,
                    message: 'Email is required'
                  }
                ]}
              >
                <Input className="add-member-input" />
              </Form.Item>
              <Button htmlType="submit" className="add-member-btn" size="large">
                Add Member
              </Button>
            </Form>
          </div>
        )}

        {open && (
          <ConfirmationModal
            onSubmit={handleUnRegistered}
            title="Confirmation"
            loading={memberLoader}
            description={
              'Please note: you are adding an unregistered user to this circle. This means the user does not have a registered SiteCircles account and will only be able to receive email notifications.'
            }
            handleClose={handleClose}
          />
        )}

        {Updateopen && (
          <ConfirmationModal
            onSubmit={handleRemoveSelectedCircles}
            title="Confirmation"
            loading={memberLoader}
            description={'Are you sure you want to delete selected members?'}
            handleClose={handleCloseRemoveConfirm}
          />
        )}
      </div>
    </div>
  );
}

export default CircleContent;
