import { Button, Form, Input } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { BeatLoader } from 'react-spinners';
import { ALERT_TYPES, SUCCESS_MESSAGES } from '../../../constants';
import {
  createProjectAliasRequest,
  getProjectViewDetailsRequest
} from '../../../redux/slicers/projects';
import { toastAlert } from '../../../services/utils';
import './styles.scss';
const ViewProject = () => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [form] = Form.useForm();

  const dispatch = useDispatch();
  useEffect(() => {
    const payload = { id: id };
    dispatch(
      getProjectViewDetailsRequest({
        payloadData: payload,
        responseCallback: (status, res) => {
          if (status) {
            setData(res);
          }
        }
      })
    );
  }, []);
  const handleSubmit = () => {
    setLoading(true);
    const values = form.getFieldsValue();
    const payload = { pid: id, data: values };
    dispatch(
      createProjectAliasRequest({
        payloadData: payload,
        responseCallback: (status, res) => {
          if (!status) {
            toastAlert(res.msg, ALERT_TYPES.error);
            setLoading(false);
          } else {
            toastAlert(SUCCESS_MESSAGES.CREATE_ALIAS, ALERT_TYPES.success);
            setLoading(false);
          }
        }
      })
    );
  };
  return (
    <section className="new-cont-project-detail-view">
      {!data ? (
        <BeatLoader size={30} color={'#fff'} />
      ) : (
        <div className="new-form">
          <h3>{data?.pname}</h3>
          <p>This project's details are displayed below.</p>
          <Form
            className="form-create"
            onFinish={handleSubmit}
            form={form}
            // initialValues={{
            //   prevligeCircles: circleoptions?.length
            //     ? `${circleoptions[0]?.value}`
            //     : "",
            // }}
          >
            <div className="alias-input-cont">
              <Form.Item name="alias" className="alias-input">
                <Input
                  className="create-input"
                  placeholder="You can set your personal project alias here"
                />
              </Form.Item>
              <Button className="alias-btn" htmlType="submit">
                {loading ? (
                  <BeatLoader
                    // Type={ClipLoader}
                    size={8}
                    color={'#fff'}
                  />
                ) : (
                  'Save Alias'
                )}
              </Button>
            </div>
            <div className="drawing-input-cont">
              <div className="form-side" style={{ alignItems: 'center' }}>
                <h5>Project Details:</h5>
                <div className="view-details-box">
                  <p className="view-details-text">{data?.pname}</p>
                </div>
                <div className="view-details-box">
                  <p className="view-details-text">{data?.cname}</p>
                </div>
                <div className="view-details-box">
                  <p className="view-details-text">{data?.pdescrip}</p>
                </div>
                <h5>Project Status</h5>
                <div className="view-details-box">
                  {data?.status ? (
                    <h4 className="view-details-status-true">Active</h4>
                  ) : (
                    <h5 className="view-details-status-false">Inactive</h5>
                  )}
                </div>
              </div>

              <div className="form-side" style={{ alignItems: 'center' }}>
                <h5>Location Details:</h5>
                <div className="view-details-box">
                  <p className="view-details-text">
                    Street: {data?.paddress?.street}
                  </p>
                </div>
                <div className="view-details-box">
                  <p className="view-details-text">
                    Suburb: {data?.paddress?.suburb}
                  </p>
                </div>
                <div className="view-details-box">
                  <p className="view-details-text">
                    City: {data?.paddress?.city}
                  </p>
                </div>
                <div className="view-details-box">
                  <p className="view-details-text">
                    Province: {data?.paddress?.province}
                  </p>
                </div>
                <div className="view-details-box">
                  <p className="view-details-text">
                    Country: {data?.paddress?.country}
                  </p>
                </div>
                <div className="view-details-box">
                  <p className="view-details-text">
                    Site Co-ordinates: {data?.paddress?.coordinates}
                  </p>
                </div>
              </div>
            </div>
          </Form>
        </div>
      )}
    </section>
  );
};

export default ViewProject;
