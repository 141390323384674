import { Button, Checkbox, Form, Input, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { Loader } from '../../../components';
import {
  ADMINISTRATORS,
  ALERT_TYPES,
  CreateDrawingFields,
  UPLOAD_REVISION_ROUTE
} from '../../../constants';
import { createDrawingRequest } from '../../../redux/slicers/drawing';
import {
  getCircleNameRequest,
  getCircleOptionsRequest
} from '../../../redux/slicers/projects';
import { toastAlert } from '../../../services/utils';
import './styles.scss';
const NewDrawing = () => {
  const params = useParams();
  const state = useSelector((state) => state);
  const { projects } = state;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [selectedCircleId, setselectedCircleId] = useState('');
  const [loader, setloader] = useState(false);

  const handleLoader = (bool) => {
    setloader(bool);
  };

  const handleChange = (value) => {
    setselectedCircleId(value);
  };

  const { drawingName, drawingNumber, prevlige, readAbleAccess } =
    CreateDrawingFields.fields;

  const handleSubmit = (values) => {
    // const values = form.getFieldsValue();
    const idsWithAdmin = projects?.circleNames?.map((dt) => dt?._id);
    const idsWithNotAdmin = projects?.circleOptions?.map((dt) => dt?._id);
    const { drawingNumber, drawingName, prevligeCircles, readAccess } = values;
    const adminId = idsWithAdmin?.filter(
      (dt) => !idsWithNotAdmin?.includes(dt)
    );

    let payload = {
      dname: drawingName,
      dnumber: drawingNumber,
      circleid: prevligeCircles,
      followerCircles: [...adminId]
    };

    if (prevligeCircles) {
      payload = {
        ...payload,
        circleid: prevligeCircles,
        followerCircles: [prevligeCircles, ...payload?.followerCircles]
      };
    }
    if (readAccess && readAccess?.length) {
      payload = {
        ...payload,
        followerCircles: [...readAccess, ...payload?.followerCircles]
      };
    }
    handleLoader(true);
    dispatch(
      createDrawingRequest({
        payloadData: { data: payload, id: params?.id },
        responseCallback: (status, res) => {
          handleLoader(false);
          if (status && res) {
            toastAlert(res.msg, ALERT_TYPES.success);
            let revisionRoute = UPLOAD_REVISION_ROUTE.replace(':id', params.id);
            revisionRoute = revisionRoute.replace(':drawingId', res._id);
            navigate(revisionRoute);
            // handleProjectCircle(res);
          } else if (!status) {
            toastAlert(res.msg, ALERT_TYPES.error);
          }
        }
      })
    );
  };

  useEffect(() => {
    dispatch(
      getCircleOptionsRequest({
        payloadData: { projectid: params?.id }
        // responseCallback: () => { }
      })
    );

    dispatch(
      getCircleNameRequest({
        payloadData: { projectid: params?.id }
        // responseCallback: () => { }
      })
    );
  }, []);

  const circleoptions =
    projects?.circleOptions?.map((item) => {
      return {
        label: item?.circlename,
        value: item?._id
      };
    }) || [];

  const allCircleOptions =
    projects?.circleNames?.map((item) => {
      return {
        label: item?.circlename,
        value: item?._id
      };
    }) || [];

  return (
    <section className="new-cont-create-draw">
      <div className="new-form">
        <h3 className="draw-head">Drawing</h3>
        <p className="draw-para">
          Enter the information below to create a new drawing
        </p>
        <Form
          className="form-create-drawing"
          onFinish={handleSubmit}
          form={form}
        >
          <div className="drawing-input-cont">
            <div className="form-side">
              <Form.Item name={drawingNumber.title} rules={drawingNumber.rules}>
                <Input
                  className="create-input"
                  placeholder={drawingNumber.label}
                  type={drawingNumber.type}
                />
              </Form.Item>
              <span className="mob-view-draw">
                <Form.Item name={drawingName.title} rules={drawingName.rules}>
                  <Input
                    className="create-input"
                    placeholder={drawingName.label}
                    type={drawingName.type}
                  />
                </Form.Item>
              </span>
              <p>
                Select the 'owning' circle for which you are creating the
                drawing. This circle will have upload privileges to the drawing.
              </p>
              <Form.Item name={prevlige.title} rules={prevlige.rules}>
                <Select
                  className="circle-select"
                  placeholder="Select Circle"
                  style={{ width: 120 }}
                  onChange={handleChange}
                  options={[...circleoptions]}
                />
              </Form.Item>
            </div>
            <div className="vr"></div>
            <div className="form-side">
              <span className="web-view-drawname">
                <Form.Item name={drawingName.title} rules={drawingName.rules}>
                  <Input
                    className="create-input"
                    placeholder={drawingName.label}
                    type={drawingName.type}
                  />
                </Form.Item>
              </span>
              <p>
                Select the circles which need to follow the activity of the
                drawing and have read access.
              </p>
              <Form.Item
                name={readAbleAccess.title}
                className="checkbox-wrapper"
              >
                <Checkbox.Group
                  options={allCircleOptions?.filter(
                    (dt) =>
                      dt?.value !== selectedCircleId &&
                      dt.label !== ADMINISTRATORS
                  )}
                />
              </Form.Item>
            </div>
          </div>
          <Button
            className="create-button-drawing"
            size="large"
            htmlType="submit"
          >
            {loader ? <Loader Type={ClipLoader} size={20} /> : 'Create Drawing'}
          </Button>
        </Form>
      </div>
    </section>
  );
};

export default NewDrawing;
