import { Breadcrumb } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import { DASHBOARD_ROUTE } from '../../constants';

import './styles.scss';

function DashboardBreadCrumb() {
  return (
    <Breadcrumb>
      <Breadcrumb.Item>
        <Link className="customBreadcrumb" to={DASHBOARD_ROUTE}>
          Dashboard
        </Link>
      </Breadcrumb.Item>
    </Breadcrumb>
  );
}

export default DashboardBreadCrumb;
