import { take, put, call, fork } from 'redux-saga/effects';
import {
  userLoginRequest,
  userNewPasswordRequest,
  userPasswordResetRequest,
  resendVerification,
  userSignUpRequest,
  confirmAccount,
  userLoginSuccess,
  getuserProfileRequest,
  getuserProfileSuccess
} from '../slicers/user';
import { ALERT_TYPES } from '../../constants';
import {
  callRequest,
  CONFIRM_ACCOUNT,
  GET_USER_PROFILE,
  LOGIN_USER,
  NEW_PASSWORD,
  REGISTER_USER,
  RESEND_VERIFICATION,
  RESET_PASS
} from '../../config/webService';
import { toastAlert } from '../../services/utils';
import { getSubscriptionsSuccess } from '../slicers/subscription';

function* signup() {
  while (true) {
    // PAYLOAD PATTERN COMING FROM REDUX-TOOLKIT
    const { payload } = yield take(userSignUpRequest.type);
    // PARAMETER SEND FROM DISPATCH WILL DESTRUCTURE THERE
    const { payloadData, responseCallback } = payload;
    try {
      const response = yield call(
        callRequest,
        REGISTER_USER,
        payloadData,
        '',
        '',
        {}
      );
      if (response.success) {
        if (responseCallback) responseCallback(true, response);
      } else {
        if (responseCallback) responseCallback(false, response);
      }
    } catch ({ response }) {
      if (responseCallback) responseCallback(response);
      if (!response.success) {
        toastAlert(response.message, ALERT_TYPES.error);
      }
    }
  }
}
function* login() {
  while (true) {
    // PAYLOAD PATTERN COMING FROM REDUX-TOOLKIT
    const { payload } = yield take(userLoginRequest.type);
    // PARAMETER SEND FROM DISPATCH WILL DESTRUCTURE THERE
    const { payloadData, responseCallback } = payload;

    try {
      const response = yield call(
        callRequest,
        LOGIN_USER,
        payloadData,
        '',
        '',
        {}
      );

      if (response.success) {
        if (responseCallback) responseCallback(true, response);
        yield put(userLoginSuccess(response));
      } else {
        if (responseCallback) responseCallback(false, response);
        yield put(userLoginSuccess(null));
      }
    } catch (err) {
      if (responseCallback) responseCallback(false, err);
    }
  }
}
function* resetPassword() {
  while (true) {
    // PAYLOAD PATTERN COMING FROM REDUX-TOOLKIT
    const { payload } = yield take(userPasswordResetRequest.type);
    // PARAMETER SEND FROM DISPATCH WILL DESTRUCTURE THERE
    // const {} = payload;
    const { payloadData, responseCallback } = payload;
    //
    try {
      const response = yield call(
        callRequest,
        RESET_PASS,
        payloadData,
        '',
        '',
        {}
      );
      if (response.success) {
        if (responseCallback) responseCallback(true, response);
        // yield put(userPas(response));
      } else {
        if (responseCallback) responseCallback(false, response);
      }
    } catch (err) {
      if (responseCallback) responseCallback(err);
    }
  }
}
function* resendVerify() {
  while (true) {
    // PAYLOAD PATTERN COMING FROM REDUX-TOOLKIT
    const { payload } = yield take(resendVerification.type);
    // PARAMETER SEND FROM DISPATCH WILL DESTRUCTURE THERE
    const { payloadData, responseCallback } = payload;
    //
    try {
      const response = yield call(
        callRequest,
        RESEND_VERIFICATION,
        {},
        `${payloadData.email}/resendVerification`,
        '',
        {}
      );
      if (response.success) {
        if (responseCallback) responseCallback(true, response);
        // yield put(getSubscriptionsSuccess(response));
      } else {
        if (responseCallback) responseCallback(false, response);
      }
    } catch (err) {
      if (responseCallback) responseCallback(err);
    }
  }
}
function* confirm() {
  while (true) {
    // PAYLOAD PATTERN COMING FROM REDUX-TOOLKIT
    const { payload, responseCallback } = yield take(confirmAccount.type);
    // PARAMETER SEND FROM DISPATCH WILL DESTRUCTURE THERE

    try {
      const response = yield call(
        callRequest,
        CONFIRM_ACCOUNT,
        {},
        `${payload.id}/confirm`,
        '',
        {}
      );
      if (response.status) {
        if (responseCallback) responseCallback(response);
        yield put(getSubscriptionsSuccess(response));
      } else {
        if (responseCallback) responseCallback(response);
      }
    } catch (err) {
      if (responseCallback) responseCallback(err);
    }
  }
}
function* newPassword() {
  while (true) {
    // PAYLOAD PATTERN COMING FROM REDUX-TOOLKIT
    const { payload } = yield take(userNewPasswordRequest.type);
    // PARAMETER SEND FROM DISPATCH WILL DESTRUCTURE THERE
    const { payloadData, responseCallback } = payload;
    const { resetString } = payloadData;

    try {
      const response = yield call(
        callRequest,
        NEW_PASSWORD,
        { ...payloadData },
        `${resetString}/resetPassword`,
        '',
        {}
      );
      if (response.success) {
        if (responseCallback) responseCallback(true, response);
        yield put(getSubscriptionsSuccess(response));
      } else {
        if (responseCallback) responseCallback(false, response);
      }
    } catch (err) {
      if (responseCallback) responseCallback(false, err);
    }
  }
}
function* getUser() {
  while (true) {
    // PAYLOAD PATTERN COMING FROM REDUX-TOOLKIT
    const { payload } = yield take(getuserProfileRequest.type);
    // PARAMETER SEND FROM DISPATCH WILL DESTRUCTURE THERE
    const { responseCallback } = payload;

    try {
      const response = yield call(
        callRequest,
        GET_USER_PROFILE,
        '',
        '',
        '',
        {}
      );
      if (response) {
        if (responseCallback) responseCallback(true, response);
        yield put(getuserProfileSuccess(response));
      } else {
        if (responseCallback) responseCallback(false, response);
      }
    } catch (err) {
      if (responseCallback) responseCallback(false, err);
    }
  }
}
export default function* root() {
  yield fork(getUser);
  yield fork(signup);
  yield fork(login);
  yield fork(resetPassword);
  yield fork(resendVerify);
  yield fork(newPassword);
  yield fork(confirm);
}
