import { take, put, call, fork } from 'redux-saga/effects';
import {
  ACCEPT_CIRCLE,
  ACCEPT_CIRCLE_INVITE,
  ADD_REGISTERED_CIRCLE_MEMBER_URL,
  ADD_UNREGISTERED_CIRCLE_MEMBER_URL,
  ARCHIVE_PROJECT,
  BULK_DOWNLOAD_DRAWINGS,
  BULK_SHARE_DRAWINGS,
  callRequest,
  CLEAR_ALL_DRAWINGS_NOTIFICATIONS,
  CREATE_PROJECT,
  CREATE_PROJECT_CIRCLE_URL,
  DELETE_CIRCLE_MEMBER_URL,
  DELETE_CIRCLE_URL,
  DELETE_NOTIFICATIONS_DRAWING,
  DELETE_NOTIFICATION_URL,
  EDIT_PROJECT,
  GET_ARCHIVED_PROJECTS,
  GET_CIRCLE_INVITES,
  GET_PROJECT_CIRCLE_NAMES_URL,
  GET_PROJECT_COMMENT_NOTIFICATION_COUNT_URL,
  GET_PROJECT_NOTIFICATION_COUNT_URL,
  GET_PROJECT_URL,
  GET_VIEW_DETAILS,
  LEAVE_PROJECT,
  REJECT_CIRCLE,
  REJECT_CIRCLE_INVITE,
  SET_ALIAS,
  UPDATE_CIRCLE_NAME_URL,
  CIRCLE_ORDER
} from '../../config/webService';
import { deleteNotificationsParams, toastAlert } from '../../services/utils';
import {
  acceptCircleInviteRequest,
  acceptCircleRequest,
  addRegisteredCircleMemberRequest,
  addUnRegisteredCircleMemberRequest,
  archiveProjectRequest,
  createCircleName,
  createProjectAliasRequest,
  createProjectCircleRequest,
  createProjectRequest,
  createProjectSuccess,
  deleteCircleMemberRequest,
  deleteCircleRequest,
  deleteNotificationDrawinngRequest,
  deleteNotificationRequest,
  editProjectRequest,
  getArchivedProjectRequest,
  getArchivedProjectSuccess,
  getCircleInvitesRequest,
  getCircleNameRequest,
  getCircleNameSuccess,
  getCircleOptionsRequest,
  getCircleOptionsSuccess,
  getCommentNotificationCountRequest,
  getNotificationCountRequest,
  getProjectCirclesRequest,
  getProjectCirclesSuccess,
  getProjectRequest,
  getProjectSuccess,
  getProjectViewDetailsRequest,
  leaveProjectRequest,
  rejectCircleInviteRequest,
  rejectCircleRequest,
  updateCircleNameRequest,
  bulkDownloadDrawingsRequest,
  bulkShareDrawingsRequest,
  clearAllNotificationsRequest,
  circleOrderRequest
} from '../slicers/projects';

function* create() {
  while (true) {
    const { payload } = yield take(createProjectRequest.type);
    const { payloadData, responseCallback } = payload;
    try {
      const response = yield call(
        callRequest,
        CREATE_PROJECT,
        payloadData,
        '',
        '',
        {}
      );

      if (response.success) {
        if (responseCallback) responseCallback(true, response);
        yield put(createProjectSuccess(response));
      } else {
        if (responseCallback) responseCallback(false, response);
      }
    } catch (err) {
      if (responseCallback) responseCallback(err);
    }
  }
}
function* getProject() {
  while (true) {
    // PAYLOAD PATTERN COMING FROM REDUX-TOOLKIT
    const { payload } = yield take(getProjectRequest.type);
    // PARAMETER SEND FROM DISPATCH WILL DESTRUCTURE THERE
    const { responseCallback } = payload;

    try {
      const response = yield call(callRequest, GET_PROJECT_URL, '', '', '', {});
      //
      if (response) {
        if (responseCallback) responseCallback(true, response);
        yield put(getProjectSuccess(response));
      } else {
        if (responseCallback) responseCallback(false, response);
      }
    } catch (err) {
      if (responseCallback) responseCallback(err);
    }
  }
}
function* getProjectNotificationCount() {
  while (true) {
    // PAYLOAD PATTERN COMING FROM REDUX-TOOLKIT
    const { payload } = yield take(getNotificationCountRequest.type);
    // PARAMETER SEND FROM DISPATCH WILL DESTRUCTURE THERE
    const { responseCallback } = payload;

    try {
      const response = yield call(
        callRequest,
        GET_PROJECT_NOTIFICATION_COUNT_URL,
        '',
        '',
        '',
        {}
      );
      if (response?.length) {
        if (responseCallback) responseCallback(true, response);
        // yield put(getNotificationCountSuccess(response));
      } else {
        if (responseCallback) responseCallback(false, response);
      }
    } catch (err) {
      if (responseCallback) responseCallback(false, err);
    }
  }
}
function* getCommentProjectNotification() {
  while (true) {
    // PAYLOAD PATTERN COMING FROM REDUX-TOOLKIT
    const { payload } = yield take(getCommentNotificationCountRequest.type);
    // PARAMETER SEND FROM DISPATCH WILL DESTRUCTURE THERE
    const { responseCallback } = payload;

    try {
      const response = yield call(
        callRequest,
        GET_PROJECT_COMMENT_NOTIFICATION_COUNT_URL,
        '',
        '',
        '',
        {}
      );
      if (response.length) {
        if (responseCallback) responseCallback(true, response);
      } else {
        if (responseCallback) responseCallback(false, response);
      }
    } catch (err) {
      if (responseCallback) responseCallback(false, err);
    }
  }
}
function* deleteNotifications() {
  while (true) {
    // PAYLOAD PATTERN COMING FROM REDUX-TOOLKIT
    const { payload } = yield take(deleteNotificationRequest.type);
    // PARAMETER SEND FROM DISPATCH WILL DESTRUCTURE THERE
    const { payloadData, responseCallback } = payload;

    try {
      const response = yield call(
        callRequest,
        DELETE_NOTIFICATION_URL,
        '',
        deleteNotificationsParams(payloadData?.id),
        '',
        {}
      );
      if (response.success) {
        if (responseCallback) responseCallback(true, response);
      } else {
        if (responseCallback) responseCallback(false, response);
      }
    } catch (err) {
      if (responseCallback) responseCallback(false, err);
    }
  }
}
function* deleteNotificationsDrawing() {
  while (true) {
    // PAYLOAD PATTERN COMING FROM REDUX-TOOLKIT
    const { payload } = yield take(deleteNotificationDrawinngRequest.type);
    // PARAMETER SEND FROM DISPATCH WILL DESTRUCTURE THERE
    const { payloadData, responseCallback } = payload;
    const url = {
      ...DELETE_NOTIFICATIONS_DRAWING,
      route: DELETE_NOTIFICATIONS_DRAWING?.route?.replace(
        ':drawingid',
        payloadData
      )
    };
    try {
      const response = yield call(callRequest, url, '', '', '', {});
      if (response.success) {
        if (responseCallback) responseCallback(true, response);
      } else {
        if (responseCallback) responseCallback(false, response);
      }
    } catch (err) {
      if (responseCallback) responseCallback(false, err);
    }
  }
}
// SINGLE PROJECT PAGE APIS

// new drawing  PAGE APIS
function* getCircleOptions() {
  while (true) {
    // PAYLOAD PATTERN COMING FROM REDUX-TOOLKIT
    const { payload } = yield take(getCircleOptionsRequest.type);
    // PARAMETER SEND FROM DISPATCH WILL DESTRUCTURE THERE
    const { payloadData, responseCallback } = payload;

    try {
      const response = yield call(
        callRequest,
        GET_PROJECT_CIRCLE_NAMES_URL,
        '',
        '',
        '',
        payloadData
      );
      if (response) {
        if (responseCallback) responseCallback(true, response);
        yield put(getCircleOptionsSuccess(response));
      } else {
        if (responseCallback) responseCallback(false, response);
      }
    } catch (err) {
      if (responseCallback) responseCallback(false, err);
    }
  }
}
function* getCircleNames() {
  while (true) {
    // PAYLOAD PATTERN COMING FROM REDUX-TOOLKIT
    const { payload } = yield take(getCircleNameRequest.type);
    // PARAMETER SEND FROM DISPATCH WILL DESTRUCTURE THERE
    const { payloadData, responseCallback } = payload;

    try {
      const response = yield call(
        callRequest,
        GET_PROJECT_CIRCLE_NAMES_URL,
        '',
        '',
        '',
        payloadData
      );
      if (response) {
        if (responseCallback) responseCallback(true, response);
        yield put(getCircleNameSuccess(response));
      } else {
        if (responseCallback) responseCallback(false, response);
      }
    } catch (err) {
      if (responseCallback) responseCallback(false, err);
    }
  }
}

function* getProjectCircles() {
  while (true) {
    // PAYLOAD PATTERN COMING FROM REDUX-TOOLKIT
    const { payload } = yield take(getProjectCirclesRequest.type);
    // PARAMETER SEND FROM DISPATCH WILL DESTRUCTURE THERE
    const { payloadData, responseCallback } = payload;

    try {
      const response = yield call(
        callRequest,
        GET_PROJECT_CIRCLE_NAMES_URL,
        '',
        '',
        '',
        { ...payloadData }
      );
      if (response?.length > 0) {
        if (responseCallback) responseCallback(true, response);
        yield put(getProjectCirclesSuccess(response));
      } else {
        if (responseCallback) responseCallback(false, response);
      }
    } catch (err) {
      if (responseCallback) responseCallback(false, err);
    }
  }
}
function* createProjectCircle() {
  while (true) {
    // PAYLOAD PATTERN COMING FROM REDUX-TOOLKIT
    const { payload } = yield take(createProjectCircleRequest.type);
    // PARAMETER SEND FROM DISPATCH WILL DESTRUCTURE THERE
    const { payloadData, responseCallback } = payload;

    try {
      const response = yield call(
        callRequest,
        CREATE_PROJECT_CIRCLE_URL,
        payloadData,
        '',
        '',
        {}
      );
      if (response) {
        const payloadSuccuss = payloadData;
        payloadSuccuss['_id'] = response._id;
        yield put(createCircleName({ payloadData: payloadSuccuss }));
        if (responseCallback) responseCallback(true, response);
      } else {
        if (responseCallback) responseCallback(false, response);
      }
    } catch (err) {
      if (responseCallback) responseCallback(false, err);
    }
  }
}
function* addRegisteredCircleMember() {
  while (true) {
    // PAYLOAD PATTERN COMING FROM REDUX-TOOLKIT
    const { payload } = yield take(addRegisteredCircleMemberRequest.type);
    // PARAMETER SEND FROM DISPATCH WILL DESTRUCTURE THERE
    const { payloadData, responseCallback } = payload;

    try {
      const response = yield call(
        callRequest,
        ADD_REGISTERED_CIRCLE_MEMBER_URL,
        payloadData,
        '',
        '',
        {}
      );
      if (response.success) {
        if (responseCallback) responseCallback(true, response);
      } else {
        if (responseCallback) responseCallback(false, response);
      }
    } catch (err) {
      if (responseCallback) responseCallback(false, err);
    }
  }
}
function* addUnRegisteredCircleMember() {
  while (true) {
    // PAYLOAD PATTERN COMING FROM REDUX-TOOLKIT
    const { payload } = yield take(addUnRegisteredCircleMemberRequest.type);
    // PARAMETER SEND FROM DISPATCH WILL DESTRUCTURE THERE
    const { payloadData, responseCallback } = payload;

    try {
      const response = yield call(
        callRequest,
        ADD_UNREGISTERED_CIRCLE_MEMBER_URL,
        payloadData,
        '',
        '',
        {}
      );
      if (response.success) {
        if (responseCallback) responseCallback(true, response);
      } else {
        if (responseCallback) responseCallback(false, response);
      }
    } catch (err) {
      if (responseCallback) responseCallback(false, err);
    }
  }
}
function* deleteCircle() {
  while (true) {
    // PAYLOAD PATTERN COMING FROM REDUX-TOOLKIT
    const { payload } = yield take(deleteCircleRequest.type);
    // PARAMETER SEND FROM DISPATCH WILL DESTRUCTURE THERE
    const { payloadData, responseCallback } = payload;

    try {
      const response = yield call(
        callRequest,
        DELETE_CIRCLE_URL,
        payloadData,
        '',
        '',
        {}
      );
      if (response.success) {
        if (responseCallback) responseCallback(true, response);
      } else {
        if (responseCallback) responseCallback(false, response);
      }
    } catch (err) {
      if (responseCallback) responseCallback(false, err);
    }
  }
}
function* updateCircleName() {
  while (true) {
    // PAYLOAD PATTERN COMING FROM REDUX-TOOLKIT
    const { payload } = yield take(updateCircleNameRequest.type);
    // PARAMETER SEND FROM DISPATCH WILL DESTRUCTURE THERE
    const { payloadData, responseCallback } = payload;

    try {
      const response = yield call(
        callRequest,
        UPDATE_CIRCLE_NAME_URL,
        payloadData,
        '',
        '',
        {}
      );
      if (response.success) {
        if (responseCallback) responseCallback(true, response);
      } else {
        if (responseCallback) responseCallback(false, response);
      }
    } catch (err) {
      if (responseCallback) responseCallback(false, err);
    }
  }
}
function* archiveProject() {
  while (true) {
    // PAYLOAD PATTERN COMING FROM REDUX-TOOLKIT
    const { payload } = yield take(archiveProjectRequest.type);
    // PARAMETER SEND FROM DISPATCH WILL DESTRUCTURE THERE

    const { responseCallback } = payload;

    try {
      const response = yield call(
        callRequest,
        ARCHIVE_PROJECT,
        payload.status,
        `/${payload.id}/changeArchivedStatus`,
        '',
        {}
      );
      if (response.success) {
        if (responseCallback) responseCallback(true, response);
      } else {
        if (responseCallback) responseCallback(false, response);
      }
    } catch (err) {
      if (responseCallback) responseCallback(false, err);
    }
  }
}
function* leaveProject() {
  while (true) {
    // PAYLOAD PATTERN COMING FROM REDUX-TOOLKIT
    const { payload } = yield take(leaveProjectRequest.type);
    // PARAMETER SEND FROM DISPATCH WILL DESTRUCTURE THERE
    const { responseCallback, payloadData } = payload;

    try {
      const response = yield call(
        callRequest,
        LEAVE_PROJECT,
        payloadData,
        '',
        '',
        {}
      );
      if (response.success) {
        if (responseCallback) responseCallback(true, response);
      } else {
        if (responseCallback) responseCallback(false, response);
      }
    } catch (err) {
      if (responseCallback) responseCallback(false, err);
    }
  }
}
function* getArchivedProject() {
  while (true) {
    // PAYLOAD PATTERN COMING FROM REDUX-TOOLKIT
    const { payload } = yield take(getArchivedProjectRequest.type);
    // PARAMETER SEND FROM DISPATCH WILL DESTRUCTURE THERE
    const { responseCallback } = payload;

    try {
      const response = yield call(
        callRequest,
        GET_ARCHIVED_PROJECTS,
        '',
        '',
        '',
        {}
      );
      //
      if (response) {
        if (responseCallback) responseCallback(true, response);
        yield put(getArchivedProjectSuccess(response));
      } else {
        if (responseCallback) responseCallback(false, response);
      }
    } catch (err) {
      if (responseCallback) responseCallback(err);
    }
  }
}

function* deleteCircleMember() {
  while (true) {
    // PAYLOAD PATTERN COMING FROM REDUX-TOOLKIT
    const { payload } = yield take(deleteCircleMemberRequest.type);
    // PARAMETER SEND FROM DISPATCH WILL DESTRUCTURE THERE
    const { payloadData, responseCallback } = payload;

    try {
      const response = yield call(
        callRequest,
        DELETE_CIRCLE_MEMBER_URL,
        payloadData,
        '',
        '',
        {}
      );
      if (response.success) {
        if (responseCallback) responseCallback(true, response);
      } else {
        if (responseCallback) responseCallback(false, response);
      }
    } catch (err) {
      if (responseCallback) responseCallback(false, err);
    }
  }
}

function* getCircleInvites() {
  while (true) {
    // PAYLOAD PATTERN COMING FROM REDUX-TOOLKIT
    const { payload } = yield take(getCircleInvitesRequest.type);
    // PARAMETER SEND FROM DISPATCH WILL DESTRUCTURE THERE
    const { responseCallback } = payload;

    try {
      const response = yield call(
        callRequest,
        GET_CIRCLE_INVITES,
        {},
        '',
        '',
        {}
      );
      if (response) {
        if (responseCallback) responseCallback(true, response);
        yield put(getArchivedProjectSuccess(response));
      } else {
        if (responseCallback) responseCallback(false, response);
      }
    } catch (err) {
      if (responseCallback) responseCallback(err);
    }
  }
}

function* rejectCircleInvite() {
  while (true) {
    // PAYLOAD PATTERN COMING FROM REDUX-TOOLKIT
    const { payload } = yield take(rejectCircleInviteRequest.type);
    // PARAMETER SEND FROM DISPATCH WILL DESTRUCTURE THERE
    const { payloadData, responseCallback } = payload;

    try {
      const response = yield call(
        callRequest,
        REJECT_CIRCLE_INVITE,
        payloadData,
        '',
        '',
        {}
      );
      if (response.success) {
        if (responseCallback) responseCallback(true, response);
      } else {
        if (responseCallback) responseCallback(false, response);
      }
    } catch (err) {
      if (responseCallback) responseCallback(false, err);
    }
  }
}
function* acceptCircleInvite() {
  while (true) {
    // PAYLOAD PATTERN COMING FROM REDUX-TOOLKIT
    const { payload } = yield take(acceptCircleInviteRequest.type);
    // PARAMETER SEND FROM DISPATCH WILL DESTRUCTURE THERE
    const { payloadData, responseCallback } = payload;

    try {
      const response = yield call(
        callRequest,
        ACCEPT_CIRCLE_INVITE,
        payloadData,
        '',
        '',
        {}
      );
      if (response.success) {
        if (responseCallback) responseCallback(true, response);
      } else {
        if (responseCallback) responseCallback(false, response);
      }
    } catch (err) {
      if (responseCallback) responseCallback(false, err);
    }
  }
}

function* createProjectAlias() {
  while (true) {
    // PAYLOAD PATTERN COMING FROM REDUX-TOOLKIT
    const { payload } = yield take(createProjectAliasRequest.type);
    // PARAMETER SEND FROM DISPATCH WILL DESTRUCTURE THERE
    const { payloadData, responseCallback } = payload;

    try {
      const response = yield call(
        callRequest,
        SET_ALIAS,
        payloadData.data,
        `${payloadData.pid}/changeProjectAlias`,
        '',
        {}
      );
      if (response.success) {
        if (responseCallback) responseCallback(true, response);
      } else {
        if (responseCallback) responseCallback(false, response);
      }
    } catch (err) {
      if (responseCallback) responseCallback(false, err);
    }
  }
}

function* getViewDetails() {
  while (true) {
    // PAYLOAD PATTERN COMING FROM REDUX-TOOLKIT
    const { payload } = yield take(getProjectViewDetailsRequest.type);
    // PARAMETER SEND FROM DISPATCH WILL DESTRUCTURE THERE
    const { payloadData, responseCallback } = payload;

    try {
      const response = yield call(
        callRequest,
        GET_VIEW_DETAILS,
        {},
        `${payloadData.id}/details`,
        '',
        {}
      );
      if (response) {
        if (responseCallback) responseCallback(true, response);
      } else {
        if (responseCallback) responseCallback(false, response);
      }
    } catch (err) {
      if (responseCallback) responseCallback(err);
    }
  }
}
function* editProject() {
  while (true) {
    // PAYLOAD PATTERN COMING FROM REDUX-TOOLKIT
    const { payload } = yield take(editProjectRequest.type);
    // PARAMETER SEND FROM DISPATCH WILL DESTRUCTURE THERE
    const { payloadData, responseCallback } = payload;
    try {
      const response = yield call(
        callRequest,
        EDIT_PROJECT,
        payloadData.data,
        `${payloadData.pid}/update`,
        '',
        {}
      );

      if (response.success) {
        if (responseCallback) responseCallback(true, response);
        // yield put(createProjectSuccess(response));
      } else {
        if (responseCallback) responseCallback(false, response);
      }
    } catch (err) {
      if (responseCallback) responseCallback(err);
    }
  }
}
function* rejectCircle() {
  while (true) {
    // PAYLOAD PATTERN COMING FROM REDUX-TOOLKIT
    const { payload } = yield take(rejectCircleRequest.type);
    // PARAMETER SEND FROM DISPATCH WILL DESTRUCTURE THERE
    const { payloadData, responseCallback } = payload;

    const url = {
      ...REJECT_CIRCLE,
      route: REJECT_CIRCLE?.route?.replace(':circleId', payloadData.circleId)
    };
    try {
      const response = yield call(callRequest, url, '', '', '');

      if (response.success) {
        if (responseCallback) responseCallback(true, response);
      } else {
        if (responseCallback) responseCallback(false, response);
      }
    } catch (err) {
      if (responseCallback) responseCallback(err);
    }
  }
}
function* acceptCircle() {
  while (true) {
    // PAYLOAD PATTERN COMING FROM REDUX-TOOLKIT
    const { payload } = yield take(acceptCircleRequest.type);
    // PARAMETER SEND FROM DISPATCH WILL DESTRUCTURE THERE
    const { payloadData, responseCallback } = payload;
    const url = {
      ...ACCEPT_CIRCLE,
      route: ACCEPT_CIRCLE?.route?.replace(':circleId', payloadData.circleId)
    };
    try {
      const response = yield call(callRequest, url, '', '', '');

      if (response.success) {
        if (responseCallback) responseCallback(true, response);
      } else {
        if (responseCallback) responseCallback(false, response);
      }
    } catch (err) {
      if (responseCallback) responseCallback(err);
    }
  }
}
function* bulkDownloadDraing() {
  while (true) {
    // PAYLOAD PATTERN COMING FROM REDUX-TOOLKIT
    const { payload } = yield take(bulkDownloadDrawingsRequest.type);
    // PARAMETER SEND FROM DISPATCH WILL DESTRUCTURE THERE
    const { payloadData, responseCallback } = payload;
    const url = {
      ...BULK_DOWNLOAD_DRAWINGS,
      route: BULK_DOWNLOAD_DRAWINGS?.route?.replace(
        ':projectId',
        payloadData?.data?.projectId
      )
    };
    try {
      const response = yield call(
        callRequest,
        url,
        { drawingIds: payloadData?.data?.drawingIds },
        '',
        ''
      );

      if (response.success) {
        if (responseCallback) responseCallback(true, response);
        toastAlert('Drawings have been sent to your email');
      } else {
        if (responseCallback) responseCallback(false, response);
      }
    } catch (err) {
      if (responseCallback) responseCallback(err);
    }
  }
}
function* bulkShareDraing() {
  while (true) {
    const { payload } = yield take(bulkShareDrawingsRequest.type);
    const { payloadData, responseCallback } = payload;
    const url = {
      ...BULK_SHARE_DRAWINGS,
      route: BULK_SHARE_DRAWINGS?.route?.replace(
        ':projectId',
        payloadData?.data?.projectId
      )
    };
    try {
      const response = yield call(
        callRequest,
        url,
        {
          drawingIds: payloadData?.data?.drawingIds,
          shareAddresses: payloadData?.data?.shareAddresses
        },
        '',
        ''
      );

      if (response.success) {
        if (responseCallback) responseCallback(true, response);
        toastAlert('Drawings have been shared in emails');
      } else {
        if (responseCallback) responseCallback(false, response);
      }
    } catch (err) {
      if (responseCallback) responseCallback(err);
    }
  }
}
function* clearAllNotificationDownload() {
  while (true) {
    const { payload } = yield take(clearAllNotificationsRequest.type);
    const { payloadData, responseCallback } = payload;
    const url = {
      ...CLEAR_ALL_DRAWINGS_NOTIFICATIONS,
      route: CLEAR_ALL_DRAWINGS_NOTIFICATIONS?.route?.replace(
        ':projectId',
        payloadData?.projectId
      )
    };
    try {
      const response = yield call(callRequest, url, '', '', '');
      if (response.success) {
        if (responseCallback) responseCallback(true, response);
      } else {
        if (responseCallback) responseCallback(false, response);
      }
    } catch (err) {
      if (responseCallback) responseCallback(err);
    }
  }
}
function* circleOrderList() {
  while (true) {
    const { payload } = yield take(circleOrderRequest.type);
    const { payloadData, responseCallback } = payload;
    try {
      const response = yield call(
        callRequest,
        CIRCLE_ORDER,
        payloadData,
        '',
        ''
      );
      if (response.success) {
        if (responseCallback) responseCallback(true, response);
      } else {
        if (responseCallback) responseCallback(false, response);
      }
    } catch (err) {
      if (responseCallback) responseCallback(err);
    }
  }
}
export default function* root() {
  yield fork(deleteNotifications);
  yield fork(getCommentProjectNotification);
  yield fork(deleteCircleMember);
  yield fork(updateCircleName);
  yield fork(deleteCircle);
  yield fork(addUnRegisteredCircleMember);
  yield fork(addRegisteredCircleMember);
  yield fork(createProjectCircle);
  yield fork(getProjectCircles);
  yield fork(getCircleNames);
  yield fork(getCircleOptions);
  yield fork(getProjectNotificationCount);
  yield fork(getProject);
  yield fork(create);
  yield fork(archiveProject);
  yield fork(getArchivedProject);
  yield fork(leaveProject);
  yield fork(getCircleInvites);
  yield fork(rejectCircleInvite);
  yield fork(acceptCircleInvite);
  yield fork(getViewDetails);
  yield fork(createProjectAlias);
  yield fork(editProject);
  yield fork(rejectCircle);
  yield fork(acceptCircle);
  yield fork(deleteNotificationsDrawing);
  yield fork(bulkDownloadDraing);
  yield fork(bulkShareDraing);
  yield fork(clearAllNotificationDownload);
  yield fork(circleOrderList);
}
