import React, { useEffect } from 'react';
import { Header } from '../../components';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { lOGIN_ROUTE } from '../../constants';
import { getuserProfileRequest } from '../../redux/slicers/user';

function PrivateSharedLayout({ children, breadCrumbs }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authenticated = useSelector((state) => state.user.isAuthenticated);

  useEffect(() => {
    if (!authenticated) {
      navigate(lOGIN_ROUTE);
    } else {
      dispatch(
        getuserProfileRequest({
          payloadData: null,
          responseCallback: () => {}
        })
      );
    }
  }, [authenticated]);
  return (
    <section className="dashboard-wrapper">
      <Header>{breadCrumbs}</Header>
      {children}
    </section>
  );
}

export default PrivateSharedLayout;
