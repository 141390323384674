import { Button, Form, Input } from 'antd';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { BeatLoader } from 'react-spinners';
import {
  ALERT_TYPES,
  CreateFields,
  SUCCESS_MESSAGES
} from '../../../constants';
import { createProjectRequest } from '../../../redux/slicers/projects';
import { toastAlert } from '../../../services/utils';
import './styles.scss';
const NewProject = () => {
  const data = useSelector((state) => state.user.data);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const primiumProject = useSelector((state) => state.general.primiumProject);

  const {
    projectName,
    companyName,
    projectDescription,
    streetInfo,
    city,
    subUrb,
    country,
    province,
    cordinates
  } = CreateFields.fields;

  const handleSubmit = () => {
    const values = form.getFieldsValue();

    setIsLoading(true);
    const payload = {
      cname: values.companyName,
      paddress: {
        coordinates: values.cordinates,
        street: values.streetInfo,
        suburb: values.subUrb,
        city: values.city,
        province: values.province,
        country: values.country
      },
      pdescrip: values.projectDescription,
      pname: values.projectName,
      userEmail: data.data.email,
      userName: data.data.fname + ' ' + data.data.lname
    };
    dispatch(
      createProjectRequest({
        payloadData: payload,
        responseCallback: (status, res) => {
          if (!status) {
            toastAlert(res.msg, ALERT_TYPES.error);
            setIsLoading(false);
          } else {
            setIsLoading(false);
            toastAlert(SUCCESS_MESSAGES.CREATE_PROJECT, ALERT_TYPES.success);
            navigate('/dashboard');
          }
        }
      })
    );

    //
  };
  return (
    <section className="new-cont2">
      <div className="new-form">
        <h3>Create a Project</h3>
        <p>Enter the information below to create a project</p>
        <Form className="form-create2" onFinish={handleSubmit} form={form}>
          <div className="input-cont">
            <div className="form-side">
              <Form.Item name={projectName.title} rules={projectName.rules}>
                <Input
                  className="create-input"
                  placeholder={projectName.label}
                  type={projectName.type}
                />
              </Form.Item>{' '}
              <Form.Item name={companyName.title} rules={companyName.rules}>
                <Input
                  className="create-input"
                  placeholder={companyName.label}
                  type={companyName.type}
                />
              </Form.Item>{' '}
              <Form.Item
                name={projectDescription.title}
                rules={projectDescription.rules}
              >
                <Input.TextArea
                  className="create-input"
                  placeholder={projectDescription.label}
                  type={projectDescription.type}
                  rows={4}
                />
              </Form.Item>
            </div>
            <div className="form-side">
              <Form.Item name={streetInfo.title} rules={streetInfo.rules}>
                <Input
                  className="create-input"
                  placeholder={streetInfo.label}
                  type={streetInfo.type}
                />
              </Form.Item>
              <Form.Item name={subUrb.title} rules={subUrb.rules}>
                <Input
                  className="create-input"
                  placeholder={subUrb.label}
                  type={subUrb.type}
                />
              </Form.Item>
              <Form.Item name={city.title} rules={city.rules}>
                <Input
                  className="create-input"
                  placeholder={city.label}
                  type={city.type}
                />
              </Form.Item>
              <Form.Item name={province.title} rules={province.rules}>
                <Input
                  className="create-input"
                  placeholder={province.label}
                  type={province.type}
                />
              </Form.Item>
              <Form.Item name={country.title} rules={country.rules}>
                <Input
                  className="create-input"
                  placeholder={country.label}
                  type={country.type}
                />
              </Form.Item>{' '}
              <Form.Item name={cordinates.title} rules={cordinates.rules}>
                <Input
                  className="create-input"
                  placeholder={cordinates.label}
                  type={cordinates.type}
                />
              </Form.Item>
            </div>
          </div>
          <div className="mainTxtDiv2">
            <div className="main-choose-txt2">
              {'Creating a project uses 1 project credit. You currently have'}{' '}
              <span>
                {primiumProject?.available ?? 0} project credits available.
              </span>
            </div>
          </div>
          <Button
            className="login-btn create-btn"
            size="large"
            htmlType="submit"
          >
            {isLoading ? (
              <BeatLoader
                // Type={ClipLoader}
                size={8}
                color={'#fff'}
              />
            ) : (
              CreateFields.btn
            )}
          </Button>
        </Form>
      </div>
    </section>
  );
};

export default NewProject;
