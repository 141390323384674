import { fork } from 'redux-saga/effects';
import general from './general';
import subscription from './subscription';
import users from './users';
import projects from './projects';
import drawings from './drawings';
import upload from './upload';
import comments from './comments';
export default function* root() {
  yield fork(upload);
  yield fork(general);
  yield fork(subscription);
  yield fork(users);
  yield fork(projects);
  yield fork(comments);
  yield fork(drawings);
}
