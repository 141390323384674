import React from 'react';
import './auth.scss';
import logo from '../../assets/logo/Logo1.png';
import { Button, Col, Form, Row } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { resendVerification } from '../../redux/slicers/user';
import { useDispatch } from 'react-redux';

function VerifyRegistration() {
  const [form] = Form.useForm();
  const { email } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleResend = () => {
    const payload = { email: email };
    dispatch(resendVerification(payload));
  };

  return (
    <div className="login-container">
      <Row align={'middle'} className="loginWrapper" justify="center">
        <Col xs={24} lg={13}>
          <div className="loginLogoWrapper">
            <img src={logo} className="logo-login" />
            <div className="title-cont">
              <p style={{ fontWeight: 'bold' }}>Site</p>
              &nbsp;
              <p style={{ fontWeight: 'lighter' }}>Circles</p>
            </div>
            <p className="tagline">Current, Trackable, Always on Site</p>
            <p className="tagline">
              Please check your inbox for an account confirmation email. If you
              do not receive this email within a few minutes, you may request to
              resend the email below.
            </p>
          </div>
          <Form className="form-login" form={form}>
            <div
              className="login-button-wrapper"
              style={{ flexDirection: 'column', gap: '15px' }}
            >
              <Button
                className="verification-btn"
                size="large"
                onClick={handleResend}
              >
                Resend Verification Email
              </Button>{' '}
              <Button
                className="verification-btn"
                size="large"
                onClick={() => {
                  navigate('/');
                }}
              >
                Back to Login
              </Button>
            </div>
          </Form>
        </Col>
      </Row>
    </div>
  );
}

export default VerifyRegistration;
