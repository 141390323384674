import { Menu, Upload, Dropdown } from 'antd';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  getCircleNameRequest,
  getCircleOptionsRequest
} from '../../../redux/slicers/projects';
import {
  bulkUploadFilesCircle,
  bulkUploadFilesReducer
} from '../../../redux/slicers/upload';
import { Images } from '../../../theme';
import './styles.scss';
const UploadStep1 = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { projects } = state;
  const { circleNames, circleOptions } = projects;
  const user = useSelector((state) => state?.user?.data?.data);
  const params = useParams();
  const { bulkUploadFiles, bulkUploadAllFiles } = state?.upload;
  const [list, setList] = useState([]);
  const [error, setError] = useState(false);
  const [circleUserOwn, setCircleUserOwn] = useState(() => []);

  useEffect(() => {
    dispatch(
      getCircleNameRequest({
        payloadData: { projectid: params?.id },
        responseCallback: () => {}
      })
    );
    dispatch(
      getCircleOptionsRequest({
        payloadData: { projectid: params?.id },
        responseCallback: () => {}
      })
    );
  }, []);

  useEffect(() => {
    let tempArray = [];
    if (!_.isEmpty(circleOptions)) {
      const checkCircleName = circleNames?.[0]?.circlemembers?.some(
        (item) => item?.userid == user?.id
      );
      if (checkCircleName) {
        setCircleUserOwn(circleOptions);
      } else {
        circleOptions.map((item) => {
          const hasUser = item?.circlemembers?.some(
            (item) => item?.userid == user?.id
          );
          if (hasUser) {
            tempArray.push(item);
          }
        });
        setCircleUserOwn(tempArray);
      }
    }
  }, [circleOptions]);

  return (
    <div className="main-parent-div">
      <div className="mainTxtDiv">
        <p className="main-choose-txt">
          Select a folder to upload from your device
        </p>
      </div>
      <Upload
        multiple={false}
        maxCount={1}
        directory
        accept=".dwg,.pdf"
        beforeUpload={(file) => {
          setError(false);
          const fileName = file?.name?.split('.');

          if (file.type === 'application/pdf') {
            setList((prev) => [...prev, file]);
          } else if (fileName[fileName?.length - 1] == 'dwg') {
            setList((prev) => [...prev, file]);
          }
          return false;
        }}
        onChange={() => {
          dispatch(bulkUploadFilesReducer(list));
        }}
        fileList={[]}
        name="image"
        className="choose-folder-btn"
        action={(a) => {
          console.log({ a });
        }}
      >
        <p className="choose-txt">Choose Folder</p>
      </Upload>

      <div className="upload-files-list ">
        {bulkUploadFiles?.length > 0 && (
          <p className="files-name-txt">
            {bulkUploadAllFiles ? `${bulkUploadAllFiles.length} Files` : ''}
          </p>
        )}
        {/* {bulkUploadAllFiles?.map((item) => {
            return (
              <p key={item} className="files-name-txt">
                {item.file?.name}
              </p>
            );
          })} */}
      </div>

      <div className="mainTxtDiv">
        <p className="main-choose-txt">
          Choose an owning circle you will be uploading the drawings to
        </p>
      </div>

      <div className="circle-dropdown-div">
        <Dropdown
          className="sorting-dropdrown"
          overlay={
            <Menu
              className="circle-menu-item"
              onClick={(item) => {
                if (!_.isEmpty(bulkUploadAllFiles)) {
                  const circleIndex = circleUserOwn.findIndex(
                    (x) => x?.circlename === item?.key
                  );
                  dispatch(
                    bulkUploadFilesCircle({
                      circle: {
                        circleId: circleUserOwn[circleIndex]?._id,
                        circleName: circleUserOwn[circleIndex]?.circlename
                      }
                    })
                  );
                } else {
                  setError(true);
                }
              }}
            >
              {circleUserOwn?.map((item) => {
                return (
                  <Menu.Item className="sorting-item" key={item?.circlename}>
                    <p>{item?.circlename}</p>
                  </Menu.Item>
                );
              })}
            </Menu>
          }
          trigger={['click']}
        >
          <div className="ant-dropdown-link">
            <p className="circle-dropdown-txt">
              {bulkUploadFiles[0]?.circle?.circleId
                ? bulkUploadFiles[0]?.circle?.circleName
                : 'Please select owning circle'}
            </p>
            <img style={{ marginLeft: 10 }} src={Images.DropdownIcon} />
          </div>
        </Dropdown>
      </div>
      {error && (
        <p className="step1-circle-error">Please Upload Files Folder</p>
      )}
    </div>
  );
};

export default UploadStep1;
