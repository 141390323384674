import { Table, Tooltip } from 'antd';
import React from 'react';
import { Images } from '../../../theme';
import './styles.scss';
export default function DrawingsUploadError({ bulkFilesErrors }) {
  const columns = [
    {
      title: 'File Name.',
      render: (item) => (
        <div style={{ width: 200 }} className="error-text-item-div">
          <Tooltip
            title={item?.file?.name.length > 20 ? item?.file?.name : ''}
            placement="bottom"
          >
            <p className="drawings-error-table-row-txt">{item?.file?.name}</p>
          </Tooltip>
        </div>
      )
    },

    {
      title: 'Error',
      render: (item) => (
        <div style={{ width: 500 }} className="stepDrawing-text-item-div-error">
          <Tooltip
            title={item?.errorMessage.length > 70 ? item?.errorMessage : ''}
            placement="bottom"
          >
            <p className="drawings-error-table-row-txt">{item?.errorMessage}</p>
          </Tooltip>
        </div>
      )
    }
  ];

  return (
    <div className="main-div-drawings-error">
      <img src={Images.errorModal} />
      <p className="drawings-error-heading">Drawing Upload Errors</p>
      <p className="drawings-error-discription">
        The following drawings have errors and will be <span>removed</span> from
        the upload process
      </p>

      <Table
        columns={columns}
        dataSource={bulkFilesErrors}
        className="table-style-error"
        rowClassName="table-row-error-drawings"
        pagination={false}
      />
    </div>
  );
}
