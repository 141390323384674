import { Button } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import logo from '../../../assets/logo/Logo1.png';
import { getDrawingUnregisterUserRequest } from '../../../redux/slicers/drawing';

import './styles.scss';
function GetDrawing() {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  console.log({ params });

  useEffect(() => {
    dispatch(
      getDrawingUnregisterUserRequest({
        payloadData: params,
        responseCallback: (status, res) => {
          console.log({ res });
          if (status) {
            window.location.replace(`${res.link}`);
          }
        }
      })
    );
  }, []);
  return (
    <>
      <div className="confirm-circle-wrapper">
        <span className="confirm-site-logo-wrapper-getdrawing">
          <img src={logo} className="logo-login-getdrawing" />
          <h1>
            <b>Site</b>Circles
          </h1>
        </span>

        <p className="getdrawing-current-txt">
          Current, Trackable, Always on Site
        </p>
        <p style={{ marginTop: 60 }} className="getdrawing-current-txt">
          Your download will begin shortly!
        </p>
        <p className="getdrawing-current-txt">
          You can register for SiteCircles below!
        </p>

        <Button
          onClick={() => {
            navigate('/register');
          }}
          style={{ marginTop: 20 }}
          size="large"
          className="register-btn-getdrawing"
        >
          Register Now
        </Button>

        <div className="getdrawing-labels">
          <div className="label-wrapper-forgetpass">
            <Link className="frgt-pass-labels">What is SiteCircles?</Link>
            <Link className="frgt-pass-labels">Contact Us</Link>
            <Link className="frgt-pass-labels">Pricing</Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default GetDrawing;
