import { Breadcrumb, Popover } from 'antd';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import {
  DASHBOARD_ROUTE,
  DETAILS_ROUTE,
  DRAWING_REVISONS_ROUTE,
  UPDATE_DRAWING_ROUTE
} from '../../constants';
import { getDrawingsRequest } from '../../redux/slicers/drawing';
import { getProjectRequest } from '../../redux/slicers/projects';
import { Images } from '../../theme';

import './styles.scss';

function UpdateDrawingBreadCrumb() {
  const params = useParams();
  const dispatch = useDispatch();

  const state = useSelector((state) => state);

  const { projectList } = state?.projects;
  const project = projectList?.find((p) => p._id === params.id);
  const pDrawing = state?.drawing?.projectDrawings?.find(
    (d) => d?._id === params?.drawingId
  );

  useEffect(() => {
    if (_.isEmpty(project)) {
      dispatch(getProjectRequest({}, () => {}));
    }

    if (_.isEmpty(pDrawing)) {
      dispatch(
        getDrawingsRequest({
          payloadData: params?.id,
          responseCallback: () => {}
        })
      );
    }
  }, []);

  const content = () => {
    return (
      <div className="breadCrumbWrapper">
        <Link to={DASHBOARD_ROUTE} className="customBreadcrumbMobView">
          Dashboard
        </Link>

        <Link
          className="customBreadcrumbMobView"
          to={DETAILS_ROUTE.replace(':id', params.id)}
        >
          {project?.projectname}
        </Link>

        {pDrawing?.dname && (
          <Link
            className="customBreadcrumbMobView"
            to={DRAWING_REVISONS_ROUTE.replace(':id', params.id).replace(
              ':drawingId',
              params.drawingId
            )}
          >
            {pDrawing.dname}
          </Link>
        )}

        <Link
          className="customBreadcrumbMobView"
          to={DRAWING_REVISONS_ROUTE.replace(':id', params.id).replace(
            ':drawingId',
            params.drawingId
          )}
        >
          Revisions
        </Link>
      </div>
    );
  };

  return (
    <>
      <div className="breadCrumbWebView">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link className="customBreadcrumb" to={DASHBOARD_ROUTE}>
              Dashboard
            </Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item>
            <Link
              className="customBreadcrumb"
              to={DETAILS_ROUTE.replace(':id', params.id)}
            >
              {project?.projectname}
            </Link>
          </Breadcrumb.Item>

          {pDrawing?.dname && (
            <Breadcrumb.Item>
              <Link
                className="customBreadcrumb"
                to={UPDATE_DRAWING_ROUTE.replace(':id', params.id).replace(
                  ':drawingId',
                  params.drawingId
                )}
              >
                {pDrawing.dname}
              </Link>
            </Breadcrumb.Item>
          )}

          <Breadcrumb.Item>
            <Link
              className="customBreadcrumb"
              to={UPDATE_DRAWING_ROUTE.replace(':id', params.id).replace(
                ':drawingId',
                params.drawingId
              )}
            >
              SETTINGS
            </Link>
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>

      <div className="breadCrumbMobView">
        <Popover content={content()}>
          <img src={Images.folder} height={30} width={30} />
        </Popover>

        <Breadcrumb>
          <Breadcrumb.Item>
            <Link
              className="customBreadcrumb"
              to={DRAWING_REVISONS_ROUTE.replace(':id', params.id).replace(
                ':drawingId',
                params.drawingId
              )}
            >
              SETTINGS
            </Link>
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
    </>
  );
}

export default UpdateDrawingBreadCrumb;
