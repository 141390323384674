import React, { useEffect } from 'react';
import './styles.scss';
import { Dropdown, Form, Menu } from 'antd';
import { Images } from '../../../theme';
import { useDispatch, useSelector } from 'react-redux';
import {
  bulkUploadDrawingsNotes,
  bulkUploadDrawingsStatus,
  bulkUploadFilesError,
  bulkUploadVerifyRequest
} from '../../../redux/slicers/upload';
import { useParams } from 'react-router-dom';
const UploadStep4 = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const state = useSelector((state) => state);
  const { bulkUploadFiles } = state?.upload;
  const params = useParams();

  useEffect(() => {
    const payload = {
      Pid: params.id,
      data: bulkUploadFiles.map((item) => {
        return {
          dnumber: item?.dnumber,
          dname: item?.dname,
          rnumber: item?.rnumber,
          circleid: item?.circle?.circleId
        };
      })
    };
    dispatch(
      bulkUploadVerifyRequest({
        payloadData: { payload: payload },
        responseCallback: (res) => {
          if (res) {
            dispatch(bulkUploadFilesError());
          }
        }
      })
    );
  }, []);

  const selectedDrawingsStatus = (item) => {
    const status = item?.key == 'No Status' ? '' : item?.key;
    dispatch(bulkUploadDrawingsStatus(status));
  };

  return (
    <div className="step4-main-div">
      <p className="drawings-status-selection-txt">
        Please select a <span>status</span> for all drawings.
      </p>
      <div className="Drawings-status">
        <p className="drawinngs-status-txt">
          You can change individual drawing statuses in the next step.
        </p>
      </div>
      <div className="drawings-status-div">
        <Dropdown
          overlay={
            <Menu
              className="step4-menu-item"
              onClick={(item) => {
                selectedDrawingsStatus(item);
              }}
            >
              <Menu.Item key="No Status">No Status</Menu.Item>
              <Menu.Item key="Draft">Draft</Menu.Item>
              <Menu.Item key="Preliminary">Preliminary</Menu.Item>
              <Menu.Item key="For Approval">For Approval</Menu.Item>
              <Menu.Item key="For Construction">For Construction</Menu.Item>
              <Menu.Item key="As Constructed">As Constructed</Menu.Item>
            </Menu>
          }
          trigger={['click']}
        >
          <div className="drawings-ant-dropdown">
            <p style={{ height: 10 }}>
              {bulkUploadFiles[0]?.status
                ? bulkUploadFiles[0]?.status
                : 'No Status'}
            </p>
            <img style={{ marginLeft: 10 }} src={Images.DropdownIcon} />
          </div>
        </Dropdown>
      </div>
      <p style={{ marginTop: 10 }} className="drawings-status-selection-txt">
        Please select a <span>Note</span> for all drawings.
      </p>
      <div className="Drawings-status">
        <p className="drawinngs-status-txt">
          This will apply to all drawings in the bulk upload process.
        </p>
      </div>
      <div className="">
        <Form form={form}>
          <Form.Item name={'notes'}>
            <textarea
              placeholder="Summary of changes included in the update…"
              className="notes-step4-input"
              onChange={(item) => {
                dispatch(bulkUploadDrawingsNotes(item.target.value));
              }}
            >
              {bulkUploadFiles[0]?.notes ? bulkUploadFiles[0]?.notes : ''}
            </textarea>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default UploadStep4;
