import ApiHandler from '../services/ApiHandler';
import { getCurrentAccessToken } from '../services/utils';
export const API_TIMEOUT = 30000;
export const ABORT_REQUEST_MESSAGE = 'Network failed. Aborted request.';

export const BASE_URL = '/api';
// export const BASE_URL = 'https://sandbox.sitecircles.com/api';

export const ERROR_SOMETHING_WENT_WRONG =
  'Something went wrong, Please try again later';
export const ERROR_API_NOT_FOUND = 'Api not found, Please try again later';

export const ERROR_NETWORK_NOT_AVAILABLE =
  'Please connect to the working Internet';

export const ERROR_ACCOUNT_BLOCKED =
  'Either your account is blocked or deleted';

export const ERROR_TOKEN_EXPIRE = 'Session Expired, Please login again!';

export const REQUEST_TYPE = {
  GET: 'get',
  POST: 'post',
  DELETE: 'delete',
  PUT: 'put'
};
//PROJECTS STATES
export const GET_PROJECT_URL = {
  route: '/projects/getUsersProjects/active',
  access_token_required: true,
  type: REQUEST_TYPE.GET
};
export const GET_PROJECT_NOTIFICATION_COUNT_URL = {
  route: '/users/notifications',
  access_token_required: true,
  type: REQUEST_TYPE.GET
};
export const GET_PROJECT_COMMENT_NOTIFICATION_COUNT_URL = {
  route: '/users/commentNotifications',
  access_token_required: true,
  type: REQUEST_TYPE.GET
};
export const DELETE_NOTIFICATION_URL = {
  route: '/drawings',
  access_token_required: true,
  type: REQUEST_TYPE.DELETE
};
export const GET_PROJECT_DRAWINGS_URL = {
  route: '/drawings',
  access_token_required: true,
  type: REQUEST_TYPE.GET
};
export const DELETE_NOTIFICATIONS_DRAWING = {
  route: '/drawings/:drawingid/revisions/notifications',
  access_token_required: true,
  type: REQUEST_TYPE.DELETE
};

//CIRCLE ROUTES
export const GET_CIRCLE_OPTIONS_URL = {
  route: '/circles/getCircleOptions',
  access_token_required: true,
  type: REQUEST_TYPE.GET
};
export const GET_ALL_CIRCLE_NAMES_URL = {
  route: '/circles/getAllCircleNames',
  access_token_required: true,
  type: REQUEST_TYPE.GET
};
export const GET_PROJECT_CIRCLE_NAMES_URL = {
  route: '/circles/getCircles',
  access_token_required: true,
  type: REQUEST_TYPE.GET
};
export const CREATE_PROJECT_CIRCLE_URL = {
  route: '/circles/create',
  access_token_required: true,
  type: REQUEST_TYPE.POST
};
export const ADD_REGISTERED_CIRCLE_MEMBER_URL = {
  route: '/circles/pushMember',
  access_token_required: true,
  type: REQUEST_TYPE.POST
};
export const ADD_UNREGISTERED_CIRCLE_MEMBER_URL = {
  route: '/circles/pushUnregisteredMember',
  access_token_required: true,
  type: REQUEST_TYPE.POST
};
export const DELETE_CIRCLE_URL = {
  route: '/circles/remove',
  access_token_required: true,
  type: REQUEST_TYPE.POST
};
export const UPDATE_CIRCLE_NAME_URL = {
  route: '/circles/rename',
  access_token_required: true,
  type: REQUEST_TYPE.POST
};
export const DELETE_CIRCLE_MEMBER_URL = {
  route: '/circles/pullMembers',
  access_token_required: true,
  type: REQUEST_TYPE.POST
};
export const CREATE_DRAWING_URL = {
  route: '/drawings',
  access_token_required: true,
  type: REQUEST_TYPE.POST
};

export const GET_CIRCLE_INVITES = {
  route: '/circles/getPendingCircleInvites',
  access_token_required: true,
  type: REQUEST_TYPE.GET
};
export const REJECT_CIRCLE_INVITE = {
  route: '/circles/rejectCircleInvite',
  access_token_required: true,
  type: REQUEST_TYPE.POST
};
export const ACCEPT_CIRCLE_INVITE = {
  route: '/circles/acceptCircleInvite',
  access_token_required: true,
  type: REQUEST_TYPE.POST
};
export const ADD_REVISION_REQUEST_URL = {
  route: '/drawings/addRevision',
  access_token_required: true,
  type: REQUEST_TYPE.POST
};
export const ADD_REVISION_LOG_REQUEST_URL = {
  route: '/drawings/addRevisionPerfLog',
  access_token_required: true,
  type: REQUEST_TYPE.POST
};
export const REJECT_CIRCLE = {
  route: '/circles/:circleId/reject',
  access_token_required: false,
  type: REQUEST_TYPE.POST
};
export const ACCEPT_CIRCLE = {
  route: '/circles/:circleId/accept',
  access_token_required: false,
  type: REQUEST_TYPE.POST
};

// DASHBOARD STATES

export const REGISTER_USER = {
  route: '/users/register',
  access_token_required: false,
  type: REQUEST_TYPE.POST
};
export const LOGIN_USER = {
  route: '/users/authenticate',
  access_token_required: false,
  type: REQUEST_TYPE.POST
};
export const GET_USER_PROFILE = {
  route: '/users/profile',
  access_token_required: true,
  type: REQUEST_TYPE.GET
};
export const GET_USER_DETAILS = {
  route: '/users/userdetails',
  access_token_required: true,
  type: REQUEST_TYPE.GET
};
export const RESET_PASS = {
  route: '/users/sendResetPassLink',
  access_token_required: false,
  type: REQUEST_TYPE.POST
};
export const RESEND_VERIFICATION = {
  route: `/users`,
  access_token_required: false,
  type: REQUEST_TYPE.GET
};
export const NEW_PASSWORD = {
  route: `/users`,
  access_token_required: false,
  type: REQUEST_TYPE.POST
};
export const CONFIRM_ACCOUNT = {
  route: `/users`,
  access_token_required: false,
  type: REQUEST_TYPE.GET
};
export const FORGOT_PASSWORD = {
  route: `/users/sendResetPassLink`,
  access_token_required: false,
  type: REQUEST_TYPE.POST
};
export const CREATE_PROJECT = {
  route: `/projects/create`,
  access_token_required: true,
  type: REQUEST_TYPE.POST
};
export const CONTACT_US = {
  route: '/conact-forms/fill',
  access_token_required: false,
  type: REQUEST_TYPE.POST
};
export const ARCHIVE_PROJECT = {
  route: `/projects`,
  access_token_required: true,
  type: REQUEST_TYPE.POST
};
export const GET_ARCHIVED_PROJECTS = {
  route: '/projects/getUsersProjects/archived',
  access_token_required: true,
  type: REQUEST_TYPE.GET
};
export const LEAVE_PROJECT = {
  route: '/circles/leaveProject',
  access_token_required: true,
  type: REQUEST_TYPE.POST
};
export const GET_VIEW_DETAILS = {
  route: '/projects',
  access_token_required: true,
  type: REQUEST_TYPE.GET
};
export const SET_ALIAS = {
  route: '/projects',
  access_token_required: true,
  type: REQUEST_TYPE.POST
};
export const EDIT_PROJECT = {
  route: '/projects',
  access_token_required: true,
  type: REQUEST_TYPE.POST
};
export const GET_DRAWING_REVISIONS = {
  route: '/drawings/getDrawingRevisions',
  access_token_required: true,
  type: REQUEST_TYPE.GET
};
export const GET_DRAWING_ACCESS_HISTORY = {
  route: '/drawings',
  access_token_required: true,
  type: REQUEST_TYPE.GET
};
export const GET_DRAWING_ACTIVITY = {
  route: '/drawings',
  access_token_required: true,
  type: REQUEST_TYPE.GET
};
export const GET_DRAWING_SEEN_ACTIVITY = {
  route: '/drawings',
  access_token_required: true,
  type: REQUEST_TYPE.GET
};
export const GET_CIRCLES_ACTIVITY = {
  route: '/circles',
  access_token_required: true,
  type: REQUEST_TYPE.GET
};
export const SHARE_DRAWING = {
  //route: '/drawings/shareDrawing',
  route: '/drawings/:drawingid/:revisionNumber/share',
  access_token_required: true,
  type: REQUEST_TYPE.POST
};
export const CANCEL_REVISION = {
  route: '/drawings',
  access_token_required: true,
  type: REQUEST_TYPE.POST
};
// ALL SUBSCRIPTIONS

export const GET_SUBSCRIPTIONS = {
  route: '/api/v1/reports/stats',
  access_token_required: false,
  type: REQUEST_TYPE.GET
};
export const UPLOAD_REVISION_FILE_BUCKET = {
  route: '',
  access_token_required: false,
  type: REQUEST_TYPE.POST
};

export const DOWNLOAD_DRAWING_URL = {
  route: 'drawings/:pId/:drawingId/:rurl/download_url',
  access_token_required: true,
  type: REQUEST_TYPE.GET
};

export const SEND_DRAWING_ACCESS_URL = {
  route: 'drawings/requestForInformation',
  access_token_required: true,
  type: REQUEST_TYPE.POST
};

export const GET_UPLOAD_URL = {
  route: 'drawings/:projectId/:drawingId/upload_url',
  access_token_required: true,
  type: REQUEST_TYPE.GET
};
export const BULK_DOWNLOAD_DRAWINGS = {
  route: 'drawings/:projectId/zip',
  access_token_required: true,
  type: REQUEST_TYPE.POST
};
export const BULK_SHARE_DRAWINGS = {
  route: 'drawings/:projectId/zip',
  access_token_required: true,
  type: REQUEST_TYPE.POST
};
export const CLEAR_ALL_DRAWINGS_NOTIFICATIONS = {
  route: 'projects/:projectId/notification',
  access_token_required: true,
  type: REQUEST_TYPE.DELETE
};

/// bulk upload step5 check upploading drawinngs already have or not

export const BULK_UPLOAD_VERIFY = {
  route: 'projects/:projectId/bulk-verify',
  access_token_required: true,
  type: REQUEST_TYPE.POST
};

// download drawings register api
export const DOWNLOAD_DRAWINGS_REGISTER = {
  route: 'projects/:projectId/drawing-register',
  access_token_required: true,
  type: REQUEST_TYPE.GET
};
export const PRIMIUM_PROJECT_CREDIT = {
  route: 'projects/quota',
  access_token_required: true,
  type: REQUEST_TYPE.GET
};
export const GET_DRAWING_UNREGISTER_USER = {
  route: 'drawings/:notifiId/getDrawing',
  access_token_required: true,
  type: REQUEST_TYPE.GET
};
export const UPLOAD_DWG = {
  route: '/drawings/dwg-revision',
  access_token_required: true,
  type: REQUEST_TYPE.PUT
};
export const CIRCLE_ORDER = {
  route: '/circles/order',
  access_token_required: true,
  type: REQUEST_TYPE.POST
};

export const callRequest = async (
  url,
  data,
  parameter,
  query,
  header = {},
  baseURL = BASE_URL,
  raw = false
) => {
  let _header = header;
  if (url.access_token_required) {
    const _access_token = getCurrentAccessToken();

    if (_access_token) {
      _header = {
        ..._header,
        ...{
          Authorization: `bearer ${_access_token}`
        }
      };
    }
  }

  let _url =
    parameter && parameter !== null ? `${url.route}/${parameter}` : url.route;
  if (query && query !== null) {
    _url = `${_url}?${query}`;
  }
  let response = await ApiHandler(url.type, _url, data, _header, baseURL, raw);

  return response;
};
